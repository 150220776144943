import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import FiltrationInput from '../../../../components/EditableTable/FiltrationInput';
import { HeaderName } from '../../../Caa/CaaTable/styles';
import Chip from '@mui/material/Chip';
import moment from 'moment/moment';
import { DARE_FORMAT_FIRST } from '../../../../constans/formats';
import Button from '../../../../components/Button';
import { GreenButton, RedButton } from '../../../Logistics/style';
import { getFormatCompensationsStatus } from '../../../MyProfile/PersonalReportsTab/constants';
import { StyledDelete, StyledPencil } from '../../../../components/EditableTable/styles';

export const columns = ({
  handleFilter,
  filters,
  handleFileDrawer,
  handleOpenDeleteModal,
  handleOpenForm,
  crewId,
  hasPermissionsWrite
}) => ([
  {
    flex: 0.2,
    field: 'id',
    minWidth: 200,
    headerName: 'Compensation #',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Compensation #</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.id || ''}
          value="id"
          placeholder="Search by ID"
        />
      </Box>
    ),
    renderCell: ({ row: { id } }) => (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
        }}
        variant="subtitle1"
      >
        {`Compensation #${id}`}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'status',
    minWidth: 200,
    headerName: 'Status',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Status</Typography>
      </Box>
    ),
    renderCell: ({ row: { report } }) => (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces'
        }}
        variant="subtitle1"
      >
        <Chip label={getFormatCompensationsStatus(report.report_status)} variant="filled" />
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'created_at',
    minWidth: 180,
    headerName: 'Creation Date',
    renderCell: ({ row }) => (
      <Typography>{moment(row.created_at).format(DARE_FORMAT_FIRST)}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'type',
    minWidth: 180,
    headerName: 'Type',
    renderCell: ({ row }) => (
      <Typography>{row.type}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'report_id',
    minWidth: 180,
    headerName: 'Created To',
    sortable: false,
    renderCell: ({ row }) => (
      <Typography>{`Report №${row?.report?.id}`}</Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'amount',
    minWidth: 200,
    headerName: 'Report Sum',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Amount</Typography>
      </Box>
    ),
    renderCell: ({ row: { amount } }) => (
      <Box sx={{ cursor: 'context-menu' }}>
        <Typography noWrap variant="subtitle1">
          {`${amount} EUR`}
        </Typography>
      </Box>
    ),
  },
  {
    flex: 0.2,
    field: 'comments',
    minWidth: 180,
    headerName: 'Description',
    renderCell: ({ row }) => (
      <Typography>{row.comments}</Typography>
    ),
  },
  {
    flex: 0.15,
    field: 'files_exists',
    minWidth: 120,
    headerName: 'Files',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Files</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Button
          sx={row.file_exist ? GreenButton : RedButton}
          size="small"
          onClick={() => handleFileDrawer(true, row?.id, null, 'Compensation', row?.id)}
        >
          Files
        </Button>
      </Box>
    ),
  },
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        {hasPermissionsWrite && <StyledPencil onClick={() => handleOpenForm(row.id)}/>}
        {' '}
        {hasPermissionsWrite && <StyledDelete onClick={() => handleOpenDeleteModal(row.id)} />}
      </>
    ),
  },
]);
