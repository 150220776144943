import {
  boolean,
  number,
  object,
  string
} from 'yup';

export const defaultValues = {
  name: '',
  daily_availability_access: false,
  flights_schedule_access: false,
  roster_tech_access: false,
  home_page: null,
  flights_access: false,
  flights_statistics_access: false,
  flights_comm_statistics_access: false,
  fleet_access: false,
  maintenance_access: false,
  maintenance_edit: false,
  maintenance_saab_access: false,
  maintenance_atr_access: false,
  maintenance_expenses_access: false,
  maintenance_tasks_access: false,
  utilization_report: false,
  aircraft_body_return_to_storage: false,
  atr_maintenance_delete_access: false,
  wo_delete_access: false,
  job_delete_access: false,
  deleted_wo_access: false,
  atr_maintenance_access: false,
  parts_base_access: false,
  wo_access: false,
  wo_creator: false,
  wo_superuser: false,
  services_supplier_access: false,
  fuel_access: false,
  aircrafts_access: false,
  airport_access: false,
  company_access: false,
  caa_access: false,
  pax_profile_access: false,
  defaults_access: false,
  crew_access: false,
  ods_access: false,
  ods_edit: false,
  ods_creator: false,
  ods_action_upload_file: false,
  ods_action_write: false,
  roster_access: false,
  roster_saab_access: false,
  roster_atr_access: false,
  roster_ops_access: false,
  roster_edit: false,
  aircraft_profile: false,
  operator_access: false,
  flight_report_access: false,
  maindeck_access: false,
  cashbook_access: false,
  maintenance_manager: false,
  manager_documents: false,
  flight_manager: false,
  users_documents: false,
  bank_access: false,
  bank_edit: false,
  invoice_access: false,
  invoice_edit: false,
  invoice_su: false,
  users_access: false,
  invoice_request_access: false,
  invoice_request_approve: false,
  invoice_request_approve_expensive: false,
  invoice_request_payment: false,
  map_access: false,
  atr_maintenance_change_version: false,
  training_module_read: false,
  training_module_write: false,
  training_report_read: false,
  training_report_write: false,
  print_all_ods: false,
  invoice_request_logist: false,
  internal_invoice_request_logist: false,
  invoice_request_logist_approve: false,
  logistics_order_creator: false,
  logistics_order_read: false,
  logist: false,
  logistics_order_approve: false,
  logistics_storage_master: false,
  logistics_storage_read: false,
  logistics_storage_write: false,
  logistics_suppliers_read: false,
  logistics_suppliers_write: false,
  logistics_aircraft_reservation: false,
  logistics_order_read_aog: false,
  internal_logistics_order_creator: false,
  internal_logistics_order_read: false,
  internal_logist: false,
  internal_logistics_order_approve: false,
  internal_logistics_storage_master: false,
  internal_logistics_storage_read: false,
  internal_logistics_storage_write: false,
  internal_logistics_overhaul_order_approve: false,
  internal_logistics_suppliers_read: false,
  internal_logistics_suppliers_write: false,
  internal_logistics_aircraft_reservation: false,
  internal_logistics_order_read_aog: false,
  finance_income_reader: false,
  finance_income_writer: false,
  finance_generate_invoice_read: false,
  finance_generate_invoice_write: false,
  finance_transactions_financiers: false,
  finance_transactions_tax: false,
  payment_data_admin: false,
  payment_data_export: false,
  payment_data_access: false,
  finance_transactions_read: false,
  finance_transactions_write: false,
  finance_statistics_read: false,
  logistic_statistic_access: false,
  logistic_statistic_setting_access: false,
  invoice_request_approve_forward_access: false,
  work_order_ods_write: false,
  plannings_write: false,
  plannings_read: false,
  compensation_write_access: false,
  compensation_read_access: false,
  invoice_pending_approval_edit_access: false,
  ad_read: false,
  ad_write: false,
  personal_reports_read: false,
  personal_reports_write: false,
  bank_card_edit: false,
  logistics_order_write: false,
  internal_logistics_order_write: false,
  edit_order_info_access: false,
  edit_supplement_order_info_access: false,
  aircraft_report_read: false,
  aircraft_report_write: false,
};

export const schema = object().shape({
  name: string()
    .required('${label} cannot be blank.')
    .label('Name'),
  home_page: number()
    .required('${label} cannot be blank.')
    .label('Home Page'),
  flights_access: boolean()
    .label('Flight Access'),
  flights_statistics_access: boolean()
    .label('Flights Statistics Access'),
  flights_comm_statistics_access: boolean()
    .label('Flight Commerce Result'),
  fleet_access: boolean()
    .label('Quotations'),
  maintenance_access: boolean()
    .label('Maintenance'),
  maintenance_edit: boolean()
    .label('Maintenance Edit'),
  maintenance_saab_access: boolean()
    .label('Maintenance SAAB Access'),
  maintenance_atr_access: boolean()
    .label('Maintenance ATR Access'),
  maintenance_expenses_access: boolean()
    .label('Maintenance Expenses'),
  maintenance_tasks_access: boolean()
    .label('Maintenance Tasks'),
  utilization_report: boolean()
    .label('Utilization Report'),
  aircraft_body_return_to_storage: boolean()
    .label('Aircraft Body Return To Storage'),
  atr_maintenance_delete_access: boolean()
    .label('Delete Task Card'),
  wo_delete_access: boolean()
    .label('Delete WO'),
  job_delete_access: boolean()
    .label('Delete Job card'),
  deleted_wo_access: boolean()
    .label('Deleted Work Orders access'),
  atr_maintenance_access: boolean()
    .label('ATR Maintenance Access'),
  parts_base_access: boolean()
    .label('Parts Base Access'),
  wo_access: boolean()
    .label('Work Order Access'),
  wo_creator: boolean()
    .label('Work Order Creator'),
  wo_superuser: boolean()
    .label('WO SUPERUSER'),
  work_order_ods_write: boolean()
    .label('WO ODS Sign'),
  services_supplier_access: boolean()
    .label('Services Suppliers'),
  fuel_access: boolean()
    .label('Fuel'),
  aircrafts_access: boolean()
    .label('Aircrafts'),
  airport_access: boolean()
    .label('Airports'),
  company_access: boolean()
    .label('Clients'),
  caa_access: boolean()
    .label('CAA'),
  pax_profile_access: boolean()
    .label('PAX'),
  defaults_access: boolean()
    .label('Defaults'),
  crew_access: boolean()
    .label('Crew'),
  ods_access: boolean()
    .label('View ODS'),
  ods_edit: boolean()
    .label('Edit ODS'),
  ods_creator: boolean()
    .label('ODS Creator'),
  ods_action_upload_file: boolean()
    .label('ODS Action Upload Files'),
  ods_action_write: boolean()
    .label('ODS Action Creation'),
  roster_access: boolean()
    .label('Roster Access'),
  roster_saab_access: boolean()
    .label('Roster SAAB Access'),
  roster_atr_access: boolean()
    .label('Roster ATR Access'),
  roster_ops_access: boolean()
    .label('Roster OPS Access'),
  roster_edit: boolean()
    .label('Roster Access Edit Mode'),
  aircraft_profile: boolean()
    .label('Aircraft Profile'),
  operator_access: boolean()
    .label('Operator Access'),
  flight_report_access: boolean()
    .label('Flight Time report Access'),
  maindeck_access: boolean()
    .label('Main DECK Access'),
  cashbook_access: boolean()
    .label('Cashbook Access'),
  maintenance_manager: boolean()
    .label('Maintenance manager (send user documents)'),
  manager_documents: boolean()
    .label('Manager Documents (send user documents for managers)'),
  flight_manager: boolean()
    .label('Flight manager (send user documents)'),
  bank_access: boolean()
    .label('Bank access'),
  bank_edit: boolean()
    .label('Bank Edit'),
  invoice_access: boolean()
    .label('Invoice Access'),
  invoice_edit: boolean()
    .label('Invoice Edit'),
  invoice_su: boolean()
    .label('Invoice Super user'),
  users_access: boolean()
    .label('User access'),
  invoice_request_access: boolean()
    .label('Invoice Request Access'),
  invoice_request_approve: boolean()
    .label('Invoice Request Approve'),
  invoice_request_approve_expensive: boolean()
    .label('Invoice Request Approve Expensive'),
  invoice_request_payment: boolean()
    .label('Invoice Request Payment'),
  map_access: boolean()
    .label('Map Access'),
  atr_maintenance_change_version: boolean()
    .label('ATR Maintenance Change Version'),
  training_module_read: boolean()
    .label('Training Module - Read'),
  training_module_write: boolean()
    .label('Training Module - Write'),
  training_report_read: boolean()
    .label('Training Report Read'),
  training_report_write: boolean()
    .label('Training Report Write'),
  print_all_ods: boolean()
    .label('Print all ODS'),
  invoice_request_logist: boolean()
    .label('Logistic Invoice Request Access'),
  internal_invoice_request_logist: boolean()
    .label('Internal Logistic Invoice Request Access'),
  invoice_request_logist_approve: boolean()
    .label('Logistic Invoice Request Approve'),
  logistics_order_creator: boolean()
    .label('Order Creator'),
  logist: boolean()
    .label('Logist'),
  logistics_order_approve: boolean()
    .label('Order Approve'),
  logistics_storage_master: boolean()
    .label('Storage Master'),
  logistics_storage_read: boolean()
    .label('Storage Read'),
  logistics_storage_write: boolean()
    .label('Storage Write'),
  logistics_suppliers_read: boolean()
    .label('Supplier Read'),
  logistics_suppliers_write: boolean()
    .label('Supplier Write'),
  logistics_order_read: boolean()
    .label('Logistics Order Read'),
  logistics_order_read_aog: boolean()
    .label('Logistics Order Read AOG'),
  logistics_aircraft_reservation: boolean()
    .label('Logistics Aircraft Reservation'),
  internal_logistics_order_creator: boolean()
    .label('Internal Order Creator'),
  internal_logist: boolean()
    .label('Internal Logist'),
  internal_logistics_order_approve: boolean()
    .label('Internal Order Approve'),
  internal_logistics_storage_master: boolean()
    .label('Internal Storage Master'),
  internal_logistics_storage_read: boolean()
    .label('Internal Storage Read'),
  internal_logistics_storage_write: boolean()
    .label('Internal Storage Write'),
  internal_logistics_overhaul_order_approve: boolean()
    .label('Overhaul Order Approve'),
  internal_logistics_suppliers_read: boolean()
    .label('Internal Supplier Read'),
  internal_logistics_suppliers_write: boolean()
    .label('Internal Supplier Write'),
  internal_logistics_order_read: boolean()
    .label('Internal Logistics Order Read'),
  internal_logistics_order_read_aog: boolean()
    .label('Internal Logistics Order Read AOG'),
  internal_logistics_aircraft_reservation: boolean()
    .label('Internal Logistics Aircraft Reservation'),
  finance_income_reader: boolean()
    .label('Finance Income Reader'),
  finance_income_writer: boolean()
    .label('Finance Income Writer'),
  finance_generate_invoice_read: boolean()
    .label('Finance Generate Invoice Read'),
  finance_generate_invoice_write: boolean()
    .label('Finance Generate Invoice Writer'),
  finance_transactions_financiers: boolean()
    .label('Finance Transactions Financiers'),
  finance_transactions_tax: boolean()
    .label('Finance Transactions Tax'),
  payment_data_admin: boolean()
    .label('Payment Data Admin'),
  payment_data_export: boolean()
    .label('Payment Data Export'),
  finance_transactions_read: boolean()
    .label('Payment Data Admin'),
  finance_transactions_write: boolean()
    .label('Payment Data Export'),
  finance_statistics_read: boolean()
    .label('Finance Statistics Read'),
  logistic_statistic_access: boolean()
    .label('Logistic Statistic View'),
  logistic_statistic_setting_access: boolean()
    .label('Logistic Statistic Writer Limit'),
  invoice_request_approve_forward_access: boolean()
    .label('Invoice Forward Approve'),
  plannings_read: boolean()
    .label('Invoice Forward Approve'),
  plannings_write: boolean()
    .label('Invoice Forward Approve'),
  compensation_write_access: boolean()
    .label('Personal Compensation Write'),
  compensation_read_access: boolean()
    .label('Personal Compensation Read'),
  invoice_pending_approval_edit_access: boolean()
    .label('Pending Approval Edit'),
  ad_read: boolean()
    .label('Airworthiness Directive Read'),
  ad_write: boolean()
    .label('Airworthiness Directive Write'),
  personal_reports_read: boolean()
    .label('Personal Reports Read'),
  personal_reports_write: boolean()
    .label('Personal Reports Write'),
  bank_card_edit: boolean()
    .label('Bank Card Edit'),
  logistics_order_write: boolean()
    .label('Logistics Order Write'),
  internal_logistics_order_write: boolean()
    .label('Internal Logistics Order Write'),
  edit_order_info_access: boolean()
    .label('Edit Order Info'),
  edit_supplement_order_info_access: boolean()
    .label('Supplement Order Info'),
  aircraft_report_read: boolean()
    .label('Aircraft Report Read'),
  aircraft_report_write: boolean()
    .label('Aircraft Report Write'),
});
