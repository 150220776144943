import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GreenButton = {
  backgroundColor: '#36918b',
  color: '#fff',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#44adab !important',
  }
};

export const YellowButton = {
  backgroundColor: '#d99b22',
  color: '#fff',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#ffc14e !important',
  }
};

export const CloseButton = {
  'margin': '7px',
  '&:hover': {
    cursor: 'pointer',
    color: 'mediumvioletred !important',
  }
};

export const Filters = {
  paddingTop: '34px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  mb: 3
};

export const RedButton = {
  backgroundColor: '#bb3a3a',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#ab4848 !important',
  }
};

export const TextWithIcon = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '8px'
};

export const GridRoot = {
  display: 'flex',
  flex: '1 1 auto',
  height: '100%'
};

export const boardsWrap = {
  display: 'flex',
  flex: '1 1 auto',
  overflowX: 'auto',
  overflowY: 'hidden',
  height: '100%',
  padding: '5px'
};

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fafcff', // #fafcff, #e9f0fe
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: '4px',
  cursor: 'pointer',
  width: '100%',
  '&:hover': {
    backgroundColor: '#e9f0fe',
  },
}));

export const StatusHeader = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px',
  fontWeight: 600,
  paddingInline: '15px'
};

export const StatusText = {
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '8px',
  userSelect: 'none'
};

export const StackStyle = {
  padding: '10px',
  alignItems: 'center'
};

export const JobChipLink = {
  cursor: 'alias',
  userSelect: 'none',
  '&:hover': {
    backgroundColor: '#ffa5003d',
  },
};

export const OrderDetailsStyle = {
  margin: '1.4rem 1rem 1.48rem 0rem',
  backgroundColor: 'white',
  borderRadius: '8px'
};

export const RightMenuStyle = {
  display: 'flex',
  justifyContent: 'end',
  padding: '1.4rem 1rem 0rem 0rem',
};

export const CloseIconStyle = {
  cursor: 'pointer',
  '&:hover': {
    color: '#bb3a3a',
  },
};

export const DetailsGridStyle = {
  backgroundColor: '#e9f0fe',
  boxShadow: '8px -13px 20px 20px #e9f0fe'
};

export const TextWrap = {
  textTransform: 'capitalize',
  whiteSpace: 'break-spaces',
  wordBreak: 'break-all',
  cursor: 'text',
  color: '#2c2a2a'
};

export const ListMark = {
  position: 'relative',
  paddingLeft: '15px',
  '&:before': {
    content: '"•"',
    position: 'absolute',
    left: '0',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '20px',
    color: 'black',
  },
};

export const TransparentButton = {
  backgroundColor: 'transparent',
};
