import { useFieldArray, useForm } from 'react-hook-form';
import { defaultValues, schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePermissions } from '../../../../../utils/hooks/usePermissions';
import { useGetOdsAtaQuery } from '../../../../../store/session';
import { getOptionsWithData, getOptionsWithKeyValue } from '../../../../../utils/getOptions';
import { useEffect, useState } from 'react';
import DocumentsForm from './components/DocumentsForm';
import AircraftForm from './components/AircraftForm';
import {
  useStoreADMutation,
  useGetAirworthinessDirectiveQuery,
  useUpdateADMutation,
  useDeleteADMutation
} from '../../../../../store/slices/airworthinessDirectiveSlice';
import { toast } from 'react-hot-toast';
import Files from './components/files/Files';

export const useAdFrom = (currentAD, aircraft, onClose) => {
  const update = currentAD != null;
  const hasWritePermissions = usePermissions('ad_write');
  const [deleteModal, setDeleteModal] = useState(false);

  const [storeAD, { isLoading: isLoadingStore }] = useStoreADMutation();
  const [updateAD, { isLoading: isLoadingUpdate }] = useUpdateADMutation();
  const [deleteAD, { isLoading: isLoadingDelete }] = useDeleteADMutation();
  const { data: adData, isLoading: adDataLoading } = useGetAirworthinessDirectiveQuery(currentAD, {
    refetchOnMountOrArgChange: true,
    skip: !currentAD
  });

  const { data: odsAta, isLoading: ataLoading } = useGetOdsAtaQuery();
  const odsAtaOptions = getOptionsWithKeyValue(odsAta);
  const aircraftOptions = getOptionsWithData(aircraft);

  const [fileNames, setFileNames] = useState([]);
  const [currentTab, setCurrentTab] = useState('documents');
  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const isLoading = isLoadingStore || adDataLoading || ataLoading || isLoadingUpdate || isLoadingDelete;

  const form = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const dataFields = useFieldArray({
    name: 'data',
    control: form.control,
  });

  useEffect(() => {
    // set all data to form
    if (adData && !adDataLoading && currentAD) {
      form.reset({
        ...adData
      });

      setFileNames([
        { id: 'evaluation_file', name: adData?.evaluation_file?.name },
        { id: 'eo_file', name: adData?.eo_file?.name },
      ]);
    } else {
      form.reset({
        ...defaultValues,
      });
      setFileNames([]);
    }

    setCurrentTab('documents');
  }, [adData, adDataLoading, currentAD]);

  const onSubmit = async (formData) => {
    if (!hasWritePermissions) return;
    // Filter aircraft data
    const selectedAircraftIds = formData.aircraft.map(a => a.value);
    const filteredData = formData.data.filter((item) => item.aircraft_id !== null && selectedAircraftIds.includes(Number(item.aircraft_id)));
    const finalData = { ...formData, data: filteredData };

    if (!isLoading) {
      const toastId = toast.loading('Loading...');
      if (!update) {
        await storeAD(finalData).then((res) => {
          if (res?.error) {
            toast.error(res?.error?.data?.message, {
              id: toastId,
              duration: 15000
            });
          } else {
            toast.success('Success!', {
              id: toastId
            });
            handleCloseForm();
          }
        });
      } else {
        await updateAD({ data: finalData, id: currentAD }).then((res) => {
          if (res?.error) {
            toast.error(res?.error?.data?.message, {
              id: toastId,
              duration: 15000
            });
          } else {
            toast.success('Success!', {
              id: toastId
            });
          }
        });
      }
    }
  };

  const onDelete = async () => {
    if (!hasWritePermissions) return;

    if (!isLoading) {
      const toastId = toast.loading('Loading...');
      await deleteAD(currentAD).then((res) => {
        if (res?.error) {
          toast.error(res?.error?.data?.message, {
            id: toastId,
            duration: 15000
          });
        } else {
          toast.success('Success!', {
            id: toastId
          });
          handleCloseForm();
        }
      });
    }
  };

  const handleTabContent = () => {
    if (currentTab === 'documents') {
      if (!update) {
        return <DocumentsForm fileNames={fileNames} setFileNames={setFileNames} />;
      }

      return (
        <>
          <Files currentAD={currentAD} typeId={8} title="Evaluation Files" />
          <Files currentAD={currentAD} typeId={9} title="EQ Files" />
          <Files currentAD={currentAD} typeId={10} title="Addition Files" />
        </>
      );
    }

    return <AircraftForm aircraft={currentTab} dataFields={dataFields} />;
  };

  const handleChangeApplicability = (e, item) => {
    item.map((item) => {
      if (dataFields.fields.find((field) => Number(field.aircraft_id) === Number(item.value))) return;
      dataFields.append({
        ...defaultValues.data[0],
        aircraft_id: item.value,
      });
    });
  };

  const handleCloseForm = () => {
    form.reset(defaultValues);
    setFileNames([]);
    setCurrentTab('documents');
    setDeleteModal(false);
    onClose();
  };

  return {
    update,
    form,
    onSubmit,
    hasWritePermissions,
    odsAtaOptions,
    aircraftOptions,
    tab: {
      currentTab,
      setCurrentTab,
      handleChangeTab,
      handleTabContent
    },
    handleChangeApplicability,
    isLoading,
    handleCloseForm,
    onDelete,
    deleteModal,
    setDeleteModal
  };
};
