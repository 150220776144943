import React, { useState } from 'react';
import Box from '@mui/material/Box';
import DropDownMenu from '../../../../components/DropDownMenu';
import { useParams } from 'react-router-dom';
import {
  listContainer, listItem, dropdownMenuSx
} from './styles';
import { useCreateMaintenanceMutation } from '../../../../store/session';
import PrintDrawer from '../PrintDrawer';
import toast from 'react-hot-toast';
import { usePermissions } from '../../../../utils/hooks/usePermissions';

const DropDownCreateForm = ({
  open,
  onClose,
  anchorEl,
  aircraft
}) => {
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const params = useParams();
  const aircraftName = aircraft?.filter((item) => item.id == params?.id)[0]?.aircraft_registration;
  const hasPermissions = usePermissions('print_all_ods');

  const [createMaintenance] = useCreateMaintenanceMutation();

  const handleCreateOds = async () => {
    const res = await createMaintenance({ aircraft_id: params.id });
    const { id } = res.data;
    if (id) {
      window.open(`/directories/ods/update/${id}`, '_blank', 'noreferrer');
    }
  };

  const handlePositionOds = async () => {
    window.open('/directories/ods/position', '_blank', 'noreferrer');
  };

  const handleTrainingOds = async () => {
    window.open('/directories/ods/training', '_blank', 'noreferrer');
  };

  const handleApproachTypeStatistics = async () => {
    window.open('/directories/ods/approach-type-statistics', '_blank', 'noreferrer');
  }

  const handlePrintAllOds = () => {
    setOpenDrawer(true);
    onClose();
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <DropDownMenu
        sx={dropdownMenuSx}
        transformOrigin={{
          vertical: 'right',
          horizontal: 'center',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
      >
        <Box>
          <Box sx={listContainer}>
            <Box sx={listItem} onClick={handleCreateOds}>
              Create Maintenance ODS
            </Box>
            <Box sx={listItem} onClick={handlePositionOds}>
              Create Position ODS
            </Box>
            <Box sx={listItem} onClick={handleTrainingOds}>
              Create Training ODS
            </Box>
            <Box sx={listItem} onClick={handleApproachTypeStatistics}>
              Approach Type Statistics
            </Box>
            {(hasPermissions && params.id !== 'other') && (
              <Box sx={listItem} onClick={handlePrintAllOds}>
                {`Print All (${aircraftName ?? ''})`}
              </Box>
            )}
            <Box sx={listItem} onClick={onClose}>
              Close
            </Box>
          </Box>
        </Box>
      </DropDownMenu>

      <PrintDrawer open={isOpenDrawer} aircraftName={aircraftName} handleDrawerClose={handleDrawerClose} />
    </>
  );
};

export default DropDownCreateForm;
