import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import Button from '../../../components/Button';
import FiltrationSelect from '../../../components/EditableTable/FiltrationSelect';
import TextEdit from '../../../components/EditableTable/TextEdit';
import { TYPE_MULTI_INPUT, TYPE_SELECT } from '../../../components/EditableTable/constants';
import { HeaderName } from '../../../components/EditableTable/styles';
import { TransactionModel } from '../../../utils/constants/portalFiles';
import { GreenButton, RedButton, TextWrap } from '../../Logistics/style';
import { FILE_FILTER, TRANSACTION_STATUS_FILTER, TRANSACTION_TYPE_FILTER } from '../constants';
import { ASSIGNMENTS, STATUS_TYPES, Types } from './constants';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';

export const otp_columns = ({
  handleFilter,
  filters,
  handleOpen,
  handleOpenDetails,
  handleToggleData,
  handleFileDrawer,
  currentBank,
  taxAccess,
  financiersAccess,
  hasPermission,
  handleOpenStatusType
}) => {
  const bankName = currentBank?.label;

  return ([
    {
      flex: 0.1,
      minWidth: 140,
      headerName: 'Actions',
      renderCell: (row) => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Button
          title="Details"
          onClick={() => handleOpenDetails(row.id)}
          size="small"
        />
      )
    },
    {
      flex: 0.1,
      field: 'id',
      minWidth: 140,
      headerName: 'Internal ID',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Internal ID</Typography>
          <FiltrationInput
            handleFilter={handleFilter}
            defaultValue={filters.id || ''}
            value="id"
            placeholder="Internal ID"
          />
        </Box>
      ),
      renderCell: (row) => (row.id),
    },
    {
      flex: 0.1,
      field: 'amount',
      minWidth: 140,
      headerName: 'Amount',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Amount</Typography>
          <FiltrationInput
            handleFilter={handleFilter}
            defaultValue={filters.amount || ''}
            value="amount"
            placeholder="Amount"
          />
        </Box>
      ),
      renderCell: ({ row }) => (`${row.amount} ${row.currency}`),
    },
    {
      flex: 0.1,
      field: 'transaction_date',
      minWidth: 140,
      headerName: 'Transaction Date',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Transaction Date</Typography>
        </Box>
      ),
      renderCell: ({ row }) => (row.transaction_date),
    },
    {
      flex: 0.1,
      field: 'type',
      minWidth: 190,
      headerName: 'Type',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Type</Typography>
          <FiltrationSelect
            options={TRANSACTION_TYPE_FILTER}
            handleFilter={handleFilter}
            defaultValue={filters.type || ''}
            value="type"
            placeholder="Select type"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <TextEdit
          handleEdit={(...params) => handleOpen(...params, 'type', row)}
          options={Types}
          text={Types?.find((item) => item.value === row.type)?.label}
          activeItem={row.type}
          labelInput="Type"
          disabled={taxAccess && !hasPermission && !financiersAccess}
          type={TYPE_SELECT}
        />
      ),
    },
    {
      flex: 0.1,
      field: 'assignment',
      minWidth: 140,
      headerName: 'Assignment',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Assignment</Typography>
        </Box>
      ),
      renderCell: ({ row }) => (
        <TextEdit
          handleEdit={(...params) => handleOpen(...params, 'assignment', row)}
          options={ASSIGNMENTS}
          text={ASSIGNMENTS?.find((item) => item.value === row.assignment)?.label}
          activeItem={row.assignment}
          labelInput="Assignment"
          disabled={taxAccess && !hasPermission && !financiersAccess}
          type={TYPE_SELECT}
        />
      ),
    },
    {
      flex: 0.1,
      field: 'status',
      minWidth: 190,
      headerName: 'Status',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Status</Typography>
          <FiltrationSelect
            options={TRANSACTION_STATUS_FILTER}
            handleFilter={handleFilter}
            defaultValue={filters.status || ''}
            value="status"
            placeholder="Select status"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Chip
          label={row?.status}
          color={row?.status === 'Open' ? 'success' : 'error'}
          variant="outlined"
          size="small"
          sx={{ fontWeight: 800, cursor: 'pointer' }}
          onClick={() => {
            if (!financiersAccess && !hasPermission) return;
            handleToggleData(row?.id, 'status');
          }}
        />
      ),
    },
    {
      flex: 0.2,
      field: 'comments_internal',
      minWidth: 140,
      headerName: 'Comments from FleetAir',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Comments from FleetAir</Typography>
        </Box>
      ),
      renderCell: ({ row }) => (
        <TextEdit
          handleEdit={(...params) => handleOpen(...params, 'comments_internal', row)}
          text={row.comments_internal}
          disabled={taxAccess && !hasPermission && !financiersAccess}
          labelInput="Comments from FleetAir"
          type={TYPE_MULTI_INPUT}
        />
      ),
    },
    {
      flex: 0.2,
      field: 'comments_external',
      minWidth: 140,
      headerName: 'Comments from Tax Office',
      renderHeader: () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Comments from Tax Office</Typography>
          <FiltrationInput
            handleFilter={handleFilter}
            defaultValue={filters.comments_external || ''}
            value="comments_external"
            placeholder="Comments"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <TextEdit
          handleEdit={(...params) => handleOpen(...params, 'comments_external', row)}
          text={row.comments_external}
          labelInput="Comments from Tax Office"
          disabled={!taxAccess && !hasPermission}
          type={TYPE_MULTI_INPUT}
        />
      ),
    },
    {
      flex: 0.1,
      field: 'status_type',
      minWidth: 190,
      headerName: 'Status Type',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Status Type</Typography>
          <FiltrationSelect
            options={STATUS_TYPES}
            handleFilter={handleFilter}
            defaultValue={filters.status_type || ''}
            value="status_type"
            placeholder="Select status type"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Typography
          variant="body1"
          sx={{
            textTransform: 'capitalize',
            whiteSpace: 'break-spaces',
            cursor: 'pointer',
            textDecoration: 'underline',
            color: '#337ab7',
            fontWeight: '400'
          }}
          onClick={() => handleOpenStatusType(row.id)}
        >
          {STATUS_TYPES.find((type) => type.value === row.status_type)?.label}
        </Typography>
      ),
    },
    {
      flex: 0.1,
      field: 'data_notice',
      minWidth: 140,
      headerName: 'Notice',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Notice</Typography>
          <FiltrationInput
            handleFilter={handleFilter}
            defaultValue={filters.data_notice || ''}
            value="data_notice"
            placeholder="Notice"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Typography style={TextWrap}>{row.data?.notice}</Typography>
      )
    },
    {
      flex: 0.1,
      field: 'data_opposite_name',
      minWidth: 140,
      headerName: 'Соunterparty',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Соunterparty</Typography>
          <FiltrationInput
            handleFilter={handleFilter}
            defaultValue={filters.data_opposite_name || ''}
            value="data_opposite_name"
            placeholder="Соunterparty"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Typography style={TextWrap}>{row.data?.opposite_name}</Typography>
      )
    },
    {
      flex: 0.15,
      field: 'files_exist',
      minWidth: 120,
      headerName: 'Files',
      renderHeader: () => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
          }}
        >
          <Typography style={HeaderName}>Files</Typography>
          <FiltrationSelect
            options={FILE_FILTER}
            handleFilter={handleFilter}
            defaultValue={filters.files_exist || ''}
            value="file_filter"
          />
        </Box>
      ),
      renderCell: ({ row }) => (
        <Box>
          <Button
            sx={row.files_exist === 0 ? RedButton : GreenButton}
            size="small"
            onClick={() => handleFileDrawer(true, row?.id, TransactionModel.types.action.id, 'Transaction', row?.id)}
          >
            Files
          </Button>
        </Box>
      ),
    }
  ]);
};
