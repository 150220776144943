import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import FormHeader from './FormHeader/FormHeader';
import CashBalance from './CashBalance/CashBalance';
import Transactions from './Transactions/Transactions';
import FormFooter from './FormFooter/FormFooter';

const AircraftReportForm = ({
  options, user, actions
}) => (
  <Grid container>
    {/* Form Header - Base info */}
    <Grid item xs={12}>
      <FormHeader options={options} user={user} />
    </Grid>

    {!options.hideHeader ? (
      <Grid item xs={12}>
        <Divider />
        <Typography variant="body1" sx={{ color: 'red' }}>
          Attention! If the card data does not match, please contact OPS.
        </Typography>
        <Divider />
      </Grid>
    ) : (
      <Grid item xs={12}>
        <Divider />
      </Grid>
    )}

    {/* Taken over Cash Balance */}
    <Grid items xs={12}>
      <CashBalance options={options} arrayName="cash_balances" />
    </Grid>

    <Grid item xs={12}>
      <Divider />
    </Grid>

    {/* Transactions */}
    <Grid item xs={12}>
      <Transactions options={options} actions={actions} />
    </Grid>

    <Grid item xs={12}>
      <Divider />
    </Grid>

    {/* Form Footer */}
    <Grid item xs={12}>
      <FormFooter options={options} />
    </Grid>

    <Grid item xs={12}>
      <Divider />
    </Grid>

    {/* Handed over Cash Balance */}
    <Grid items xs={12}>
      <CashBalance options={options} arrayName="handed_over_cash_balances" />
    </Grid>
  </Grid>
);

export default AircraftReportForm;
