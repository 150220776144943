import { createSlice } from '@reduxjs/toolkit';
import { sessionApi } from '../session';
import { useProvideTags } from '../../utils/hooks/useProvideTags';

export const airworthinessDirectiveSlice = createSlice({
  name: 'airworthinessDirective',
  initialState: {},
});

sessionApi.injectEndpoints({
  endpoints: (builder) => ({
    getAirworthinessDirectiveTable: builder.query({
      query: (data) => `/airworthiness-directive${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['AD_TABLE']),
    }),
    storeAD: builder.mutation({
      query: (data) => ({
        url: '/airworthiness-directive',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['AD_TABLE']),
    }),
    getAirworthinessDirective: builder.query({
      query: (id) => `/airworthiness-directive/${id}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_AD']),
    }),
    updateAD: builder.mutation({
      query: ({ id, data }) => ({
        url: `/airworthiness-directive/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['AD_TABLE', 'GET_AD', 'AD_TABLE']),
    }),
    deleteAD: builder.mutation({
      query: (id) => ({
        url: `/airworthiness-directive/${id}`,
        method: 'DELETE',
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['AD_TABLE', 'GET_AD', 'AD_TABLE'])
    }),
    getADFiles: builder.query({
      query: ({ id, file_type_id }) => `/airworthiness-directive/files/list/${id}?file_type_id=${file_type_id}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_AD_FILES']),
    }),
    uploadADFile: builder.mutation({
      query: ({ id, data }) => ({
        url: `/airworthiness-directive/files/upload/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },

      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    openADFile: builder.mutation({
      query: ({ id }) => ({
        url: `/airworthiness-directive/files/open/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteADFile: builder.mutation({
      query: ({ id }) => ({
        url: `/airworthiness-directive/files/delete/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    exportDataTable: builder.mutation({
      query: (data) => ({
        url: `/airworthiness-directive/export${data}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useGetAirworthinessDirectiveTableQuery,
  useStoreADMutation,
  useGetAirworthinessDirectiveQuery,
  useUpdateADMutation,
  useDeleteADMutation,
  useGetADFilesQuery,
  useUploadADFileMutation,
  useOpenADFileMutation,
  useDeleteADFileMutation,
  useExportDataTableMutation,
} = sessionApi;

export default airworthinessDirectiveSlice.reducer;
