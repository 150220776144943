import {
  mixed, number,
  object, string
} from 'yup';

export const defaultValues = {
  invoice_sum: '',
  file: '',
  description: 'Order ID: ',
  invoice_number: '',
  due_payment_date: null,
  currency_id: '',
  priority: null
};

export const schema = object().shape({
  file: mixed().nullable(),
  invoice_sum: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('SUM'),
  description: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Description'),
  invoice_number: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Invoice Number'),
  currency: object()
    .nullable()
    .required('${label} cannot be blank')
    .label('Currency'),
  currency_id: number()
    .nullable()
    .required('${label} cannot be blank')
    .label('Currency'),
  due_payment_date: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Deadline Payment'),
  priority: number()
    .required('${label} cannot be blank')
    .label('Priority'),
});
