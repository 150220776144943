export const tableContainerTransactions = {
  '& .row-cnld': {
    backgroundColor: '#ffbbb2',
    '&:hover': {
      backgroundColor: '#ec7063 !important',
    }
  },
  '& .row-selected': {
    backgroundColor: '#a4a4a4'
  }
};
