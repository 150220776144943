import { headerBtn, headerBtnS } from '../../Invoices/style';
import { maxWidth800px } from '../../../constans/mediaQuery';
import { TextWithIcon } from '../../Logistics/style';

export const CardHeaderSx = {
  mt: 3,
  padding: '1rem'
};

export const BoxHeader = {
  ...headerBtn,
  ...(maxWidth800px && headerBtnS),
};

export const BoxHeaderFirst = maxWidth800px ? { alignSelf: 'start' } : {};

export const MainHeader = {
  ...TextWithIcon,
  textTransform: 'capitalize'
};

export const CardAction = {
  ...TextWithIcon,
  padding: '0.4rem',
  borderRadius: 1,
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: 'rgba(29,69,113,0.06)',
  }
};

export const IssueCardStyle = {
  padding: '1rem',
  backgroundColor: '#e9f0fe',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
};

export const WhiteInput = {
  backgroundColor: 'white',
  borderRadius: '8px',
  '& .Mui-disabled': { color: '#616161', '-webkit-text-fill-color': '#616161 !important' }
};
