import { Box, Grid, Typography } from '@mui/material';
import ModalBase from '@mui/material/Modal';
import React, { useState } from 'react';
import {
  blockBtn, cancel, closeIcon, headerSx, modalSx, text, titleSx
} from '../../../components/ApproveModal/styles';
import Button from '../../../components/Button';
import { CloseIcon } from '../../../components/Icons';
import DatePicker from '../../../form/components/DatePicker';
import { setDateValue, validateDatePickerValue } from '../../../utils/setDateValue';
import moment from 'moment';
import { stringifyLocationSearch } from '../../../utils/locationSearch';
import { toast } from 'react-hot-toast';
import FileSaver from 'file-saver';
import { useExportQuotationsTableMutation } from '../../../store/session';

const ExportModal = (props) => {
  const {
    open = true,
    onClose = () => {},
  } = props;

  const [exportState, setExportState] = useState({
    sortField: 'fleet_id',
    sortDir: 'DESC',
    date_from: moment().format('YYYY-MM-DD'),
    date_to: moment().add(10, 'days').format('YYYY-MM-DD')
  });

  const [exportData, { isLoading: isExportLoading }] = useExportQuotationsTableMutation();

  const handleExport = async () => {
    const toastId = toast.loading('Loading...');

    await exportData(stringifyLocationSearch(exportState)).then((res) => {
      if (res?.error) {
        toast.error('Something went wrong.', {
          id: toastId,
          duration: 15000
        });
      } else {
        toast.success('Success!', {
          id: toastId
        });
        FileSaver.saveAs(res.data, `Quotations ${exportState?.date_from} - ${exportState?.date_to}`);
      }
    });

    onClose();
  };

  const handleSetExportDate = (type, date) => {
    setExportState(prev => ({
      ...prev,
      [type]: date
    }));
  };

  return (
    <ModalBase
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalSx}>
        <Box sx={headerSx}>
          <Typography sx={titleSx}>
            Set the date to export.
          </Typography>
          <CloseIcon onClick={onClose} sx={closeIcon} />
        </Box>
        <Grid container spacing={6} sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <Typography variant="h6">From:</Typography>
            <DatePicker
              placeholderText="YYYY-MM-DD"
              dateFormat="yyyy-MM-dd"
              showMonthDropdown
              showYearDropdown
              onChange={(e) => {
                setDateValue(e, handleSetExportDate, 'date_from', 'YYYY-MM-DD');
              }}
              value={validateDatePickerValue(exportState?.date_from)}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">To:</Typography>
            <DatePicker
              placeholderText="YYYY-MM-DD"
              dateFormat="yyyy-MM-dd"
              showMonthDropdown
              showYearDropdown
              onChange={(e) => {
                setDateValue(e, handleSetExportDate, 'date_to', 'YYYY-MM-DD');
              }}
              value={validateDatePickerValue(exportState?.date_to)}
            />
          </Grid>
        </Grid>
        <Box sx={text}>

        </Box>
        <Box sx={blockBtn}>
          <Button onClick={handleExport} title="Export" disabled={isExportLoading} />
          <Button onClick={onClose} sx={cancel} title="Cancel" />
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ExportModal;
