import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { APPROACH_TYPE_NORMAL } from './constants';
import { useGetAirTypeListQuery } from '../../../store/session';
import { defaultOptions } from '../../../components/SearchStatisticsV2/Search';
import { selectOptionFormat } from '../../../utils/number';
import moment from 'moment/moment';
import { validateDatePickerValue } from '../../../utils/setDateValue';

/**
 * @returns {{
 * navigate: NavigateFunction,
 * tab: {activeTab: string, setActiveTab: (value: (((prevState: string) => string) | string)) => void}
 * }}
 */
export const useApproachTypeStatistics = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(APPROACH_TYPE_NORMAL);
  const [filterState, setFilterState] = useState({
    'approach_type': activeTab,
    'aircraft_id': 'all'
  });
  const { data: aircraftTypeList = [] } = useGetAirTypeListQuery();

  const aircraftTypeListOptions = [
    ...defaultOptions,
    ...selectOptionFormat(aircraftTypeList || [], 'aircraft_type_name'),
  ].map(({ value, label }) => ({ value: value.toString(), label }));

  const handleFilterState = useCallback((value, type) => {
    setFilterState(prev => ({
      ...prev,
      [type]: value
    }));
  }, []);

  const handleChangeTab = (event, newValue) => {
    handleFilterState(newValue, 'approach_type');
    setActiveTab(newValue);
  };

  const filterConfig = {
    afterDivider: false,
    items: [
      {
        value: filterState.aircraft_type,
        callback: (e) => handleFilterState(e.target.value, 'aircraft_type'),
        closeCallback: () => handleFilterState(null, 'aircraft_type'),
        typeFilter: 'Select',
        icon: 'Airplane',
        options: aircraftTypeListOptions,
        placeholder: 'AC Type'
      },
      {
        value: validateDatePickerValue(filterState.date_from || null),
        callback: (e) => (handleFilterState(e ? moment(e).format('YYYY-MM-DD') : '', 'date_from')),
        closeCallback: () => handleFilterState(null, 'date_from'),
        typeFilter: 'DatePicker',
        icon: 'CalendarMonthIcon',
        placeholder: 'Date From'
      },
      {
        value: validateDatePickerValue(filterState.date_to || null),
        callback: (e) => (handleFilterState(e ? moment(e).format('YYYY-MM-DD') : '', 'date_to')),
        closeCallback: () => handleFilterState(null, 'date_to'),
        typeFilter: 'DatePicker',
        icon: 'CalendarMonthIcon',
        placeholder: 'Date To'
      }
    ]
  };

  return {
    navigate,
    tab: { activeTab, setActiveTab, handleChangeTab },
    filterConfig,
    filterState
  };
};
