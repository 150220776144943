import React from 'react';
import {
  Box,
  Card, CircularProgress, InputAdornment, TextField,
  Typography
} from '@mui/material';
import {
  activeTypeSx,
  boxAircraft,
  boxAircraftSx,
  containerAircraft,
  containerAircraftSx,
  titleContainer, typeSx
} from '../MaintenanceACFT/styles';
import { useAirworthinessDirective } from './useAirworthinessDirective';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import { GreenButton, TextWithIcon } from '../../Logistics/style';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import {
  useGetAirworthinessDirectiveTableQuery
} from '../../../store/slices/airworthinessDirectiveSlice';
import { debounce } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import { FileExportOutline, Plus } from 'mdi-material-ui';
import ADForm from './components/ADForm';
import ExportModal from './components/ExportForm';

const AirworthinessDirective = () => {
  const {
    isLoading,
    aircraft,
    openModal,
    setOpenModal,
    maxWidth768px,
    activeType,
    setActiveType,
    aircraftType,
    setAircraftType,
    handleSetActiveType,
    hasPermissions,
    hasWritePermissions,
    search,
    setSearch,
    allTypesSelected,
    handleCloseModal,
    handleOpenModal,
    currentAD,
    handleTable,
    handleOpenExportModal,
    openExportModal,
    setOpenExportModal,
    aircraftOptions
  } = useAirworthinessDirective();

  return (
    <>
      {/* Title */}
      <Card sx={{
        mt: 3,
        padding: '1rem',
      }}
      >
        <Box sx={{ ...titleContainer }}>
          <Typography variant="h3" sx={TextWithIcon}>
            <AirplaneTicketOutlinedIcon />
            Airworthiness Directives Status
          </Typography>

          <Box>
            <Button
              title="Create new AD"
              sx={GreenButton}
              startIcon={<Plus />}
              disabled={!hasWritePermissions}
              onClick={() => handleOpenModal(null)}
            />

            <Button
              title="Export"
              sx={{ mx: 2 }}
              startIcon={<FileExportOutline />}
              onClick={() => setOpenExportModal(true)}
            />
          </Box>
        </Box>
      </Card>

      {/* Filters*/}
      <Card sx={{
        mt: 3,
        padding: '1rem',
      }}
      >
        {isLoading ? (
          <CircularProgress sx={{
            marginTop: '2rem',
          }}
          />
        ) : (
          <Box>
            <Box sx={{ ...containerAircraft, ...(maxWidth768px && containerAircraftSx) }}>
              <Box sx={{ ...boxAircraft, ...(maxWidth768px && boxAircraftSx) }}>
                <Button
                  title="All"
                  sx={{ ...typeSx, ...(activeType === 'all' && activeTypeSx) }}
                  key="all"
                  onClick={() => handleSetActiveType({ id: 'all' })}
                />
                {aircraft?.map(type => (
                  <Button
                    key={type.id}
                    title={type.aircraft_registration}
                    sx={{ ...typeSx, ...(type.id === activeType && activeTypeSx) }}
                    onClick={() => handleSetActiveType(type)}
                  />
                ))}
              </Box>
            </Box>

            <Box sx={{
              display: 'flex', flexWrap: 'wrap', alignItems: 'center', mt: 5
            }}
            >
              <TextField
                size="small"
                sx={{
                  mr: 6, mb: 2, minWidth: '350px', maxWidth: '350px'
                }}
                placeholder="Search..."
                onChange={debounce((e) => setSearch(e.target.value), 400)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        )}
      </Card>

      {/* Body */}
      <Card sx={{ mt: 3, padding: '1rem' }}>
        <EditableTable
          getRowHeight={() => 'auto'}
          isAddTopScroll={false}
          useHook={useGetAirworthinessDirectiveTableQuery}
          columns={handleTable().columns}
          hasPermissions={hasPermissions}
          hasWritePermissions={hasWritePermissions}
          sortByType="DESC"
          sortByActive="effective_date"
          tableParams={{
            aircraft_id: activeType,
            search
          }}
          // experimentalFeatures={{ columnGrouping: true }}
          // columnGroupingModel={handleTable().group}
          onRowClick={(row) => {
            if (document.getSelection().type === 'Range') return;
            handleOpenModal(activeType === 'all' ? row?.id : row?.row?.dir_id);
          }}
          style={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
        />
      </Card>

      <ADForm open={openModal} onClose={handleCloseModal} aircraft={aircraft} currentAD={currentAD} />
      <ExportModal
        open={openExportModal}
        onClose={() => setOpenExportModal(false)}
        aircraftOptions={aircraftOptions}
      />
    </>
  );
};

export default AirworthinessDirective;
