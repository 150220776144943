import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  style, WIDTH
} from './styles';
import { APPROACH_OPTIONS } from '../../OdsApproachTypeStatistics/constants';

export const ForPrint = ({ data }) => (
  <Box boxSizing="border-box" overflow="auto">
    <Box
      id="forPdf"
      minWidth={WIDTH + 32}
      sx={style}
    >
      <div className="col-md-12">
        <Typography variant="h5" noWrap sx={{ textTransform: 'capitalize', mb: 2 }}>
          ODS
          {' '}
          {data['[2]_ac_reg']}
          {' '}
          {data['[4]_sheet_no']}
        </Typography>
        <table className="table-wrap">
          <tbody>
            <tr>
              <td>
                <table className="table table-bordered table-condensed table-hover">
                  <tbody>
                    <tr>
                      <td colSpan="2">
                        [1]
                        {' '}
                        <strong>FLEET AIR INTERNATIONAL KFT</strong>
                        <br />
                        <strong>
                          2220, VECSES, FO UT 218, HUNGARY
                          <br />
                          {' '}
                          TEL:+36-1-4453776
                          <br />
                          EMAIL: OPS@FLEETAIR.EU
                          {' '}
                        </strong>
                      </td>
                      <td>
                        [2] A/C REG
                        {' '}
                        <strong>{data['[2]_ac_reg']}</strong>
                      </td>
                      <td>
                        [3] A/C TYPE
                        {' '}
                        <strong>{data['[3]_ac_type']}</strong>
                      </td>
                      <td>
                        [4] SHEET NO.
                        {' '}
                        <strong>{data['[4]_sheet_no']}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className="uppercase">
              <td>
                <table className="table table-bordered table-condensed table-hover">
                  <tbody>
                    <tr>
                      <td
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        [5] A/C TAKEN OVER BY:
                        {' '}
                        {data['[5]_taken_over_by']}
                        {data['[5]_taken_over_by_sign'] && (
                          <img alt="fe-img" src={data['[5]_taken_over_by_sign']} />
                        )}
                      </td>
                      <td>
                        [6] LICENCE:
                        {' '}
                        <strong>{data['[6]_taken_licence']}</strong>
                      </td>
                      <td>
                        [7] DATE:
                        {' '}
                        <strong>{data['[7]_taken_date']}</strong>
                      </td>
                      <td>
                        [8] UTC:
                        {' '}
                        <strong>{data['[8]_taken_time']}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        [9] A/C HANDED OVER BY:
                        {' '}
                        {data['[9]_taken_over_by']}
                        {data['[9]_taken_over_by_sign'] && (
                        <img alt="fe-img" src={data['[9]_taken_over_by_sign']} />
                        )}
                      </td>
                      <td>
                        [10] LICENCE:
                        <strong>
                          {data['[10]_taken_licence']}
                          {' '}
                        </strong>
                      </td>
                      <td>
                        [11] DATE:
                        {' '}
                        <strong>{data['[11]_taken_date']}</strong>
                      </td>
                      <td>
                        [12] UTC:
                        {' '}
                        <strong>{data['[12]_taken_time']}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className="uppercase">
              <td>
                <table className="table table-bordered table-condensed table-hover">
                  <tbody>
                    <tr>
                      <td>
                        <strong
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '92%',
                          }}
                        >
                          [13] PREFLIGHT AND SECURITY CHECK
                          {' '}
                          {data['[13]_preflight_sec_check']}
                          {data['[13]_sign'] && (
                          <img alt="fe-img" src={data['[13]_sign']} />
                          )}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table className="table-wrap">
                  <tbody>
                    <tr>
                      <td>
                        <table className="table-wrap">
                          <tbody>
                            <tr>
                              <td>
                                <table className="table table-bordered table-condensed table-hover">
                                  <tbody>
                                    <tr>
                                      <td><strong>[14] DATE</strong></td>
                                    </tr>
                                    <tr>
                                      <td>{data['[14]_date']}</td>
                                    </tr>
                                    <tr>
                                      <td><strong>[15] FLT/NR</strong></td>
                                    </tr>
                                    <tr>
                                      <td>{data['[15]_flt']}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td>
                                <table className="table table-bordered table-condensed table-hover">
                                  <tbody>
                                    <tr>
                                      <td height="60px" colSpan="3">
                                        [16]
                                        {' '}
                                        <strong>DEPARTURES</strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>[17] FROM</td>
                                      <td>[18] BLOCK OFF</td>
                                      <td>[19] TAKE OFF</td>
                                    </tr>
                                    <tr>
                                      <td><strong>{data['[17]_from']}</strong></td>
                                      <td>
                                        <strong>{data['[18]_block_off']}</strong>
                                      </td>
                                      <td>
                                        <strong>{data['[19]_take_off']}</strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td>
                                <table className="table table-bordered table-condensed table-hover">
                                  <tbody>
                                    <tr>
                                      <td height="60px" colSpan="3">
                                        [20]
                                        {' '}
                                        <strong>PREVIOUS HRS/LDGS</strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>[21] BLOCK</td>
                                      <td>[22] AIR</td>
                                      <td>[23] LDG</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>{data['[21]_block']}</strong>
                                      </td>
                                      <td><strong>{data['[22]_air']}</strong></td>
                                      <td><strong>{data['[23]_ldg']}</strong></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <table className="table table-bordered table-condensed table-hover">
                                  <tbody>
                                    <tr>
                                      <td><strong>[24] DATE</strong></td>
                                    </tr>
                                    <tr>
                                      <td>{data['[24]_date']}</td>
                                    </tr>
                                    <tr>
                                      <td>&nbsp;</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td>
                                <table className="table table-bordered table-condensed table-hover">
                                  <tbody>
                                    <tr>
                                      <td colSpan="3">
                                        [25]
                                        {' '}
                                        <strong>ARRIVALS</strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>[26] TO</td>
                                      <td>[27] TAKE ON</td>
                                      <td>[28] BLOCK ON</td>
                                    </tr>
                                    <tr>
                                      <td><strong>{data['[26]_to']}</strong></td>
                                      <td>
                                        <strong>{data['[27]_take_on']}</strong>
                                      </td>
                                      <td>
                                        <strong>{data['[28]_block_on']}</strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td>
                                <table className="table table-bordered table-condensed table-hover">
                                  <tbody>
                                    <tr>
                                      <td colSpan="3"><strong>[29] TOTAL HRS/LDGS</strong></td>
                                    </tr>
                                    <tr>
                                      <td>[30] BLOCK</td>
                                      <td>[31] AIR</td>
                                      <td>[32] LDG</td>
                                    </tr>
                                    <tr>
                                      <td><strong>{data['[30]_block']}</strong></td>
                                      <td><strong>{data['[31]_air']}</strong></td>
                                      <td><strong>{data['[32]_ldg']}</strong></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table className="table-wrap">
                          <tbody>
                            <tr>
                              <td>
                                <table className="table table-bordered table-condensed table-hover">
                                  <tbody>
                                    <tr>
                                      <td colSpan="2"><strong>[33] CREW</strong></td>
                                    </tr>
                                    <tr>
                                      <td>[34] PIC</td>
                                      <td>
                                        <strong>{data['[34]_pic']}</strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>[35] FO</td>
                                      <td>
                                        <strong>{data['[35]_fo']}</strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>[36] FE</td>
                                      <td>
                                        <strong>{data['[36]_fe']}</strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>[37] CCM</td>
                                      <td>
                                        <strong>{data['[37]_ccm']}</strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>[38] LM</td>
                                      <td>
                                        <strong>
                                          {' '}
                                          {data['[38]_lm']}
                                        </strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>[39] INSP</td>
                                      <td>
                                        <strong>{data['[39]_insp']}</strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table className="table-wrap">
                          <tbody>
                            <tr>
                              <td>
                                <table className="table table-bordered table-condensed table-hover">
                                  <tbody>
                                    <tr>
                                      <td colSpan="2"><strong>[40] LOAD</strong></td>
                                    </tr>
                                    <tr>
                                      <td>[41] CARGO</td>
                                      <td>
                                        <strong>{data['[41]_cargo']}</strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>[42] PAX</td>
                                      <td>
                                        <strong>{data['[42]_pax']}</strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>[43] DRG</td>
                                      <td>
                                        <strong>{data['[43]_drg']}</strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>&nbsp;</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table className="table table-bordered table-condensed table-hover">
                          <tbody>
                            <tr>
                              <td colSpan="2"><strong>[44] FUEL (KG)</strong></td>
                            </tr>
                            <tr>
                              <td>[45] UPLIFT</td>
                              <td>
                                <strong>{data['[45]_uplift']}</strong>
                              </td>
                            </tr>
                            <tr>
                              <td>[46] DEPARTURE</td>
                              <td>
                                <strong>{data['[46]_departure']}</strong>
                              </td>
                            </tr>
                            <tr>
                              <td>[47] ARRIVAL</td>
                              <td>
                                <strong>{data['[47]_arrival']}</strong>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2">[48] WEIGHT (KG)</td>
                            </tr>
                            <tr>
                              <td>[49] TOW</td>
                              <td>
                                <strong>{data['[49]_tow']}</strong>
                              </td>
                            </tr>
                            <tr>
                              <td>[50] LW</td>
                              <td>
                                <strong>{data['[50]_lw']}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table className="table table-bordered table-condensed">
                  <tbody>
                    <tr>
                      <td><strong>[51] DISCREPANCY / PART OFF</strong></td>
                      <td><strong>[52] CORRECTIVE ACTION/PART ON/LINE M/PART-145 CRS</strong></td>
                      <td><strong>[53] SIGNED</strong></td>
                      <td><strong>[54] SIGN</strong></td>
                      <td><strong>[55] LICENSE</strong></td>
                      <td><strong>[56] HOUR/MIN/DATE</strong></td>
                      <td><strong>[91] RE-INSPECTED BY</strong></td>
                      <td><strong>[92] RE-INSPECTED SIGN</strong></td>
                      <td><strong>[93] RE-INSPECTED DATE</strong></td>
                    </tr>
                    {!!data.actions?.length && data.actions.map((action, key) => (
                      <tr key={key}>
                        <td>{action['[51]_discrepancy']}</td>
                        <td>{action['[52]_corrective']}</td>
                        <td>{action['[53]_signed']}</td>
                        <td>
                          {action['[54]_sign'] && (
                            <img alt="fe-img" src={action['[54]_sign']} />
                          )}
                        </td>
                        <td>{action['[55]_license']}</td>
                        <td>{action['[56]_date']}</td>

                        <td>{action['[91]_re_inspected_by']}</td>
                        <td>
                          {action['[92]_re_inspected_sign'] && (
                          <img alt="fe-img" src={action['[92]_re_inspected_sign']} />
                          )}
                        </td>
                        <td>{action['[93]_re_inspected_date']}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table className="table table-bordered table-condensed">
                  <tbody>
                    <tr>
                      <td colSpan="2" rowSpan="2"><strong>[57] PAX INFORMATION</strong></td>
                      <td colSpan="2"><strong>[58] DE ICEING/ANTI ICEING</strong></td>
                      <td colSpan="1"><strong>[94] APPROACH TYPE</strong></td>
                      <td colSpan="2"><strong>[59] LH OIL</strong></td>
                      <td colSpan="2"><strong>[60] RH OIL</strong></td>
                      <td colSpan="2"><strong>[61] REMARK</strong></td>
                    </tr>
                    <tr>
                      <td>[62] TYPE</td>
                      <td>{data['[58]_iceing_type']}</td>
                      <td>{APPROACH_OPTIONS.find((type) => type.value === data['[94]_approach_type'])?.label}</td>
                      <td>[63] ENG</td>
                      <td>[64] PGB</td>
                      <td>[65] ENG</td>
                      <td>[66] PGB</td>
                      <td colSpan="2" rowSpan="4">{data['[61]_remark']}</td>
                    </tr>
                    <tr>
                      <td>[67] ADULT</td>
                      <td>{data['[67]_adult']}</td>
                      <td>[68] %</td>
                      <td width="30px">{data['[68]_percent']}</td>
                      <td>[69] ARRIVAL</td>
                      <td>{data['[69]_arrival_lh_oil_eng']}</td>
                      <td>{data['[69]_arrival_lh_oil_pgb']}</td>
                      <td>{data['[69]_arrival_rh_oil_eng']}</td>
                      <td>{data['[69]_arrival_rh_oil_pgb']}</td>
                    </tr>
                    <tr>
                      <td>[70] CHILDREN</td>
                      <td>{data['[70]_children']}</td>
                      <td>[71] HOUR/MIN</td>
                      <td>{data['[71]_hour_min']}</td>
                      <td>[72] UPLIFT</td>
                      <td>{data['[72]_uplift_lh_oil_eng']}</td>
                      <td>{data['[72]_uplift_lh_oil_pgb']}</td>
                      <td>{data['[72]_uplift_rh_oil_eng']}</td>
                      <td>{data['[72]_uplift_rh_oil_pgb']}</td>
                    </tr>
                    <tr>
                      <td>[73] INFANT</td>
                      <td>{data['[73]_infant']}</td>
                      <td>[74] HOT</td>
                      <td>{data['[74]_hot']}</td>
                      <td>[75] DEPARTURE</td>
                      <td>{data['[75]_departure_lh_oil_eng']}</td>
                      <td>{data['[75]_departure_lh_oil_pgb']}</td>
                      <td>{data['[75]_departure_rh_oil_eng']}</td>
                      <td>{data['[75]_departure_rh_oil_pgb']}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table className="table table-bordered table-condensed">
                  <tbody>
                    <tr>
                      <td colSpan="13"><strong>[76] INSTRUMENT REGARDINGS (ONCE PER FLIGHT)</strong></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>[77] ENG RPM %</td>
                      <td>[78] ENG TEMP</td>
                      <td>[79] ENG OIL TEMP</td>
                      <td>[80] PROP RPM</td>
                      <td>[81] PROP OIL TEMP</td>
                      <td>[82] TRQ</td>
                      <td>[83] FUEL FLOW</td>
                      <td>[84] START TIME</td>
                      <td>[85] HYD QTY</td>
                      <td>[86] IAS</td>
                      <td>[87] O.A.T.</td>
                      <td>[88] FLIGHT LEVEL</td>
                    </tr>
                    <tr>
                      <td>[89] LH</td>
                      <td>{data['[89-77]_lh_rpm']}</td>
                      <td>{data['[89-78]_lh_temp']}</td>
                      <td>{data['[89-79]_lh_oil_temp']}</td>
                      <td>{data['[89-80]_lh_prop_rpm']}</td>
                      <td>{data['[89-81]_lh_prop_oil_temp']}</td>
                      <td>{data['[89-82]_lh_trq']}</td>
                      <td>{data['[89-83]_lh_fuel_flow']}</td>
                      <td>{data['[89-84]_lh_start_time']}</td>
                      <td rowSpan="2">{data['[85]_hyd_qty']}</td>
                      <td rowSpan="2">{data['[86]_reg_ias']}</td>
                      <td rowSpan="2">{data['[87]_reg_oat']}</td>
                      <td rowSpan="2">{data['[88]_reg_flight_level']}</td>
                    </tr>
                    <tr>
                      <td>[90] RH</td>
                      <td>{data['[90-77]_rh_rpm']}</td>
                      <td>{data['[90-78]_rh_temp']}</td>
                      <td>{data['[90-79]_rh_oil_temp']}</td>
                      <td>{data['[90-80]_rh_prop_rpm']}</td>
                      <td>{data['[90-81]_rh_prop_oil_temp']}</td>
                      <td>{data['[90-82]_rh_trq']}</td>
                      <td>{data['[90-83]_rh_fuel_flow']}</td>
                      <td>{data['[90-84]_rh_start_time']}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table className="table table-bordered table-condensed">
                  <tbody>
                    <tr>
                      <td>
                        <strong>PRE FLIGHT MEANS:</strong>
                        {' '}
                        All necessary works performed according to Part M subpart G
                      </td>
                      <td>
                        <strong>Part-145 CRS MEANS:</strong>
                        {' '}
                        Certifies that the work specified in this log except as
                        otherwise
                        specified was carried out in accordance with the Part-145.50 and in respect to that work the
                        aircraft/aircraft component is considered ready for release to service
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>AC TAKEN OVER MEANS:</strong>
                        {' '}
                        This certifies that the Pre-Flight Inspection has been completed and the aircraft has been accepted as airworthy for flight by the Commander.
                      </td>
                      <td>
                        <strong>AC HANDED OVER MEANS:</strong>
                        {' '}
                        This certifies the Commander confirms that the security check has been completed and the crew has left the aircraft.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <strong>ALL TIME IN UTC</strong>
        <br />
        <strong>FAI-CAM-15 REVISION 6</strong>
      </div>
    </Box>
  </Box>
);
