export const headerSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  mb: '15px',
};
export const headerTitleSx = {
  textAlign: 'center',
  flex: 1,
  fontSize: '1.17em',
  fontWeight: 700,
  marginLeft: '24px'
};

export const footerSx = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'flex-end',
  mt: '15px',
};

export const cancelButton = {
  backgroundColor: 'transparent'
};

export const stuckSx = {
  mt: '15px',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
};
