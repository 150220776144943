import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { useGetAircraftListQuery } from '../../store/session';

const initButtons = [
  {
    title: 'FLIGHTS SCHEDULE',
    link: 'maindeck/schedule',
    permissions: 'flights_schedule_access'
  },
  {
    title: 'ATR CREW ROSTER',
    permissions: 'roster_atr_access',
    link: 'roster/atr',
  },
  {
    title: 'TECHNICIAN CREW ROSTER',
    permissions: 'roster_tech_access',
    link: 'roster/technicians',
  },
  {
    title: 'OPS CREW ROSTER',
    permissions: 'roster_ops_access',
    link: 'roster/ops',
  },
  {
    title: 'DAILY AVAILABILITY',
    permissions: 'daily_availability_access',
    link: '/daily_availability',
  },
];

const ButtonsGroup = () => {
  const maxWidth685px = useMediaQuery('(max-width:685px)');
  const navigator = useNavigate();
  const { data: aircraft } = useGetAircraftListQuery('?type=1');

  const [buttons, setButtons] = useState(initButtons);

  useEffect(() => {
    if (aircraft && aircraft.length) {
      setButtons(prevButtons => [
        ...prevButtons,
        {
          title: 'SAAB CREW ROSTER',
          permissions: 'roster_saab_access',
          link: 'roster/saab',
        },
      ]);
    }
  }, [aircraft]);

  const flightsScheduleAccess = usePermissions('flights_schedule_access');
  const rosterAtrAccess = usePermissions('roster_atr_access');
  const rosterTechAccess = usePermissions('roster_tech_access');
  const rosterOpsAccess = usePermissions('roster_ops_access');
  const dailyAvailabilityAccess = usePermissions('daily_availability_access');
  const rosterSaabAccess = usePermissions('roster_saab_access');

  const permissions = {
    flights_schedule_access: flightsScheduleAccess,
    roster_atr_access: rosterAtrAccess,
    roster_tech_access: rosterTechAccess,
    roster_ops_access: rosterOpsAccess,
    daily_availability_access: dailyAvailabilityAccess,
    roster_saab_access: rosterSaabAccess,
  };

  const handleClick = (rout) => {
    navigator(rout);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        ...(maxWidth685px && { display: 'flex', flexDirection: 'column' }),
      }}
    >
      {buttons.map((i, index) => (
        <Grid
          key={index}
          item
          spacing={2}
          sx={{
            ...(maxWidth685px && { width: '100%' }),
          }}
        >
          <Button
            title={i.title}
            disabled={i.permissions ? !permissions[i.permissions] : false}
            onClick={() => handleClick(i.link)}
            sx={{
              ...(maxWidth685px && { width: '100%' }),
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ButtonsGroup;
