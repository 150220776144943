import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Card,
  Divider,
  Grid,
  Typography,
  Box
} from '@mui/material';
import { StatusCardStyle } from '../styles';
import FormGroup from '../../../../../../form/components/FormGroup';
import Input from '../../../../../../form/components/Input';
import ServerAutocomplete from '../../../../../../form/components/ServerAutocomplete';
import { useSearchSupplierQuery } from '../../../../../../store/slices/logisticsSlice';
import DatePicker from '../../../../../../form/components/DatePicker';
import { setDateValue, validateDatePickerValue } from '../../../../../../utils/setDateValue';
import { WhiteInput } from '../../../../../Finance/InvoiceManagement/styles';

const EditHistory = ({ canEditInput }) => {
  const {
    setValue,
    control,
  } = useFormContext();

  return (
    <Grid item xs={12}>
      <Card sx={StatusCardStyle}>
        <Box>
          <Typography variant="h6">
            Edit
            {' '}
            <b>History (OUT)</b>
          </Typography>
        </Box>

        <Divider />

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <FormGroup label="Pending Notice">
              <Controller
                name="history.requested_notice"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    rows={2}
                    placeholder="Enter Notice"
                    multiline
                    disabled={!canEditInput('history.requested_notice')}
                    sx={{ ...(WhiteInput), width: '100%' }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <FormGroup label="Price Notice">
              <Controller
                name="history.approver_notice"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    rows={2}
                    placeholder="Enter Notice"
                    multiline
                    disabled={!canEditInput('history.approver_notice')}
                    sx={{ ...(WhiteInput), width: '100%' }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <FormGroup label="Expected Day Delivery">
              <Controller
                name="history.expected_day_delivery"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="YYYY-MM-DD"
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    sx={{ ...(WhiteInput), width: '100%' }}
                    disabled={!canEditInput('history.expected_day_delivery')}
                    onChange={(e) => {
                      field.onChange(e);
                      setDateValue(e, setValue, 'history.expected_day_delivery', 'YYYY-MM-DD');
                    }}
                    value={validateDatePickerValue(field.value)}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <FormGroup label="TTM Code" required>
              <Controller
                name="history.ttn_code"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Enter Tracking Number"
                    disabled={!canEditInput('history.ttn_code')}
                    sx={{ ...(WhiteInput), width: '100%' }}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <FormGroup label="Delivery operator" required>
              <Controller
                control={control}
                name="history.delivery_operator"
                render={({ field }) => (
                  <ServerAutocomplete
                    fields={field}
                    withData
                    placeholder="Search operators by name or email"
                    searchQueryFn={useSearchSupplierQuery}
                    sx={{ ...(WhiteInput), width: '100%' }}
                    disabled={!canEditInput('history.delivery_operator_id')}
                    handleSelect={(value) => {
                      field.onChange(value);
                      setValue('history.delivery_operator_id', value?.value || null);
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <FormGroup label="Delivery Due Date">
              <Controller
                control={control}
                name="history.delivery_due_date"
                render={({ field }) => (
                  <DatePicker
                    placeholderText="YYYY-MM-DD"
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    disabled={!canEditInput('history.delivery_due_date')}
                    sx={{ ...(WhiteInput), width: '100%' }}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setDateValue(e, setValue, 'history.delivery_due_date', 'YYYY-MM-DD');
                    }}
                    value={validateDatePickerValue(field.value)}
                  />
                )}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default EditHistory;
