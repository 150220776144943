import React from 'react';
import {
  Box, Breadcrumbs, Card, Typography
} from '@mui/material';
import { BoxHeader, CardHeaderSx, MainHeader } from '../../InvoiceManagement/styles';
import { BoxFlexBetween } from '../../../../assets/styles';
import FlightIcon from '@mui/icons-material/Flight';

const PageHeader = ({ options }) => (
  <Card sx={{ ...CardHeaderSx, mb: 5 }}>
    <Box sx={BoxHeader}>
      <BoxFlexBetween>
        <Typography noWrap variant="h4" sx={MainHeader}>
          <FlightIcon />
          Aircraft Reports
        </Typography>
      </BoxFlexBetween>
    </Box>

    <Box sx={{ mt: 2 }}>
      <Breadcrumbs aria-label="breadcrumb">
        {options.breadcrumbs.map((breadcrumb, index) => (
          <Typography
            key={index}
            onClick={breadcrumb.callback}
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer'
            }}
          >
            {breadcrumb.icon}
            <Typography sx={{ fontWeight: '600' }}>{breadcrumb.label}</Typography>
          </Typography>
        ))}
      </Breadcrumbs>
    </Box>
  </Card>
);

export default PageHeader;
