import React from 'react';
import { Card, Typography } from '@mui/material';
import { TitleContainer } from '../OdsTable/styles';
import Box from '@mui/material/Box';
import { ArrowBack } from '@mui/icons-material';
import { useApproachTypeStatistics } from './useApproachTypeStatistics';
import { TabContext, TabList } from '@mui/lab';
import Tab from '@mui/material/Tab';
import { APPROACH_OPTIONS } from './constants';
import EditableTable from '../../../components/EditableTable';
import TableFilterContainer from '../../../components/EditableTable/TableFilterContainer';
import ApproachTypeTable from "./ApproachTypeTable";

const OdsApproachTypeStatistics = () => {
  const {
    navigate,
    tab,
    filterConfig,
    filterState
  } = useApproachTypeStatistics();

  return (
    <Box>
      <Card sx={{ padding: '1rem' }}>
        <Box
          sx={{ ...(TitleContainer), justifyContent: 'left' }}
        >
          <ArrowBack sx={{ cursor: 'pointer' }} onClick={() => navigate('/directories/ods')} />
          <Typography variant="h3" sx={{ ml: 5 }}>Approach Type Statistics</Typography>
        </Box>

      </Card>

      <Card sx={{ mt: 2, borderRadius: '10px 10px 0px 0px' }}>
        <TableFilterContainer filterConfig={filterConfig} />
      </Card>

      <Card sx={{ borderRadius: '0' }}>
        <TabContext value={tab.activeTab}>
          <TabList
            onChange={tab.handleChangeTab}
            aria-label="tabs"
            sx={{ bgcolor: 'whitesmoke', minHeight: '96px' }}
            variant="fullWidth"
          >
            {APPROACH_OPTIONS.map(option => (
              <Tab value={option.value} label={option.label} />
            ))}
          </TabList>
        </TabContext>
      </Card>

      <Card sx={{ borderRadius: '0px 0px 10px 10px' }}>
        <ApproachTypeTable filterState={filterState} />
      </Card>
    </Box>
  );
};

export default OdsApproachTypeStatistics;
