import {
  Box, Grid, Typography
} from '@mui/material';
import {
  blockBtn,
  cancel,
  closeIcon,
  headerSx,
  modalSx,
  text,
  titleSx
} from '../../../../../components/ApproveModal/styles';
import { CloseIcon } from '../../../../../components/Icons';
import DatePicker from '../../../../../form/components/DatePicker';
import { setDateValue, validateDatePickerValue } from '../../../../../utils/setDateValue';
import Button from '../../../../../components/Button';
import ModalBase from '@mui/material/Modal';
import React, { useState } from 'react';
import Autocomplete from '../../../../../form/components/Autocomplete';
import moment from 'moment';
import { useExportDataTableMutation } from '../../../../../store/slices/airworthinessDirectiveSlice';
import { stringifyLocationSearch } from '../../../../../utils/locationSearch';
import FileSaver from 'file-saver';
import { toast } from 'react-hot-toast';
import Select from '../../../../../form/components/Select';

const ExportModal = (props) => {
  const {
    open = true,
    onClose = () => {},
    aircraftOptions
  } = props;

  const [exportState, setExportState] = useState({
    format: 'pdf',
    aircraft_ids: '',
    date_from: '',
    date_to: moment().add(10, 'days').format('YYYY-MM-DD')
  });
  const [exportData, { isLoading: isExportLoading }] = useExportDataTableMutation();

  const handleExport = async () => {
    const toastId = toast.loading('Loading...');

    await exportData(stringifyLocationSearch(exportState)).then((res) => {
      if (res?.error) {
        const message = res.error.status === 404 ? 'There are no records for the selected date.' : 'Something went wrong.';
        toast.error(message, {
          id: toastId,
          duration: 15000
        });
      } else {
        toast.success('Success!', {
          id: toastId
        });
        FileSaver.saveAs(
          res.data,
          exportState?.date_from ? `${exportState.date_from} - ${exportState.date_to}` : exportState.date_to
        );
      }
    });

    onClose();
  };

  const handleSetExportDate = (type, date) => {
    setExportState(prev => ({
      ...prev,
      [type]: date
    }));
  };

  const handleAircraftChange = (event, values) => {
    const aircraftIds = values.map((item) => item.value);
    setExportState(prev => ({
      ...prev,
      aircraft_ids: aircraftIds.join(',')
    }));
  };

  const handleFormatChange = (event) => {
    setExportState(prev => ({
      ...prev,
      format: event.target.value
    }));
  };

  return (
    <ModalBase
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalSx}>
        <Box sx={headerSx}>
          <Typography sx={titleSx}>
            Export
          </Typography>
          <CloseIcon onClick={onClose} sx={closeIcon} />
        </Box>
        <Grid container spacing={6} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Autocomplete
              options={aircraftOptions || []}
              multiple
              size="small"
              placeholder="Please select an Aircraft"
              onChange={handleAircraftChange}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">From:</Typography>
            <DatePicker
              placeholderText="YYYY-MM-DD"
              dateFormat="yyyy-MM-dd"
              showMonthDropdown
              showYearDropdown
              onChange={(e) => {
                setDateValue(e, handleSetExportDate, 'date_from', 'YYYY-MM-DD');
              }}
              value={validateDatePickerValue(exportState?.date_from)}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">To:</Typography>
            <DatePicker
              placeholderText="YYYY-MM-DD"
              dateFormat="yyyy-MM-dd"
              showMonthDropdown
              showYearDropdown
              onChange={(e) => {
                setDateValue(e, handleSetExportDate, 'date_to', 'YYYY-MM-DD');
              }}
              value={validateDatePickerValue(exportState?.date_to)}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">Format:</Typography>
            <Select
              options={[
                { label: 'PDF', value: 'pdf' },
                { label: 'EXCEL', value: 'excel' },
              ]}
              onChange={handleFormatChange}
              defaultValue={exportState?.format}
            />
          </Grid>
        </Grid>
        <Box sx={text}>

        </Box>
        <Box sx={blockBtn}>
          <Button onClick={handleExport} title="Export" disabled={isExportLoading} />
          <Button onClick={onClose} sx={cancel} title="Cancel" />
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ExportModal;
