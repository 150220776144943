import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '../../../../components/Modal';
import CloseIcon from '@mui/icons-material/Close';
import {
  footerSx, headerSx, cancelButton, stuckSx
} from './styles';

export const ConfirmModal = ({
  onClose, onAccept, open, confirmText
}) => (
  <Modal onClose={onClose} open={open}>
    <Box>
      <Box sx={headerSx}>
        <Box>Confirm Modal</Box>
        <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
      </Box>
      <Box sx={stuckSx}>
        <Box>{confirmText}</Box>
      </Box>
      <Box sx={footerSx}>
        <Button onClick={onClose} sx={cancelButton}>Cancel</Button>
        <Button onClick={onAccept} variant="contained">Save</Button>
      </Box>
    </Box>
  </Modal>
);
