import React, { useState } from 'react';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PollIcon from '@mui/icons-material/Poll';
import { Box, Grid } from '@mui/material';
import { useGetStatisticsTotalQuery } from '../../../store/slices/financeSlice';
import CardStatistics from '../components/CardStatistics';
import Filters from './Filters';
import { TextWithIcon } from '../../Logistics/style';
import Typography from '@mui/material/Typography';
import { Skeleton } from '@mui/lab';
import moment from 'moment';

const Statistics = () => {
  const [filters, setFilters] = useState({
    date_from: moment().startOf('year').format('YYYY-MM-DD'),
    date_to: moment().endOf('year').format('YYYY-MM-DD')
  });
  const { data: statisticsTotal, isLoading, isFetching } = useGetStatisticsTotalQuery(`?date_from=${filters?.date_from || ''}&date_to=${filters?.date_to || ''}`, {
    refetchOnMountOrArgChange: true,
  });
  const dataLoading = isLoading || isFetching;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Box sx={TextWithIcon}>
          <PollIcon />
          <Typography variant="h4">Statistics</Typography>
        </Box>
      </Grid>

      <Grid item xs={12} lg={8}>
        <Filters filters={filters} setFilters={setFilters} isLoading={dataLoading} />
      </Grid>

      <Grid item xs={12} lg={4}>
        {statisticsTotal && (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {dataLoading ? (
              <Skeleton variant="rounded" sx={{ minHeight: '79px' }} />
            ) : (
              <CardStatistics title="Total" icon={<PollIcon />} stats={statisticsTotal?.total || 0} color="info" />
            )}
          </Grid>

          <Grid item xs={12}>
            {dataLoading ? (
              <Skeleton variant="rounded" sx={{ minHeight: '79px' }} />
            ) : (
              <CardStatistics title="Paid" icon={<CreditScoreIcon />} stats={statisticsTotal?.paid || 0} />
            )}
          </Grid>

          <Grid item xs={12}>
            <CardStatistics title="Unpaid" icon={<CreditCardOffIcon />} stats={statisticsTotal?.unpaid || 0} color="error" />
          </Grid>
        </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Statistics;
