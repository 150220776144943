import React from 'react';
import { SvgIcon } from '@mui/material';

const AmexIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 156 128">
    <style type="text/css">
      {`
      .st0 { clip-path: url(#SVGID_2_); fill: #246EA9; }
      .st1 { clip-path: url(#SVGID_4_); fill: #FFFFFE; }
      .st2 { clip-path: url(#SVGID_6_); fill: #FFFFFE; }
      .st3 { fill: #246EA9; }
    `}
    </style>
    <g>
      <g>
        <defs>
          <path
            d="M13.9,29.3c0-3.3,2.7-6,6-6h116c3.3,0,6,2.7,6,6v68c0,3.3-2.7,6-6,6h-116c-3.3,0-6-2.7-6-6V29.3z"
            id="SVGID_1_"
          />
        </defs>
        <clipPath id="SVGID_2_">
          <use style={{ overflow: 'visible' }} xlinkHref="#SVGID_1_" />
        </clipPath>
        <path
          className="st0"
          d="M13.9,29.3c0-3.3,2.7-6,6-6h116c3.3,0,6,2.7,6,6v68c0,3.3-2.7,6-6,6h-116c-3.3,0-6-2.7-6-6V29.3z"
        />
      </g>
    </g>
    <g>
      <defs>
        <path
          d="M140.5,72.6h-6.4c-0.6,0-1.1,0-1.4,0.3c-0.4,0.2-0.5,0.6-0.5,1.1c0,0.6,0.3,0.9,0.8,1.1
          c0.4,0.1,0.8,0.2,1.3,0.2l1.9,0c1.9,0.1,3.2,0.4,4,1.2c0.1,0.1,0.2,0.2,0.3,0.4V72.6z
          M140.5,82.3c-0.9,1.3-2.5,1.9-4.8,1.9h-6.8V81h6.8c0.7,0,1.1-0.1,1.4-0.4
          c0.2-0.2,0.4-0.5,0.4-1c0-0.4-0.2-0.8-0.4-1c-0.3-0.2-0.6-0.3-1.2-0.3
          c-3.3-0.1-7.4,0.1-7.4-4.6c0-2.1,1.4-4.4,5-4.4h7v-2.9H134c-2,0-3.4,0.5-4.4,1.2v-1.2h-9.6
          c-1.5,0-3.3,0.4-4.2,1.2l0-1.2H98.6v1.2c-1.4-1-3.7-1.2-4.7-1.2H82.5v1.2c-1.1-1-3.5-1.2-5-1.2H64.9
          L62,69.7l-2.7-3.1H40.3v20.5h18.6l3-3.2l2.8,3.2l11.5,0v-4.8h1.1c1.5,0,3.3,0,4.9-0.7v5.5h9.5v-5.3h0.5
          c0.6,0,0.6,0,0.6,0.6v4.7h28.7c1.8,0,3.7-0.5,4.8-1.3v1.3h9.1c1.9,0,3.7-0.3,5.2-0.9V82.3z
          M126.4,76.4c0.7,0.7,1.1,1.6,1.1,3.1c0,3.2-2,4.6-5.5,4.6h-6.9V81h6.8
          c0.7,0,1.1-0.1,1.4-0.4c0.2-0.2,0.4-0.5,0.4-1c0-0.4-0.2-0.8-0.4-1
          c-0.3-0.2-0.6-0.3-1.3-0.3c-3.3-0.1-7.4,0.1-7.4-4.6c0-2.1,1.3-4.4,5-4.4h7v3.1h-6.4
          c-0.7,0-1.1,0-1.4,0.3c-0.4,0.2-0.5,0.6-0.5,1.1c0,0.6,0.3,0.9,0.8,1.1
          c0.4,0.1,0.8,0.2,1.4,0.2l1.9,0C124.4,75.3,125.6,75.6,126.4,76.4z
          M94.7,75.5c-0.5,0.3-1.1,0.3-1.7,0.3h-4.3v-3.3h4.3
          c0.6,0,1.3,0,1.7,0.3c0.5,0.2,0.7,0.7,0.7,1.3
          C95.5,74.7,95.2,75.2,94.7,75.5z
          M96.9,77.3c0.8,0.3,1.4,0.8,1.7,1.2
          c0.5,0.7,0.6,1.4,0.6,2.7v2.9h-3.5v-1.8
          c0-0.9,0.1-2.2-0.6-2.9c-0.5-0.5-1.3-0.7-2.6-0.7h-3.8v5.4h-3.5V69.5h8.1
          c1.8,0,3.1,0.1,4.2,0.7c1.1,0.7,1.8,1.6,1.8,3.3
          C99.3,75.8,97.8,77,96.9,77.3z
          M101.3,69.5H113v3.1h-8.2v2.7h8v3h-8v2.9l8.2,0v3.1h-11.7V69.5z
          M77.6,76.3H73v-3.7h4.6c1.3,0,2.1,0.5,2.1,1.8
          C79.8,75.6,78.9,76.3,77.6,76.3z
          M69.5,82.9l-5.4-6l5.4-5.8V82.9z
          M55.6,81.1H47v-2.9h7.7v-3H47v-2.7h8.8l3.8,4.3L55.6,81.1z
          M83.5,74.3c0,4.1-3,4.9-6.1,4.9H73v4.9h-6.8l-4.3-4.9l-4.5,4.9H43.5V69.5h14.1l4.3,4.8l4.5-4.8h11.2
          C80.4,69.5,83.5,70.3,83.5,74.3z"
          id="SVGID_3_"
        />
      </defs>
      <clipPath id="SVGID_4_">
        <use style={{ overflow: 'visible' }} xlinkHref="#SVGID_3_" />
      </clipPath>
      <rect className="st1" height="30.5" width="110.2" x="35.3" y="61.6" />
    </g>
    <g>
      <defs>
        <path
          d="M26.7,52.5l-2.4-5.9l-2.4,5.9H26.7z M79.7,50.2c-0.5,0.3-1.1,0.3-1.7,0.3h-4.3v-3.3H78
          c0.6,0,1.3,0,1.7,0.3c0.5,0.2,0.7,0.7,0.7,1.3C80.4,49.5,80.1,50,79.7,50.2z
          M110.2,52.5l-2.4-5.9l-2.4,5.9H110.2z
          M53.4,58.9h-3.6l0-11.5l-5.1,11.5h-3.1l-5.1-11.5v11.5h-7.1L28,55.6h-7.3l-1.4,3.3h-3.8l6.3-14.7H27l6,13.9V44.2h5.7l4.6,10l4.2-10h5.8V58.9z
          M67.7,58.9H56V44.2h11.7v3.1h-8.2v2.6h8v3l-8,0v2.9h8.2V58.9z
          M84.3,48.1c0,2.3-1.6,3.6-2.5,3.9c0.8,0.3,1.4,0.8,1.7,1.2c0.5,0.7,0.6,1.4,0.6,2.7v2.9h-3.5l0-1.9
          c0-0.9,0.1-2.2-0.6-2.9c-0.5-0.5-1.3-0.6-2.6-0.6h-3.8v5.3h-3.5V44.2h8.1
          c1.8,0,3.1,0.1,4.3,0.7C83.6,45.5,84.3,46.5,84.3,48.1z
          M86.3,58.9h3.6V44.2h-3.6V58.9z
          M131.5,58.9h-5l-6.7-11l0,11h-7.2l-1.4-3.3H104l-1.3,3.3h-4.1
          c-1.7,0-3.9-0.4-5.1-1.6c-1.2-1.3-1.9-2.9-1.9-5.6c0-2.2,0.4-4.2,1.9-5.7
          c1.1-1.2,2.9-1.7,5.3-1.7h3.4v3.1h-3.3c-1.3,0-2,0.2-2.7,0.9c-0.6,0.6-1,1.8-1,3.3
          c0,1.6,0.3,2.7,1,3.5c0.5,0.6,1.5,0.8,2.4,0.8h1.6l5-11.6h5.3l5.9,13.9V44.2h5.4l6.2,10.2V44.2h3.6V58.9z
          M15.4,61.8h6l1.4-3.3h3l1.3,3.3H39v-2.5l1.1,2.5h6.1l1-2.5v2.5h29.4l0-5.4h0.6
          c0.4,0,0.5,0.1,0.5,0.7v4.7h15.2v-1.3c1.2,0.7,3.1,1.3,5.6,1.3h6.4l1.4-3.3h3l1.3,3.3h12.3v-3.1l1.9,3.1h9.9V41.3h-9.8v2.4
          l-1.4-2.4h-10v2.4l-1.3-2.4H98.7c-2.3,0-4.3,0.3-5.9,1.2v-1.2h-9.3v1.2c-1-0.9-2.4-1.2-4-1.2H45.4
          l-2.3,5.3l-2.3-5.3H30v2.4l-1.2-2.4h-9.2L15.4,51V61.8z"
          id="SVGID_5_"
        />
      </defs>
      <clipPath id="SVGID_6_">
        <use style={{ overflow: 'visible' }} xlinkHref="#SVGID_5_" />
      </clipPath>
      <rect className="st2" height="30.5" width="129.2" x="10.4" y="36.3" />
    </g>
    <rect className="st3" height="14.7" width="3.6" x="86.3" y="44.2" />
  </SvgIcon>
);

export default AmexIcon;
