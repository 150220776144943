import { Box, Card, styled } from '@mui/material';

export const BoxFlexBetween = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const DialogMaxHeight = {
  '& .MuiDialog-paper': {
    height: '90vh',
    maxHeight: '90vh',
  },
};

export const DialogCardContent = styled(Card)(() => ({
  padding: '1rem',
  overflowY: 'auto',
  height: '100%'
}));

export const StyledCard = styled(Card)(() => ({
  padding: '1rem'
}));

export const HideDisabledStyle = {
  '& .Mui-disabled': {
    color: '#616161',
    '-webkit-text-fill-color': '#616161 !important'
  }
};
