import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  Card, Divider,
  Grid, Tab, Typography, useMediaQuery,
} from '@mui/material';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import Button from '../../../components/Button';
import ButtonSelect from '../../../components/ButtonSelect';
import EditableTable from '../../../components/EditableTable';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import {
  useDeleteTransactionFileMutation,
  useGetBankAccountListQuery, useGetBankInvoicesListQuery, useGetTransactionFilesMutation,
  useOpenTransactionFileMutation,
  useUploadTransactionFileMutation
} from '../../../store/session';
import {
  useGetTransactionTableQuery, useToggleStatusTransactionsMutation, useToggleTypeTransactionsMutation, useUpdateTransactionMutation
} from '../../../store/slices/financeSlice';
import { setFilesName } from '../../../utils/constants/portalFiles';
import { getOptions } from '../../../utils/getOptions';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { headerBtn, headerBtnS } from '../../Invoices/style';
import { GreenButton, TextWithIcon } from '../../Logistics/style';
import { MONTH_SHORT } from '../../MainDeckSchedule/constants';
import { DEFAULT_MONTH, DEFAULT_YEAR } from '../../SabCrewRoster/constants';
import FileDrawer from '../../TrainingModule/FileDrawer/FileDrawer';
import { columns } from './columns';
import AddManualTransactions from './components/AddManualTransactions/AddManualTransactions';
import TransactionDetails from './components/TransactionDetails';
import TransactionImport from './components/import/TransactionImport';
import { otp_columns } from './otp_columns';
import { raif_columns } from './raif_columns';
import { tableContainerTransactions } from './styles';
import StatusTypeModal from "./components/StatusTypeModal";

const Transactions = () => {
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [currentBank, setCurrentBank] = useState(null);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [openStatusType, setOpenStatusType] = useState(false);
  const [refetchTable, setRefetchTable] = useState(0);
  const { data: banks, isLoading: isLoadingBanks } = useGetBankInvoicesListQuery(1);
  const bankList = useMemo(() => getOptions(banks), [banks]);
  const [fileDrawerState, setFileDrawerState] = useState({
    isOpen: false,
    type: '',
    fileList: [],
    name: '',
    currentName: '',
    currentId: null
  });

  const { data: accounts } = useGetBankAccountListQuery(bankList.find((item) => item?.label?.toLowerCase() === currentBank)?.value, {
    refetchOnMountOrArgChange: true,
    skip: !currentBank
  });
  const [toggleType, { isLoading: isLoadingToggleType }] = useToggleTypeTransactionsMutation();
  const [toggleStatus, { isLoading: isLoadingToggleStatus }] = useToggleStatusTransactionsMutation();
  const [getFiles, { isLoading: isLoadingCrewFiles }] = useGetTransactionFilesMutation();
  const [uploadFiles, { isLoading: isAttachLoading }] = useUploadTransactionFileMutation();
  const [year, setYear] = useState(DEFAULT_YEAR);
  const [months, setMonths] = useState(DEFAULT_MONTH);
  const [addTransactionsDrawer, setAddTransactionsDrawer] = useState(false);
  const maxWidth1000px = useMediaQuery('(max-width:1000px)');
  const hasPermission = usePermissions('finance_transactions_write');
  const financiersAccess = usePermissions('finance_transactions_financiers');
  const taxAccess = usePermissions('finance_transactions_tax');
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTransaction = searchParams.get('search_transaction');

  const accountList = useMemo(() => getOptions(accounts), [accounts]);
  useEffect(() => {
    if (accountList) {
      setCurrentAccount(accountList[0]?.value);
    }
  }, [accountList]);

  useEffect(() => {
    if (bankList) {
      setCurrentBank(bankList[0]?.label?.toLowerCase());
    }
  }, [bankList]);

  const handleChangeBank = (_, newBank = '') => {
    setCurrentBank(newBank?.toLowerCase());
  };

  const handleChangeAccount = (_, newAccount) => {
    setCurrentAccount(newAccount);
  };

  const numberMonths = MONTH_SHORT.reduce((total, amount, index) => {
    const monthIndex = [...total, months.includes(amount) && index + 1];

    return monthIndex.filter((e) => e);
  }, []);

  const openDetailsPopup = (tr_id) => {
    setCurrentTransaction(tr_id);
    setOpenDetails(true);
  };

  const closeDetailsPopup = () => {
    setOpenDetails(false);
    setCurrentTransaction(null);
  };

  const openStatusTypePopup = (tr_id) => {
    setCurrentTransaction(tr_id);
    setOpenStatusType(true);
  }

  const closeStatusTypePopup = () => {
    setOpenStatusType(false);
    setCurrentTransaction(null);
  }

  const handleOpenImport = () => {
    setOpenImport(true);
  };

  const handleCloseImport = () => {
    setOpenImport(false);
  };

  if (isLoadingBanks) {
    return <ContentPageLoader />;
  }

  const getFileList = async (id, callback) => {
    const res = await getFiles({ id });

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.');
      } else {
        toast.error('Unable to get file list.');
      }

      callback([]);

      return;
    }

    callback(res?.data || []);
  };

  const handleFileDrawer = (isOpen, id = null, type = null, name = '', currentName = '') => {
    if (id) {
      getFileList(id, (list) => setFileDrawerState(prev => ({
        ...prev,
        isOpen,
        type,
        currentId: id,
        fileList: setFilesName(list),
        name,
        currentName
      })));
    }

    setFileDrawerState(prev => ({
      ...prev,
      isOpen,
      type,
      fileList: [],
      currentId: id,
      name,
      currentName
    }));
  };

  const handleRefetchTable = () => {
    setRefetchTable(prev => prev + 1);
  };

  const handleToggleData = async (id, type) => {
    const update = type === 'type' ? toggleType : toggleStatus;
    const isUpdating = type === 'type' ? isLoadingToggleType : isLoadingToggleStatus;

    if (isUpdating) return;
    const toastId = toast.loading('Loading...');

    await update({ id }).then((res) => {
      if (!res?.errors) {
        toast.success('Success!', {
          id: toastId,
        });

        handleRefetchTable();
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const updateFiles = async (id, file, type, callback) => {
    if (!file.target.files[0]) return;

    const toastId = toast.loading('Loading...');
    const formData = new FormData();

    Object.keys(file.target.files)?.forEach((key) => {
      formData.append('upload_files[]', file.target.files[key]);
    });

    const res = await uploadFiles({ id, data: formData });

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.', {
          id: toastId,
        });
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });

    callback();
    handleRefetchTable();
  };

  const renderColumns = (props) => {
    if (bankList) {
      switch (bankList.find((item) => item?.label?.toLowerCase() === currentBank)?.label) {
        case 'Raiffeisen':
          return raif_columns(props);
        case 'OTP':
          return otp_columns(props);
        default:
          return columns(props);
      }
    }

    return columns(props);
  };

  const handleAddTransactionsDrawer = (data) => {
    setAddTransactionsDrawer(data);
  };

  return (
    <Card sx={{
      padding: '10px'
    }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{ ...headerBtn, ...(maxWidth1000px && headerBtnS) }}
          >
            <Typography noWrap variant="h3" sx={TextWithIcon}>
              Transactions
            </Typography>
            <Box />
            {!searchTransaction && (
              <Box sx={{ display: 'flex', gap: 4, justifySelf: 'end' }}>
                {(currentBank === 'takarek pannonia' || currentBank === 'takarek fleetair') && (
                  <Button
                    title="Add Transactions"
                    disabled={!hasPermission}
                    size="small"
                    variant="contained"
                    component="label"
                    color="secondary"
                    onClick={() => handleAddTransactionsDrawer(true)}
                  />
                )}
                <Button
                  title="Import Transactions"
                  disabled={!hasPermission}
                  size="small"
                  variant="contained"
                  component="label"
                  color="secondary"
                  startIcon={<CloudUploadIcon />}
                  onClick={handleOpenImport}
                  sx={GreenButton}
                />
              </Box>
            )}

          </Box>

          <Divider />
        </Grid>

        {!searchTransaction && (
          <>
            <Grid item xs={12}>
              {bankList?.length ? (
                <Tabs
                  value={currentBank}
                  onChange={handleChangeBank}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {(bankList || []).map((block) => (
                    <Tab
                      label={block?.label}
                      key={block?.value}
                      value={block?.label?.toLowerCase()}
                    />
                  ))}
                </Tabs>
              ) : (<Typography variant="subtitle1" sx={{ ml: '40px', color: 'red' }}>No active banks.</Typography>)}
            </Grid>
            <Grid item xs={12}>
              {accountList?.length ? (
                <Tabs
                  value={currentAccount}
                  onChange={handleChangeAccount}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {(accountList || []).map((block) => (
                    <Tab
                      label={block?.label}
                      key={block?.value}
                      value={block?.value}
                    />
                  ))}
                </Tabs>
              ) : (<Typography variant="subtitle1" sx={{ ml: '40px', color: 'red' }}>No account numbers for current bank.</Typography>)}
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          {!searchTransaction && (
            <Box sx={{
              backgroundColor: '#dfe6f4',
              borderRadius: '5px 5px 0px 0px',
              padding: '10px'
            }}
            >
              <ButtonSelect
                sx={{
                  paddingBottom: '0px'
                }}
                year={year}
                months={months}
                setYear={setYear}
                setMonths={setMonths}
                disabledSendRoster={false}
              />
            </Box>
          )}

          <EditableTable
            getRowClassName={(params) => {
              if (fileDrawerState.currentId === params.id) {
                return 'row-m row-selected';
              }

              return `row-m row-${params?.row.status_type === 'incorrect' && 'cnld'}`;
            }}
            getRowId={(row) => row.id}
            useHook={useGetTransactionTableQuery}
            useHookUpdate={useUpdateTransactionMutation}
            tableParams={{
              bank_account_number_id: currentAccount,
              months: numberMonths,
              year,
            }}
            getRowHeight={() => 'auto'}
            sortByType="DESC"
            sortByActive="id"
            columns={renderColumns}
            refetchTable={refetchTable}
            style={{
              '& .MuiDataGrid-columnHeaders': {
                borderRadius: 0,
                minHeight: '80px!important'
              },
              ...(tableContainerTransactions)
            }}
            handleOpenDetails={(tr_id) => openDetailsPopup(tr_id)}
            handleOpenStatusType={(tr_id) => openStatusTypePopup(tr_id)}
            handleToggleData={handleToggleData}
            handleFileDrawer={handleFileDrawer}
            financiersAccess={financiersAccess}
            hasPermission={hasPermission}
            taxAccess={taxAccess}
            bankList={bankList}
            currentBank={bankList ? bankList.find((item) => item?.label?.toLowerCase() === currentBank) : null}
          />

        </Grid>

      </Grid>

      <StatusTypeModal open={openStatusType} onClose={closeStatusTypePopup} trxId={currentTransaction} />
      <TransactionDetails open={openDetails} onClose={closeDetailsPopup} transactionID={currentTransaction} />
      <TransactionImport open={openImport} onClose={handleCloseImport} bankList={bankList} />
      <FileDrawer
        open={fileDrawerState.isOpen}
        onClose={() => handleFileDrawer(false)}
        title={fileDrawerState.name}
        itemName={fileDrawerState.currentName}
        itemsList={fileDrawerState.fileList}
        uploadFile={(file) => updateFiles(fileDrawerState.currentId, file, fileDrawerState.type, () => handleFileDrawer(true, fileDrawerState.currentId, fileDrawerState.type, fileDrawerState.name, fileDrawerState.currentName))}
        deleteFileCallback={handleRefetchTable}
        isAttachLoading={isAttachLoading}
        hasPermissions={hasPermission || financiersAccess}
        isLoadingFiles={isLoadingCrewFiles}
        useOpenFileMutation={useOpenTransactionFileMutation}
        useDeleteFileMutation={useDeleteTransactionFileMutation}
        dragAndDrop
      />

      <AddManualTransactions
        open={addTransactionsDrawer}
        onClose={() => handleAddTransactionsDrawer(false)}
        currentAccount={currentAccount}
        refresh={handleRefetchTable}
      />
    </Card>
  );
};

export default Transactions;
