import React, { useState } from 'react';
import {
  Box, Card, CardContent, Tab
} from '@mui/material';
import {
  useDestroyPersonalReportMutation,
  useGetPersonalReportsTableQuery,
  useGetPersonalReportFilesMutation,
  useOpenPersonalReportFileMutation,
  useDeletePersonalReportFileMutation, useGetFinancePilotReportsTableQuery
} from '../../../store/slices/personalReportsSlice';
import EditableTable from '../../../components/EditableTable';
import { columns } from './columns';
import Button from '../../../components/Button/Button';
import { GreenButton } from '../../Logistics/style';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import PersonalReportForm from './components/form/PersonalReportForm';
import toast from 'react-hot-toast';
import { setFilesName } from '../../../utils/constants/portalFiles';
import FileDrawer from '../../TrainingModule/FileDrawer/FileDrawer';
import { TabContext, TabList } from '@mui/lab';

const PersonalReportsTab = ({ user }) => {
  const [tabsState, setTabsState] = useState('active');
  const [fileDrawerState, setFileDrawerState] = useState({
    isOpen: false,
    type: '',
    fileList: [],
    name: '',
    currentName: '',
    currentId: null
  });
  const [openModal, setOpenModal] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [getFiles, { isLoading: isLoadingFiles }] = useGetPersonalReportFilesMutation();

  const getFileList = async (id, callback) => {
    const res = await getFiles({ id });

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.');
      } else {
        toast.error('Unable to get file list.');
      }

      callback([]);

      return;
    }

    callback(res?.data || []);
  };

  const handleFileDrawer = (isOpen, id = null, type = null, name = '', currentName = '') => {
    if (id) {
      getFileList(id, (list) => setFileDrawerState(prev => ({
        ...prev,
        isOpen,
        type,
        currentId: id,
        fileList: setFilesName(list),
        name,
        currentName
      })));
    }

    setFileDrawerState(prev => ({
      ...prev,
      isOpen,
      type,
      fileList: [],
      currentId: id,
      name,
      currentName
    }));
  };

  const handleOpenEditForm = (reportID) => {
    setCurrentId(reportID);
    setOpenModal(true);
  };

  const handleCloseEditForm = () => {
    setCurrentId(null);
    setOpenModal(false);
  };
  const handleTabsChange = (event, newValue) => {
    setTabsState(newValue);
  };

  return (
    <Card>
      <CardContent>
        <TabContext value={tabsState}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TabList
              onChange={handleTabsChange}
              aria-label="tabs"
            >
              <Tab value="active" label="Active" />
              <Tab value="draft" label="Draft" />
            </TabList>
            <Button
              title="Create new report"
              sx={{ ...GreenButton, height: '38px' }}
              startIcon={<FlagCircleIcon />}
              onClick={() => setOpenModal(true)}
            />
          </Box>

          <EditableTable
            useHook={useGetPersonalReportsTableQuery}
            useHookDelete={useDestroyPersonalReportMutation}
            getRowHeight={() => 'auto'}
            columns={columns}
            sortByType="DESC"
            sortByActive="created_at"
            handleFileDrawer={handleFileDrawer}
            handleOpenEditForm={handleOpenEditForm}
            tableParams={{
              state: tabsState,
            }}
          />
        </TabContext>
      </CardContent>

      <FileDrawer
        hasUploadButton={false}
        hasDeleteButton={false}
        open={fileDrawerState.isOpen}
        onClose={() => handleFileDrawer(false)}
        title={fileDrawerState.name}
        itemName={fileDrawerState.currentName}
        itemsList={fileDrawerState.fileList}
        hasPermissions
        isLoadingFiles={isLoadingFiles}
        useOpenFileMutation={useOpenPersonalReportFileMutation}
      />

      <PersonalReportForm open={openModal} onClose={handleCloseEditForm} id={currentId} user={user} />
    </Card>
  );
};

export default PersonalReportsTab;
