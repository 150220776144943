import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Card,
  Divider,
  Grid,
  Typography,
  Box
} from '@mui/material';
import { StatusCardStyle } from '../styles';
import FormGroup from '../../../../../../form/components/FormGroup';
import Input from '../../../../../../form/components/Input';
import { WhiteInput } from '../../../../../Finance/InvoiceManagement/styles';

const EditDeclineStatus = ({canEditInput}) => {
  const {
    getValues,
    control,
  } = useFormContext();

  return (
    <Grid item xs={12}>
      <Card sx={StatusCardStyle}>
        <Box>
          <Typography variant="h6">
            Edit
            {' '}
            <b>Decline</b>
            {' '}
            Status:
          </Typography>
        </Box>

        <Typography variant="subtitle2">
          {`${getValues('decline.decliner')} - ${getValues('decline.declined_at')}`}
        </Typography>

        <Divider />

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <FormGroup label="Decline reason">
              <Controller
                name="decline.decline_reason"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    rows={2}
                    placeholder="Enter Reason"
                    multiline
                    disabled={!canEditInput('decline.decline_reason')}
                    sx={{ ...(WhiteInput), width: '100%' }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default EditDeclineStatus;
