import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Card, Divider, Grid, Typography
} from '@mui/material';
import { StatusCardStyle } from '../styles';
import FormGroup from '../../../../../../form/components/FormGroup';
import Input from '../../../../../../form/components/Input';
import { WhiteInput } from '../../../../../Finance/InvoiceManagement/styles';

const EditPendingStatus = ({canEditInput}) => {
  const {
    getValues,
    control,
  } = useFormContext();

  return (
    <Grid item xs={12}>
      <Card sx={StatusCardStyle}>
        <Typography variant="h6">
          Edit
          {' '}
          <b>Pending</b>
          {' '}
          Status:
        </Typography>

        <Typography variant="subtitle2">
          {`${getValues('pending.requester')} - ${getValues('pending.request_at')}`}
        </Typography>

        <Divider />

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <FormGroup label="Pending Notice">
              <Controller
                name="pending.requested_notice"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    rows={2}
                    placeholder="Enter Notice"
                    multiline
                    disabled={!canEditInput('pending.requested_notice')}
                    sx={{ ...(WhiteInput), width: '100%' }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default EditPendingStatus;
