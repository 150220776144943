import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, Breadcrumbs, Card, CircularProgress, Grid
} from '@mui/material';
import {
  BoxHeader, BoxHeaderFirst, CardHeaderSx, MainHeader
} from '../../InvoiceManagement/styles';
import Typography from '@mui/material/Typography';
import FlagIcon from '@mui/icons-material/Flag';
import LinkButton from '../../../../components/LinkButton';
import {
  useGetFinanceCrewProfileQuery,
  useGetFinanceCrewCompensationsTableQuery,
  useGetCompensationFilesMutation,
  useOpenPersonalReportFileMutation,
  useDestroyCompensationMutation
} from '../../../../store/slices/personalReportsSlice';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import EditableTable from '../../../../components/EditableTable';
import { columns } from './columns';
import { setFilesName } from '../../../../utils/constants/portalFiles';
import toast from 'react-hot-toast';
import FileDrawer from '../../../TrainingModule/FileDrawer/FileDrawer';
import CompensationForm from '../CompensationForm/CompensationForm';
import Button from '../../../../components/Button';
import { GreenButton } from '../../../Logistics/style';
import { usePermissions } from '../../../../utils/hooks/usePermissions';

const PilotCompensations = () => {
  const { id } = useParams();
  const hasPermissionsWrite = usePermissions('compensation_write_access');
  const [fileDrawerState, setFileDrawerState] = useState({
    isOpen: false,
    type: '',
    fileList: [],
    name: '',
    currentName: '',
    currentId: null
  });
  const [openModal, setOpenModal] = useState(false);
  const [formId, setFormId] = useState(null);
  const { data: crew, isLoading: crewProfileLoading } = useGetFinanceCrewProfileQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id
  });
  const [getFiles, { isLoading: isLoadingFiles }] = useGetCompensationFilesMutation();

  const getFileList = async (currentId, callback) => {
    const res = await getFiles({ id: currentId });

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.');
      } else {
        toast.error('Unable to get file list.');
      }

      callback([]);

      return;
    }

    callback(res?.data || []);
  };

  const handleFileDrawer = (isOpen, currentId = null, type = null, name = '', currentName = '') => {
    if (currentId) {
      getFileList(currentId, (list) => setFileDrawerState(prev => ({
        ...prev,
        isOpen,
        type,
        currentId,
        fileList: setFilesName(list),
        name,
        currentName
      })));
    }

    setFileDrawerState(prev => ({
      ...prev,
      isOpen,
      type,
      fileList: [],
      currentId,
      name,
      currentName
    }));
  };

  const handleOpenForm = (compensationID) => {
    setFormId(compensationID);
    setOpenModal(true);
  };

  const handleCloseForm = () => {
    setFormId(null);
    setOpenModal(false);
  };

  return (
    <Box>
      {/* Page Header */}
      <Card sx={{ ...CardHeaderSx, mb: 5 }}>
        <Box sx={BoxHeader}>
          <Box sx={{
            ...BoxHeaderFirst, display: 'flex', width: '100%', justifyContent: 'space-between'
          }}
          >
            <Typography noWrap variant="h4" sx={MainHeader}>
              <FlagIcon />
              Compensations
            </Typography>
            {hasPermissionsWrite && (
              <Button
                title="Create Compensations"
                sx={GreenButton}
                onClick={() => setOpenModal(true)}
              />
            )}
          </Box>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <LinkButton path="/finance/personal-reports" style={{ display: 'flex', alignItems: 'center' }}>
              <GroupIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Crews
            </LinkButton>
            <LinkButton path={`/finance/compensations/pilot/${id}`} style={{ display: 'flex', alignItems: 'center' }}>
              <PersonIcon sx={{ mr: 0.5, color: 'rgb(28 70 117)' }} fontSize="inherit" />
              <Typography sx={{ fontWeight: '600' }}>{`${crew?.crew_shortname || ''} Compensations`}</Typography>
            </LinkButton>
          </Breadcrumbs>
        </Box>
      </Card>

      {/* Page Content */}
      <Box>
        {crewProfileLoading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <EditableTable
                  useHook={useGetFinanceCrewCompensationsTableQuery}
                  useHookDelete={useDestroyCompensationMutation}
                  columns={columns}
                  sortByType="DESC"
                  sortByActive="id"
                  getRowClassName={({ id }) => `row-m ${id}`}
                  getRowHeight={() => 'auto'}
                  getRowId={(row) => row.id}
                  tableParams={{
                    crew_id: id
                  }}
                  handleFileDrawer={handleFileDrawer}
                  handleOpenForm={handleOpenForm}
                  crewId={id}
                  hasPermissionsWrite={hasPermissionsWrite}
                />
              </Card>
            </Grid>

            <FileDrawer
              hasUploadButton={false}
              hasDeleteButton={false}
              open={fileDrawerState.isOpen}
              onClose={() => handleFileDrawer(false)}
              title={fileDrawerState.name}
              itemName={fileDrawerState.currentName}
              itemsList={fileDrawerState.fileList}
              hasPermissions
              isLoadingFiles={isLoadingFiles}
              useOpenFileMutation={useOpenPersonalReportFileMutation}
            />

            <CompensationForm open={openModal} onClose={handleCloseForm} id={formId} crewId={id} />
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default PilotCompensations;
