import React, { useEffect, useState } from 'react';
import { IconStyle } from './styles';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import FlightIcon from '@mui/icons-material/Flight';
import FlagIcon from '@mui/icons-material/Flag';
import {
  useGetAircraftListQuery,
  useGetCurrencyListQuery
} from '../../../store/session';
import { getOptions, getOptionsWithData } from '../../../utils/getOptions';
import {
  useGetAircraftReportQuery,
  useGetCreditCardsQuery,
  useUpdateAircraftReportMutation,
  useGetReportTransactionsListQuery
} from '../../../store/slices/aircraftReportsSlice';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { defaultValues, schema } from '../../MyProfile/AircraftReports/AircraftReportDialog/schema';
import { STATUS_SENT } from '../../MyProfile/AircraftReports/constants';
import toast from 'react-hot-toast';
import { useHandleQueryFormError } from '../../../utils/hooks/useHandleQueryFormError';
import { openFileInNewTab } from '../../../utils/utils';

export const useFinanceAircraftReport = () => {
  const [reportStatus, setReportStatus] = useState(STATUS_SENT);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const handleError = useHandleQueryFormError();
  const [user, setUser] = useState({
    name: 'N/A'
  });
  const [aircraft, setAircraft] = useState(null);
  const [report, setReport] = useState(null);
  const [fileNames, setFileNames] = useState([]);

  const [updateAircraftReport, { isLoading: isUpdating }] = useUpdateAircraftReportMutation();

  const { data: aircraftList, isLoading: AircraftLoading } = useGetAircraftListQuery();
  const aircraftOptions = getOptionsWithData(aircraftList);

  const { data: currencies, isLoading: CurrenciesLoading } = useGetCurrencyListQuery();
  const currencyOptions = getOptions(currencies);

  const { data: reportData, isLoading: ReportLoading } = useGetAircraftReportQuery(report, {
    refetchOnMountOrArgChange: true,
    skip: !report
  });

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const { data: creditCards } = useGetCreditCardsQuery(form.watch('aircraft_id'), {
    refetchOnMountOrArgChange: true,
    skip: !form.watch('aircraft_id')
  });
  const creditCardsOptions = getOptions(creditCards);

  const aircraftBreadcrumb = {
    label: 'Aircraft',
    callback: (e) => {
      e.preventDefault();
      setAircraft(null);
      setReport(null);
    },
    // eslint-disable-next-line react/jsx-filename-extension
    icon: (<ConnectingAirportsIcon sx={IconStyle} fontSize="inherit" />)
  };

  const selectedAircraftBreadcrumb = {
    label: `${aircraft?.name}`,
    callback: (e) => {
      e.preventDefault();
      setReport(null);
    },
    // eslint-disable-next-line react/jsx-filename-extension
    icon: (<FlightIcon sx={IconStyle} fontSize="inherit" />)
  };

  const selectedReportBreadcrumb = {
    label: `Report #${report}`,
    callback: () => {},
    // eslint-disable-next-line react/jsx-filename-extension
    icon: (<FlagIcon sx={IconStyle} fontSize="inherit" />)
  };

  useEffect(() => {
    if (aircraft === null && report === null) {
      setBreadcrumbs([
        aircraftBreadcrumb
      ]);
    }

    if (aircraft !== null && report === null) {
      setBreadcrumbs([
        aircraftBreadcrumb,
        selectedAircraftBreadcrumb
      ]);
    }

    if (report !== null) {
      setBreadcrumbs([
        aircraftBreadcrumb,
        selectedAircraftBreadcrumb,
        selectedReportBreadcrumb
      ]);
    }
  }, [aircraft, report]);

  useEffect(() => {
    if (reportData) {
      form.reset(reportData);

      setUser({ name: reportData.responsible });
      setReportStatus(reportData.status);
      setFileNames(
        reportData?.transactions?.map((row, index) => ({
          id: index,
          name: row?.file?.name || '',
        }))
      );
    }
  }, [reportData, ReportLoading]);

  const { data: transactionsList, isLoading: TransactionsLoading } = useGetReportTransactionsListQuery(form.getValues('uuid') ? form.getValues('uuid') : '', {
    refetchOnMountOrArgChange: true,
    skip: !report
  });
  const transactionOptions = getOptionsWithData(transactionsList);

  const handleOpenAircraft = (_, aircraftID, aircraftName) => {
    setAircraft({
      id: aircraftID,
      name: aircraftName
    });
  };

  const handleOpenReport = (_, reportID) => {
    setReport(reportID);
  };

  const onSubmit = async (data) => {
    if ((isUpdating || isUpdating) && report) return;
    const toastId = toast.loading('Loading...');

    const res = await updateAircraftReport({ id: report, data });
    if (res.error) {
      handleError(res.error, form.setError, form.getValues, toastId);

      return;
    }

    toast.success('Success!', {
      id: toastId
    });
  };

  const handleOpenFile = (content, mimeType) => {
    openFileInNewTab(content, mimeType);
  };

  const isLoading = AircraftLoading || CurrenciesLoading || ReportLoading || TransactionsLoading;

  return {
    isLoading,
    form,
    options: {
      breadcrumbs,
      aircraft,
      report,
      aircraftOptions,
      currencyOptions,
      creditCardsOptions,
      fileNames,
      setFileNames,
      user,
      reportStatus,
      setReportStatus,
      transactionOptions,
      hideHeader: true,
      readOnly: true,
    },
    actions: {
      handleOpenAircraft,
      handleOpenReport,
      onSubmit,
      handleOpenFile
    }
  };
};
