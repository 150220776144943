import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../../../components/Button';
import Drawer from '../../../../../../components/Drawer';
import FieldError from '../../../../../../form/components/FieldError';
import FormGroup from '../../../../../../form/components/FormGroup';
import Input from '../../../../../../form/components/Input';
import Select from '../../../../../../form/components/Select';
import ServerAutocomplete from '../../../../../../form/components/ServerAutocomplete';
import { useCreateWOTaskMutation, useGetOdsAtaQuery, useGetTaskListSearchQuery } from '../../../../../../store/session';
import { getOptionsWithKeyValue } from '../../../../../../utils/getOptions';
import { useHandleQueryFormError } from '../../../../../../utils/hooks/useHandleQueryFormError';
import { schema } from './schema';
import { cancelSx, drawer, footerSx } from './styles';

const CreateTaskDrawer = ({
  open, onClose, woId
}) => {
  const maxWidth800px = useMediaQuery('(max-width:800px)');
  const params = useParams();
  const navigate = useNavigate();

  const handleError = useHandleQueryFormError();

  const [assignWoTask, { isLoading }] = useCreateWOTaskMutation();

  const { data: odsAta } = useGetOdsAtaQuery();

  const odsAtaOptions = getOptionsWithKeyValue(odsAta);

  const {
    handleSubmit,
    setValue,
    control,
    reset,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const handleCreateTask = async (data) => {
    const toastId = toast.loading('Loading...');
    const res = await assignWoTask({ ...data, wo_id: params.id });

    if (res?.error) {
      // handleError(res.error, setError, getValues, toastId);
      toast.error(res.error.data?.message, {
        id: toastId,
      });

      return;
    }

    reset();

    onClose();

    toast.success('Successfully!', {
      id: toastId
    });

    navigate(`/maintenance/work-order-task/update/${res.data.id}`);
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  const handleSelectJob = (selection) => {
    const option = selection || { value: null, label: '' };

    setValue('task', option?.value || null);
  };

  return (
    <Drawer
      title="Task"
      open={open}
      onClose={onClose}
      sx={{ ...(maxWidth800px && drawer) }}
    >
      <form onSubmit={handleSubmit(handleCreateTask)}>
        <Grid
          container
          spacing={5}
          rowSpacing={2}
          sx={{ p: '30px' }}
        >
          <Grid item xs={maxWidth800px ? 12 : 6}>
            <FormGroup label="Name" required hasError={!!errors.name}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="Name"
                    {...field}
                  />
                )}
                control={control}
                name="name"
              />

              <FieldError error={errors.name} />
            </FormGroup>
          </Grid>

          <Grid item xs={maxWidth800px ? 12 : 6}>
            <FormGroup label="Description" required hasError={!!errors.description}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="Description"
                    {...field}
                  />
                )}
                control={control}
                name="description"
              />

              <FieldError error={errors.description} />
            </FormGroup>
          </Grid>

          <Grid item xs={maxWidth800px ? 12 : 6}>
            <FormGroup label="Title" required hasError={!!errors.title}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="Title"
                    {...field}
                  />
                )}
                control={control}
                name="title"
              />

              <FieldError error={errors.title} />
            </FormGroup>
          </Grid>

          <Grid item xs={maxWidth800px ? 12 : 6}>
            <FormGroup label="Task" hasError={!!errors.task}>
              <Controller
                name="task"
                control={control}
                render={({ field }) => (
                  <ServerAutocomplete
                    fields={field}
                    placeholder="Search Task"
                    disabled={!woId}
                    searchQueryFn={useGetTaskListSearchQuery}
                    queryParams={{ aircraft_id: woId }}
                    handleSelect={handleSelectJob}
                  />
                )}
              />

              <FieldError error={errors.task} />
            </FormGroup>
          </Grid>

          <Grid item xs={maxWidth800px ? 12 : 6}>
            <FormGroup label="Ref" hasError={!!errors.ref}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="Ref"
                    {...field}
                  />
                )}
                control={control}
                name="ref"
              />

              <FieldError error={errors.ref} />
            </FormGroup>
          </Grid>

          <Grid item xs={maxWidth800px ? 12 : 6}>
            <FormGroup label="Interval" hasError={!!errors.ref}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="Interval"
                    {...field}
                  />
                )}
                control={control}
                name="interval"
              />

              <FieldError error={errors.interval} />
            </FormGroup>
          </Grid>

          <Grid item xs={maxWidth800px ? 12 : 6}>
            <FormGroup label="Zone" hasError={!!errors.zone}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="Zone"
                    {...field}
                  />
                )}
                control={control}
                name="zone"
              />

              <FieldError error={errors.zone} />
            </FormGroup>
          </Grid>

          <Grid item xs={maxWidth800px ? 12 : 6}>
            <FormGroup label="Access" hasError={!!errors.access}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="Access"
                    {...field}
                  />
                )}
                control={control}
                name="access"
              />

              <FieldError error={errors.access} />
            </FormGroup>
          </Grid>

          <Grid item xs={maxWidth800px ? 12 : 6}>
            <FormGroup label="Action Taken" hasError={!!errors.action_taken}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="Action Taken"
                    {...field}
                  />
                )}
                control={control}
                name="action_taken"
              />

              <FieldError error={errors.action_taken} />
            </FormGroup>
          </Grid>

          <Grid item xs={maxWidth800px ? 12 : 6}>
            <FormGroup label="Task Group" hasError={!!errors.task_group}>
              <Controller
                render={({ field } ) => (
                  <Input
                    placeholder="Task Group"
                    {...field}
                  />
                )}
                control={control}
                name="task_group"
              />

              <FieldError error={errors.task_group} />
            </FormGroup>
          </Grid>

          <Grid item xs={maxWidth800px ? 12 : 6}>
            <FormGroup label="ATA">
              <Controller
                render={({ field } ) => (
                  <Select
                    options={odsAtaOptions}
                    {...field}
                  />
                )}
                control={control}
                name="ata"
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Box sx={footerSx}>
          <Button disabled={isLoading} type="submit" variant="contained" title="Create Task" />
          <Button sx={cancelSx} title="Close" onClick={onClose} />
        </Box>
      </form>
    </Drawer>
  );
};

export default CreateTaskDrawer;
