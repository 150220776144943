import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Divider,
  Grid,
  Card
} from '@mui/material';
import React, { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Button from '../../components/Button';
import Autocomplete from '../../form/components/Autocomplete';
import Input from '../../form/components/Input';
import { useUpdateBankTransactionsCurrencyMutation } from '../../store/session';
import {
  btn,
  containerInvoice,
  content,
} from './style';
import toast from 'react-hot-toast';
import { WhiteInput } from '../Finance/InvoiceManagement/styles';
import FormGroup from '../../form/components/FormGroup/FormGroup';
import { GreenButton, RedButton } from '../Logistics/style';
import AddCardIcon from '@mui/icons-material/AddCard';
import CreditCard from './components/CreditCard/CreditCard';
import AddCreditCard from './components/CreditCard/AddCreditCard';
import { usePermissions } from '../../utils/hooks/usePermissions';

const CardsTable = ({ bank = null, currencyOptions }) => {
  const [openCard, setOpenCard] = useState(false);
  const { control, watch, setValue } = useFormContext();
  const [updateBankTransactionsCurrency] = useUpdateBankTransactionsCurrencyMutation();
  const hasCreditCardPermission = usePermissions('bank_card_edit', false);

  const {
    fields,
    append,
    remove,
    update,
  } = useFieldArray({ name: 'account_numbers', control });

  const handleAddCard = (index, data) => {
    setOpenCard({ index, data });
  };

  const addAccountNumber = () => {
    append({
      account_number: null,
      cards: [],
    });
  };

  const syncCurrency = async () => {
    if (!bank) {
      return;
    }
    const toastId = toast.loading('Loading...');

    const res = await updateBankTransactionsCurrency(bank.id);

    if (res.error) {
      toast.error(res.error?.data?.message ? res.error.data.message : 'Error', {
        id: toastId,
      });

      return;
    }

    toast.success('Successfully synced', {
      id: toastId,
    });
  };

  return (
    <Box sx={content}>
      {fields?.map((item, index) => (
        <Card sx={{ padding: '16px', backgroundColor: '#e9e9e9' }} key={item.id}>
          <Grid container spacing={5} sx={{ display: 'flex', }}>
            <Grid item xs={12}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              >
                <Button
                  title="Delete Account Number"
                  sx={RedButton}
                  onClick={() => remove(index)}
                  startIcon={<DeleteIcon />}
                />

                <Button
                  title="Add Card"
                  sx={GreenButton}
                  startIcon={<AddCardIcon />}
                  disabled={!hasCreditCardPermission}
                  onClick={() => handleAddCard(index, item)}
                />
              </Box>
              <Divider />
            </Grid>

            <Grid item xs={12} lg={5}>
              <Grid container spacing={5}>
                <Grid item xs={12} lg={8}>
                  <FormGroup label="Account number" required>
                    <Controller
                      name={`account_numbers.${[index]}.account_number`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          sx={WhiteInput}
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} lg={4}>
                  <FormGroup label="Currency">
                    <Controller
                      name={`account_numbers.${[index]}.currency_id`}
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          sx={WhiteInput}
                          value={currencyOptions?.filter((currency) => Number(currency?.value) === Number(field?.value))?.[0]}
                          options={currencyOptions}
                          size="small"
                          placeholder="Select"
                          onChange={(e, value ) => {
                            field.onChange(value);
                            setValue(`account_numbers.${index}.currency_id`, value?.value || null);
                          }}
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup label="Account number notes">
                    <Controller
                      name={`account_numbers.${[index]}.account_number_notes`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          sx={WhiteInput}
                          multiline
                          rows={4}
                          {...field}
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={7} sx={containerInvoice}>
              {item?.cards?.length > 0 && (
                <Box sx={{ marginTop: '1rem' }}>
                  <Grid container spacing={6}>
                    {watch(`account_numbers.${[index]}`)?.cards?.map((card, subIndex) => (
                      <CreditCard
                        card={card}
                        index={index}
                        subIndex={subIndex}
                        update={update}
                        key={subIndex}
                      />
                    ))}
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
        </Card>
      ))}

      <Grid sx={{ display: 'flex' }}>
        <Button
          sx={{ ...(btn), ...(GreenButton) }}
          title="Add account number"
          onClick={addAccountNumber}
          startIcon={<AddIcon />}
        />
        {(bank && bank.bank_name.includes('TAKAREK')) && <Button sx={{ ...btn, marginLeft: '16px' }} title="Sync currency" onClick={syncCurrency} />}
      </Grid>

      <AddCreditCard open={openCard} onClose={() => setOpenCard(false)} update={update} />
    </Box>
  );
};

export default CardsTable;
