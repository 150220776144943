import React, { useState } from 'react';
import TypeCard from '../TypeCard';
import { Grid, Checkbox, Typography } from '@mui/material';
import FormGroup from '../../../../form/components/FormGroup';
import { Controller } from 'react-hook-form';
import {
  APPROACH_OPTIONS,
  APPROACH_TYPE_CAT_2_NOT_SATISFACTORY,
  APPROACH_TYPE_CAT_2_SATISFACTORY
} from '../../OdsApproachTypeStatistics/constants';

const ApproachType = ({ control, setValue, isPanel }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleErrorMessage = (value) => {
    if (value === APPROACH_TYPE_CAT_2_SATISFACTORY) {
      setErrorMessage('In case of CAT II Approach not forget to fill "CAT II Approach and Landing report"!');
    } else if (value === APPROACH_TYPE_CAT_2_NOT_SATISFACTORY) {
      setErrorMessage('In case of CAT II not satisfactory, please indicate corresponding defects if any.');
    } else {
      setErrorMessage('');
    }
  };

  return (
    <TypeCard title="APPROACH TYPE" variant="yellow" isPanel={isPanel}>
      <Typography sx={{
        color: 'red', position: 'absolute', top: 20, left: 178, fontWeight: 'bold'
      }}>
        {errorMessage}
      </Typography>
      <Grid container spacing={7} rowSpacing={2} sx={{ flexGrow: 1 }}>
        {APPROACH_OPTIONS.map((option) => (
          <Grid item xs={12} lg={4} key={option.value}>
            <FormGroup label={option.label}>
              <Controller
                name="approach_type"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value === option.value}
                    onChange={(e) => {
                      setValue('approach_type', e.target.checked ? option.value : null, { shouldDirty: true });
                      handleErrorMessage(option.value);
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
        ))}
      </Grid>
    </TypeCard>
  );
};

export default ApproachType;
