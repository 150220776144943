import { useGetTransactionUpdateDataQuery, useUpdateTransactionMutation } from '../../../../../store/slices/financeSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import { schema, defaultValues } from './schema';
import toast from 'react-hot-toast';

export const useStatusType = (trxId, onClose) => {
  const { data: transaction, isLoading: getLoading } = useGetTransactionUpdateDataQuery(trxId, {
    refetchOnMountOrArgChange: true,
    skip: !trxId,
  });
  const [update, { isLoading: updateLoading }] = useUpdateTransactionMutation();

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
    shouldFocusError: false,
  });

  useEffect(() => {
    if (transaction && !getLoading) {
      form.setValue('status_type', transaction.status_type);
      form.setValue('comments_external', transaction.comments_external);
      form.setValue('id', transaction.id);
    }
  }, [transaction, getLoading]);

  const isLoading = updateLoading || getLoading;

  const onSubmit = useCallback(async (data) => {
    if (isLoading) return;
    const toastId = toast.loading('Loading...');

    if (data.status_type !== 'incorrect') {
      delete data.comments_external;
    }

    await update({ id: trxId, data }).then((res) => {
      if (!res?.errros) {
        toast.success('Success!', {
          id: toastId,
        });
        onClose();
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  });

  return {
    transaction,
    form,
    onSubmit,
    isLoading
  };
};
