import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Card,
  Divider,
  Grid,
  Typography,
  Box
} from '@mui/material';
import { StatusCardStyle } from '../styles';
import FormGroup from '../../../../../../form/components/FormGroup';
import Input from '../../../../../../form/components/Input';
import { WhiteInput } from '../../../../../Finance/InvoiceManagement/styles';

const EditApproveStatus = ({canEditInput}) => {
  const {
    getValues,
    control,
  } = useFormContext();

  return (
    <Grid item xs={12}>
      <Card sx={StatusCardStyle}>
        <Box>
          <Typography variant="h6">
            Edit
            {' '}
            <b>Approve</b>
            {' '}
            Status:
          </Typography>
        </Box>

        <Typography variant="subtitle2">
          {`${getValues('approved.approver')} - ${getValues('approved.approved_at')}`}
        </Typography>

        <Divider />

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <FormGroup label="Price Notice">
              <Controller
                name="approved.approver_notice"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    rows={2}
                    placeholder="Enter Notice"
                    multiline
                    disabled={!canEditInput('approved.approver_notice')}
                    sx={{ ...(WhiteInput), width: '100%' }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default EditApproveStatus;
