import React from 'react';
import EditableTable from '../../../../components/EditableTable';
import { useGetFinanceAircraftReportsTableQuery } from '../../../../store/slices/aircraftReportsSlice';
import { columns } from './columns';
import { Card } from '@mui/material';

const AircraftTable = ({ actions }) => (
  <Card sx={{ padding: '0.5rem' }}>
    <EditableTable
      useHook={useGetFinanceAircraftReportsTableQuery}
      sortByType="DESC"
      sortByActive="id"
      columns={columns}
      handleOpenAircraft={actions.handleOpenAircraft}
    />
  </Card>
);

export default AircraftTable;
