import React from 'react';
import {
  Dialog,
  DialogContent,
  Divider,
} from '@mui/material';
import {
  DialogCardContent,
  DialogMaxHeight
} from '../../../../assets/styles';
import { FormProvider } from 'react-hook-form';
import AircraftReportForm from './AircraftReportForm';
import { useAircraftReportDialog } from './useAircraftReportDialog';
import DialogHeader from './DialogHeader';
import { loaderIcon } from '../../../CrewForm/Documentation/Card/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const AircraftReportDialog = (props) => {
  const {
    open,
    onClose,
    reportID,
    user
  } = props;

  const {
    isCreate,
    form,
    onSubmit,
    isLoading,
    options,
    actions
  } = useAircraftReportDialog(reportID, onClose);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={onClose}
      sx={DialogMaxHeight}
    >
      <DialogContent>
        {isLoading ? (
          <Box sx={loaderIcon}>
            {' '}
            <CircularProgress />
            {' '}
          </Box>
        )
          : (
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} style={{ height: '100%' }}>
                <DialogCardContent>
                  {/* Popup Header */}
                  <DialogHeader options={options} isCreate={isCreate} onClose={onClose} />

                  <Divider />

                  {/* Form */}
                  <AircraftReportForm options={options} user={user} actions={actions} />
                </DialogCardContent>
              </form>
            </FormProvider>
          )}
      </DialogContent>
    </Dialog>
  );
};

export default AircraftReportDialog;
