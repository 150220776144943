import { createSlice } from '@reduxjs/toolkit';
import { sessionApi } from '../session';
import { useProvideTags } from '../../utils/hooks/useProvideTags';

export const aircraftReportSlice = createSlice({
  name: 'aircraftReports',
  initialState: {},
});

sessionApi.injectEndpoints({
  endpoints: (builder) => ({

    getCreditCards: builder.query({
      query: (aircraftID) => `/aircraft_reports/credit_cards/list?aircraft_id=${aircraftID}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),

    storeAircraftReport: builder.mutation({
      query: (data) => ({
        url: '/aircraft_reports',
        body: data,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['GET_TABLE_AIRCRAFT_REPORTS', 'GET_AIRCRAFT_REPORT']),
    }),

    getAircraftReport: builder.query({
      query: (id) => `/aircraft_reports/${id}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_AIRCRAFT_REPORT']),
    }),

    getAircraftReportsTable: builder.query({
      query: (data) => `/aircraft_reports${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_TABLE_AIRCRAFT_REPORTS']),
    }),

    updateAircraftReport: builder.mutation({
      query: ({ id, data }) => ({
        url: `/aircraft_reports/${id}`,
        body: data,
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['GET_TABLE_AIRCRAFT_REPORTS', 'GET_AIRCRAFT_REPORT']),
    }),

    getFinanceAircraftReportsTable: builder.query({
      query: (data) => `/aircraft_reports/finance/table${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_FINANCE_TABLE_AIRCRAFT_REPORTS']),
    }),

    getFinanceReportsAircraftTable: builder.query({
      query: (data) => `/aircraft_reports/finance/table/aircraft${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_FINANCE_TABLE_REPORTS_AIRCRAFT']),
    }),

    getReportTransactionsList: builder.query({
      query: (aircraft_report_uuid) => `/aircraft_reports/finance/transactions?aircraft_report_uuid=${aircraft_report_uuid}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),

    getTransactionFile: builder.mutation({
      query: (id) => ({
        url: `/aircraft_reports/transaction/file/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),

  }),
});

export const {
  useGetCreditCardsQuery,
  useStoreAircraftReportMutation,
  useGetAircraftReportQuery,
  useGetAircraftReportsTableQuery,
  useUpdateAircraftReportMutation,
  useGetFinanceAircraftReportsTableQuery,
  useGetFinanceReportsAircraftTableQuery,
  useGetReportTransactionsListQuery,
  useGetTransactionFileMutation
} = sessionApi;

export default aircraftReportSlice.reducer;
