import React, { useState } from 'react';
import { boxInvoice } from '../../style';
import { useFormContext } from 'react-hook-form';
import { Box, Grid } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import Card from 'react-credit-cards';
import EditCreditCard from './EditCreditCard';
import {usePermissions} from "../../../../utils/hooks/usePermissions";

const CreditCard = (props) => {
  const {
    index,
    subIndex,
    update
  } = props;

  const {
    watch,
  } = useFormContext();

  const [openEditCard, setOpenEditCard] = useState(false);
  const hasCreditCardPermission = usePermissions('bank_card_edit', false);

  return (
    <Grid item xs={6} sx={{ mb: 2 }} key={subIndex}>
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          if (document.getSelection().type === 'Range') return;
          setOpenEditCard(!!hasCreditCardPermission);
        }}
      >
        <Card
          cvc={watch(`account_numbers.${[index]}.cards.${[subIndex]}.cvc`) || ''}
          expiry={watch(`account_numbers.${[index]}.cards.${[subIndex]}.expire_date`) || ''}
          name={watch(`account_numbers.${[index]}.cards.${[subIndex]}.cardholder`) || ''}
          number={watch(`account_numbers.${[index]}.cards.${[subIndex]}.card_number`) || ''}
        />
      </Box>

      <EditCreditCard
        open={openEditCard}
        onClose={() => setOpenEditCard(false)}
        accountIndex={index}
        cardIndex={subIndex}
        update={update}
      />
    </Grid>
  );
};

export default CreditCard;
