import React from 'react';
import { Grid } from '@mui/material';
import { StyledCard } from '../../../../../../assets/styles';
import FormGroup from '../../../../../../form/components/FormGroup';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker from '../../../../../../form/components/DatePicker';
import CheckBox from '../../../../../../form/components/Checkbox';
import { setDateValue, validateDatePickerValue } from '../../../../../../utils/setDateValue';
import CreditCards from '../CreditCards/CreditCards';
import { WhiteInput } from '../../../../../Finance/InvoiceManagement/styles';

const FormFooter = ({ options }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <StyledCard sx={{ backgroundColor: '#f5f5f5' }}>
      <Grid container spacing={6}>
        {/* Handed over Date */}
        <Grid item xs={12} lg={4}>
          <FormGroup label="Handed over date" required hasError={!!errors.handed_over_date}>
            <Controller
              name="handed_over_date"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  sx={WhiteInput}
                  placeholderText="YYYY-MM-DD"
                  dateFormat="yyyy-MM-dd"
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) => {
                    setDateValue(e, setValue, 'handed_over_date', 'YYYY-MM-DD');
                  }}
                  value={validateDatePickerValue(field.value)}
                  disabled={options.readOnly}
                />
              )}
            />
          </FormGroup>
        </Grid>

        {/* Handed over Card */}
        <Grid item xs={12} lg={4}>
          <CreditCards options={options} type="handed_over" />
        </Grid>

        <Grid item xs={12} lg={4}>
          <FormGroup label="Confirm Handed Over" hasError={!!errors.handed_over_confirm}>
            <Controller
              name="handed_over_confirm"
              control={control}
              render={({ field }) => (
                <CheckBox
                  {...field}
                  disabled={options.readOnly}
                />
              )}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </StyledCard>
  );
};

export default FormFooter;
