import {
  array, object, string
} from 'yup';

export const defaultValues = {
  crew_id: '',
  report_id: '',
  rows: [
    {
      type: '',
      amount: 0,
      comments: '',
      file: {
        content: '',
        name: '',
      }
    }
  ],

};

export const schema = object().shape({
  rows: array().of(object({}).shape({
    type: string().required('Type is required'),
    amount: string()
      .required('Amount is required')
      .test('not-zero', 'Amount cannot be zero', (value) => parseFloat(value) > 0),
    comments: string().required('Comments is required'),
  }))
});
