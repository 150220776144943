import React from 'react';
import {
  Box, Card, Grid, Typography
} from '@mui/material';
import { BoxFlexBetween, StyledCard } from '../../../../../../assets/styles';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import FormGroup from '../../../../../../form/components/FormGroup';
import Input from '../../../../../../form/components/Input';
import { WhiteInput } from '../../../../../Finance/InvoiceManagement/styles';
import Select from '../../../../../../form/components/Select';
import DatePicker from '../../../../../../form/components/DatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '../../../../../../components/Button';
import { GreenButton } from '../../../../../Logistics/style';
import { defaultValues } from '../../schema';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { setDateValue, validateDatePickerValue } from '../../../../../../utils/setDateValue';
import { numberFormatValidate } from '../../../../../../utils/number';

const CashBalance = ({ options, arrayName }) => {
  const { currencyOptions } = options;

  const { control, setValue } = useFormContext();

  const {
    fields: rowFields,
    append: rowAppend,
    remove: rowRemove
  } = useFieldArray({
    name: arrayName,
    control,
  });

  return (
    <StyledCard sx={{ backgroundColor: '#f5f5f5' }}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <BoxFlexBetween>
            <Box>
              <Typography variant="h6" noWrap>
                {arrayName === 'cash_balances' ? 'Taken Over Cash Balances' : 'Handed Over Cash Balances'}
              </Typography>
            </Box>

            {!options.readOnly && (
              <Button
                startIcon={<KeyboardArrowDownIcon />}
                title="Add row"
                sx={GreenButton}
                onClick={() => (arrayName === 'cash_balances'
                  ? rowAppend(defaultValues.cash_balances[0])
                  : rowAppend(defaultValues.handed_over_cash_balances[0]))}
              />
            )}

          </BoxFlexBetween>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={6}>
            {rowFields.map((row, index) => (
              <Grid item xs={12} key={row.id}>
                <Card sx={{ padding: '1rem', backgroundColor: 'rgba(54,140,134,0.28)' }}>
                  <Grid container spacing={6}>
                    {/* Amount */}
                    <Grid item xs={12} lg={4}>
                      <FormGroup label="Amount">
                        <Controller
                          name={`${arrayName}[${index}].amount`}
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              sx={WhiteInput}
                              disabled={options.readOnly}
                              type="number"
                              placeholder="Amount"
                              style={{ width: '100%' }}
                              onChange={(e) => {
                                const { value } = e.target;

                                if (numberFormatValidate(value, 11)) {
                                  field.onChange(value);
                                }
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>

                    {/* Currency */}
                    <Grid item xs={12} lg={4}>
                      <FormGroup label="Currency">
                        <Controller
                          name={`${arrayName}[${index}].currency_id`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              sx={WhiteInput}
                              {...field}
                              placeholder="Select Amount Currency"
                              options={currencyOptions}
                              disabled={currencyOptions.length === 0 || options.readOnly}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>

                    {/* Received Date */}
                    <Grid item xs={12} lg={3}>
                      <FormGroup label="Cash Received Date">
                        <Controller
                          name={`${arrayName}[${index}].cash_received_date`}
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              sx={WhiteInput}
                              placeholderText="YYYY-MM-DD"
                              dateFormat="yyyy-MM-dd"
                              showMonthDropdown
                              showYearDropdown
                              onChange={(e) => {
                                setDateValue(e, setValue, `${arrayName}[${index}].cash_received_date`, 'YYYY-MM-DD');
                              }}
                              value={validateDatePickerValue(field.value)}
                              disabled={options.readOnly}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>

                    { (index !== 0 && !options.readOnly) && (
                      <Grid item xs={12} lg={1} sx={{ alignContent: 'center' }}>
                        <DeleteIcon
                          onClick={() => rowRemove(index)}
                          style={{ cursor: 'pointer' }}
                        />
                      </Grid>
                    )}

                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </StyledCard>
  );
};

export default CashBalance;
