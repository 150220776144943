import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { HeaderName } from '../../../../components/EditableTable/styles';
import React from 'react';

export const columns = ({ handleOpenAircraft }) => ([
  {
    flex: 0.2,
    field: 'aircraft_registration',
    minWidth: 200,
    headerName: 'Aircraft',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Aircraft</Typography>
      </Box>
    ),
    renderCell: ({ row: { id, aircraft_registration } }) => (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          textDecorationLine: 'underline',
          cursor: 'pointer'
        }}
        variant="subtitle1"
        onClick={(e) => handleOpenAircraft(e, id, aircraft_registration)}
      >
        {`${aircraft_registration}`}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'total_reports',
    minWidth: 200,
    headerName: 'Reports Count',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Reports Count</Typography>
      </Box>
    ),
    renderCell: ({ row: { total_reports } }) => (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
        }}
        variant="subtitle1"
      >
        {total_reports}
      </Typography>
    ),
  },
]);
