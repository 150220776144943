import { useGetAircraftListQuery } from '../../../store/session';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { columns_all, columnGroupingModel } from './columns_all';
import { columns, columnGroupingModelAR } from './columns';
import { getOptionsWithData } from '../../../utils/getOptions';

export const useAirworthinessDirective = () => {
  const params = useParams();
  const navigate = useNavigate();

  const hasPermissions = usePermissions(['ad_read', 'ad_write']);
  const hasWritePermissions = usePermissions('ad_write');

  const { data: aircraft, isLoading: AircraftLoading } = useGetAircraftListQuery('?with_current_ods=true');
  const [openModal, setOpenModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [currentAD, setCurrentAD] = useState(null);
  const [activeType, setActiveType] = useState(+params.id);
  const [aircraftType, setAircraftType] = useState([]);
  const [search, setSearch] = useState('');
  const aircraftOptions = getOptionsWithData(aircraft);

  const isLoading = AircraftLoading;
  const maxWidth768px = useMediaQuery('(max-width:840px)');

  const allTypesSelected = activeType === 'all';

  const handleSetActiveType = (data) => {
    setActiveType(data.id);
    setAircraftType(data);
    navigate(`/maintenance/airworthiness-directives/${data.id}`);
  };

  const handleOpenModal = (adID) => {
    setCurrentAD(adID);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setCurrentAD(null);
    setOpenModal(false);
  };

  useEffect(() => {
    const res = aircraft?.find(item => item.id === +params.id);

    if (res) {
      setActiveType(res.id);
      setAircraftType(res);
    } else {
      setActiveType('all');
      setAircraftType([]);
    }
  }, [aircraft]);

  const handleTable = (row) => {
    if (activeType === 'all') {
      return {
        columns: columns_all,
        group: columnGroupingModel,
      };
    }

    return {
      columns,
      group: columnGroupingModelAR,
    };
  };

  return {
    isLoading,
    aircraft,
    openModal,
    setOpenModal,
    maxWidth768px,
    activeType,
    setActiveType,
    aircraftType,
    setAircraftType,
    handleSetActiveType,
    hasPermissions,
    hasWritePermissions,
    search,
    setSearch,
    allTypesSelected,
    handleCloseModal,
    handleOpenModal,
    currentAD,
    handleTable,
    openExportModal,
    setOpenExportModal,
    aircraftOptions
  };
};
