import React from 'react';
import { Card, Grid, Typography } from '@mui/material';
import Button from '../../../../../components/Button';
import { GreenButton, RedButton } from '../../../../Logistics/style';
import FormGroup from '../../../../../form/components/FormGroup';
import { Controller, useFormContext } from 'react-hook-form';
import Input from '../../../../../form/components/Input';
import { WhiteInput } from '../../../InvoiceManagement/styles';
import {
  STATUS_CLOSED,
  STATUS_DECLINED,
  STATUS_SENT,
  STATUS_SUBMITTED
} from '../../../../MyProfile/AircraftReports/constants';
import { usePermissions } from '../../../../../utils/hooks/usePermissions';

const SubmitCard = ({ options }) => {
  const {
    control,
    formState: { errors },
    setValue,
    watch
  } = useFormContext();

  const canEditPermission = usePermissions('aircraft_report_write');

  return (
    <Card sx={{
      padding: '1rem',
      backgroundColor: 'rgba(29,69,113,0.2)',
    }}
    >
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h6" noWrap>
            <b>Report Status: </b>
            {options.reportStatus}
          </Typography>
        </Grid>

        {[STATUS_DECLINED, STATUS_SENT].includes(options.reportStatus) && (
          <Grid item xs={12} lg={6}>
            <Button
              sx={GreenButton}
              type="Submit"
              title="Submit Report"
              disabled={!canEditPermission || watch('decline_purpose')}
              onClick={() => setValue('status', STATUS_SUBMITTED)}
            />
          </Grid>
        )}

        {[STATUS_SUBMITTED, STATUS_CLOSED].includes(options.reportStatus) && (
          <Grid item xs={12} lg={6}>
            <Button
              sx={GreenButton}
              type="Submit"
              title={options.reportStatus === STATUS_SUBMITTED ? 'Close Report' : 'Update Report'}
              disabled={!canEditPermission}
              onClick={() => setValue('status', STATUS_CLOSED)}
            />
          </Grid>
        )}

        <Grid item xs={12} lg={6}>
          <Button
            sx={RedButton}
            type="Submit"
            title="Decline Report"
            disabled={!canEditPermission}
            onClick={() => setValue('status', STATUS_DECLINED)}
          />
        </Grid>

        <Grid item xs={12}>
          <FormGroup hasError={!!errors.decline_purpose}>
            <Controller
              name="decline_purpose"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Decline Purpose"
                  sx={WhiteInput}
                  multiline
                  rows={6}
                />
              )}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SubmitCard;
