export const documentForm = {
  width: '100%',
};

export const block = {
  width: '100%',
  mb: '20px',
};

export const file = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  mb: '10px',
};

export const groupTitleSx = {
  cursor: 'pointer',
  pl: '10px',
};

export const label = {
  '& label': {
    marginBottom: '5px ',
  }
};

export const button = {
  '& label': {
    marginBottom: '0px !important',
  }
};

export const fileName = {
  marginTop: '14px',
  marginLeft: '10px',
  cursor: 'pointer',
  textTransform: 'capitalize',
  whiteSpace: 'break-spaces',
  textDecorationLine: 'underline'
};
