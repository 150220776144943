import { object, string } from 'yup';

export const defaultValues = {
  status_type: 'default',
  comments_external: '',
};

export const schema = object().shape({
  status_type: string()
    .required('${label} cannot be blank.')
    .nullable()
    .label('Status Type'),
  comments_external: string().when('status_type', {
    is: 'incorrect',
    then: string()
      .nullable()
      .required('Comments is required when Status Type is "Incorrect"'),
    otherwise: string().nullable(),
  }),
});
