export const GENERAL_STATUSES_OPTIONS = [
  { value: 'open', label: 'Open' },
  { value: 'in_progress', label: 'In Progress' },
  { value: 'na', label: 'N/A' },
  { value: 'superseded', label: 'Superseded' },
  { value: 'undefined', label: 'Undefined' }
];

/**
 * 'closed',
 *      *  'open',
 *      *  'repetitive',
 *      *  'closed_with_nsv',
 *      *  'transferred_to_task',
 *      *  'not_applicable',
 *      *  'superseded',
 *      *  'cancelled'
 *
 * @type {*[]}
 */
export const AIRCRAFT_DATA_STATUSES = [
  { value: 'closed', label: 'Closed' },
  { value: 'open', label: 'Open' },
  { value: 'repetitive', label: 'Repetitive' },
  { value: 'closed_with_nsv', label: 'Closed with NSV' },
  { value: 'transferred_to_task', label: 'Transferred to Task' },
  { value: 'not_applicable', label: 'Not applicable' },
  { value: 'superseded', label: 'Superseded' },
  { value: 'cancelled', label: 'Cancelled' },
];

export const EVALUATION_DONE_OPTIONS = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
  { value: 'n/a', label: 'N/A' },
];

export const AR_DIR_STATUSES = [
  { value: 'open', label: 'OP' },
  { value: 'closed', label: 'CL' },
  { value: 'repetitive', label: 'R' },
  { value: 'closed_with_nsv', label: 'CW' },
  { value: 'transferred_to_task', label: 'TT' },
  { value: 'not_applicable', label: 'NA' },
  { value: 'superseded', label: 'SS' },
  { value: 'cancelled', label: 'CN' },
];
