import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import React from 'react';
import EditableTable from '../../../components/EditableTable';
import LinkButton from '../../../components/LinkButton';
import { columns, columnsOther } from './columns';
import DropDown from './DropDown';
import DropDownCreateForm from './DropDownCreateForm';
import {
  actionPerformed,
  activeTypeSx,
  downloadBtn,
  header,
  headerRow,
  headerSx,
  otherBtn,
  tableContainer,
  TitleContainer,
  types,
  typesContainer,
  typesSmall,
  typeSx,
} from './styles';
import { useOds } from './useOds';

const Ods = () => {
  const {
    activeType,
    aircraftTypeT,
    aircraft,
    maxWidth1000px,
    maxWidth733px,
    tableParams,
    isLoading,
    open,
    anchorEl,
    idRow,
    openCreteForm,
    odsStatuses,
    anchorElCreteForm,
    closeMenuCreteForm,
    handleOpen,
    closeMenu,
    tableId,
    handleDoc,
    useGetOdsOtherQuery,
    useGetOdsQuery,
    handleSetActiveType,
    hasPermissionsOdsCreate,
    openMenuCreteForm,
    odsRecalculate,
  } = useOds();

  return (
    <Card sx={{ padding: '1rem' }}>
      <Box sx={TitleContainer}>
        <Typography variant="h3">ODS</Typography>
      </Box>

      <Box sx={{ ...headerSx, ...(maxWidth733px && header) }}>
        <Box sx={typesContainer}>
          {aircraft?.map((type) => (
            <Button
              sx={{ ...typeSx, ...(type.id === activeType && activeTypeSx) }}
              key={type.id}
              onClick={() => handleSetActiveType(type.id)}
            >
              {type.aircraft_registration}
            </Button>
          ))}
          <Button
            sx={{ ...typeSx, ...otherBtn, ...(activeType === 'other' && activeTypeSx) }}
            onClick={() => handleSetActiveType('other')}
          >
            OTHER
          </Button>
        </Box>

        <Box
          sx={{
            ...headerRow,
            ...(maxWidth1000px && types),
            ...(maxWidth733px && typesSmall),
          }}
        >
          <Button disabled={!hasPermissionsOdsCreate} sx={typeSx} onClick={openMenuCreteForm}>
            Actions
          </Button>

          <LinkButton
            style={{ ...typeSx, ...actionPerformed }}
            path="/directories/ods-action"
          >
            Actions performed
          </LinkButton>

          <Button sx={{ ...typeSx, ...downloadBtn }} onClick={handleDoc}>
            Download
          </Button>
        </Box>
      </Box>
      <Box sx={{ ...tableContainer, mt: 3 }}>
        {(tableId === 'other') ? (

          <EditableTable
            getRowId={(row) => row?.id}
            getRowClassName={(params) => `row-m row-${params?.row.ods_status === 1 && 'cnld'}`}
            useHook={useGetOdsOtherQuery}
            sortByType="DESC"
            sortByActive="type"
            odsStatuses={odsStatuses}
            aircrafts={aircraft}
            customIsLoading={isLoading}
            aircraftTypeT={aircraftTypeT}
            columns={columnsOther}
            odsRecalculate={odsRecalculate}
            openMenu={handleOpen}
            isAddTopScroll={false}
          />

        ) : (
          <EditableTable
            getRowId={(row) => row?.id}
            getRowClassName={(params) => `row-${params?.row.type === 'M' ? 'm' : 'f'} row-${params.row.ods_status === 'CNLD' && 'cnld'}`}
            useHook={useGetOdsQuery}
            sortByType="DESC"
            sortByActive="index"
            tableParams={tableParams}
            columns={columns}
            customIsLoading={isLoading}
            odsRecalculate={odsRecalculate}
            openMenu={handleOpen}
          />
        )}

        <DropDown
          open={open}
          onClose={closeMenu}
          anchorEl={anchorEl}
          idRow={idRow}
          odsRecalculate={odsRecalculate}
        />

        <DropDownCreateForm
          open={openCreteForm}
          onClose={closeMenuCreteForm}
          anchorEl={anchorElCreteForm}
          aircraft={aircraft}
        />
      </Box>
    </Card>
  );
};

export default Ods;
