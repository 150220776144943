import React from 'react';
import EditableTable from '../../../../components/EditableTable';
import { useGetFinanceReportsAircraftTableQuery } from '../../../../store/slices/aircraftReportsSlice';
import { columns } from './columns';
import { Card } from '@mui/material';

const ReportsTable = ({ actions, options }) => (
  <Card sx={{ padding: '0.5rem' }}>
    <EditableTable
      useHook={useGetFinanceReportsAircraftTableQuery}
      sortByType="DESC"
      sortByActive="id"
      columns={columns}
      tableParams={{
        aircraft_id: options.aircraft.id
      }}
      handleOpenReport={actions.handleOpenReport}
    />
  </Card>
);

export default ReportsTable;
