import React from 'react';
import { Card, Grid } from '@mui/material';
import AircraftReportForm from '../../../MyProfile/AircraftReports/AircraftReportDialog/AircraftReportForm';
import { FormProvider } from 'react-hook-form';
import SubmitCard from './SubmitCard';

const ReportForm = ({ options, actions, form }) => (
  <Card sx={{ padding: '0.5rem' }}>
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(actions.onSubmit)}>
        <Grid container spacing={6}>
          <Grid item xs={8}>
            <AircraftReportForm options={options} user={options.user} actions={actions} />
          </Grid>

          <Grid item xs={4}>
            <SubmitCard options={options} actions={actions} />
          </Grid>
        </Grid>

      </form>
    </FormProvider>
  </Card>
);

export default ReportForm;
