import {
  Box,
  Card,
  CircularProgress, Dialog, DialogContent, Divider, Grid,
  Typography
} from '@mui/material';
import React, { useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { useGetInternalOrderAllInfoQuery } from '../../../../store/slices/internalLogisticsSlice';
import { useGetOrderAllInfoQuery } from '../../../../store/slices/logisticsSlice';
import { headerBtn } from '../../../Invoices/style';
import { DetailsGridStyle } from '../../style';
import MainInfo from './MainInfo/MainInfo';
import OrderDocs from './OrderDocs/OrderDocs';
import OrderHistory from './OrderHistory/OrderHistory';
import PartPath from './PartPath/PartPath';
import TimelinePart from './TimelinePart/TimelinePart';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import Button from '../../../../components/Button';
import EditIcon from '@mui/icons-material/Edit';
import EditModal from './EditModal/EditModal';

const OrderInfoModal = (props) => {
  const { open, onClose } = props;
  const params = useParams();
  const location = useLocation();
  const tabValue = location.pathname.split('/')[3];
  const { data: externalOrderInfo, isLoading: isExternalInfoLoading } = useGetOrderAllInfoQuery({
    id: params?.id
  }, {
    refetchOnMountOrArgChange: true,
    skip: (tabValue === 'delivery') || !params?.id
  });
  const { data: internalOrderInfo, isLoading: isInternalInfoLoading } = useGetInternalOrderAllInfoQuery({
    id: params?.id
  }, {
    refetchOnMountOrArgChange: true,
    skip: (tabValue === 'orders') || !params?.id
  });

  const orderInfo = (tabValue === 'orders') ? externalOrderInfo : internalOrderInfo;

  const [openEdit, setOpenEdit] = useState(false);
  const hasPermissionsWriteOrder = usePermissions(['internal_logistics_order_write', 'logistics_order_write']);
  const hasEditPermission = usePermissions(['edit_supplement_order_info_access', 'edit_order_info_access']);
  const getFormatOrderType = () => {
    if (
      orderInfo?.main?.part_type
      && orderInfo.main.part_type !== 'part'
      && orderInfo.main.part_type !== 'consumable'
    ) {
      const type = orderInfo.main.part_type
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      return `${type} order `;
    }

    return 'Order ';
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
    >
      {(isExternalInfoLoading || isInternalInfoLoading) ? (
        <DialogContent sx={{ alignSelf: 'center' }}>
          <CircularProgress />
        </DialogContent>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={8.5} sx={{ backgroundColor: '#e9f1fe26' }}>
            <Card
              sx={{ padding: '1rem' }}
            >
              <Box sx={{ ...(headerBtn), display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                  <Typography variant="h5" noWrap>
                    {getFormatOrderType()}
                    {orderInfo?.main?.order_number}
                  </Typography>
                </Box>

                {(hasPermissionsWriteOrder && hasEditPermission) && (
                  <Box>
                    <Button
                      title="Edit"
                      startIcon={<EditIcon />}
                      onClick={() => setOpenEdit(true)}
                    />
                  </Box>
                )}
              </Box>

              <Divider />
            </Card>

            {/* Main Info */}
            <MainInfo main={orderInfo?.main} orderInfo={orderInfo} />

            {/* Parts path */}
            <PartPath path={orderInfo?.path} />

            {/* Timeline part */}
            <TimelinePart timeline={orderInfo?.timeline} />

            {/* Docs */}
            {(orderInfo?.invoices?.length >= 1) && <OrderDocs invoices={orderInfo?.invoices} />}

          </Grid>

          <Grid item xs={3.5} sx={DetailsGridStyle}>

            {/* Order History */}
            <OrderHistory order={orderInfo || {}} />
          </Grid>
        </Grid>
      )}

      <EditModal open={openEdit} onClose={() => setOpenEdit(false)} tabValue={tabValue} />
    </Dialog>
  );
};

export default OrderInfoModal;
