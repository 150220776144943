import React from 'react';
import { SvgIcon } from '@mui/material';

const MasterCardIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 64 64">
    <g>
      <g>
        <g>
          <path
            d="M63.5,32c0,10.4-8.4,18.9-18.9,18.9c-10.4,0-18.9-8.5-18.9-18.9v0c0-10.4,8.4-18.9,18.8-18.9
            C55.1,13.1,63.5,21.6,63.5,32C63.5,32,63.5,32,63.5,32z"
            fill="#FFB600"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M44.6,13.1c10.4,0,18.9,8.5,18.9,18.9c0,0,0,0,0,0c0,10.4-8.4,18.9-18.9,18.9c-10.4,0-18.9-8.5-18.9-18.9"
            fill="#F7981D"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M44.6,13.1c10.4,0,18.9,8.5,18.9,18.9c0,0,0,0,0,0c0,10.4-8.4,18.9-18.9,18.9"
            fill="#FF8500"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M19.2,13.1C8.9,13.2,0.5,21.6,0.5,32c0,10.4,8.4,18.9,18.9,18.9c4.9,0,9.3-1.9,12.7-4.9l0,0h0
            c0.7-0.6,1.3-1.3,1.9-2h-3.9c-0.5-0.6-1-1.3-1.4-1.9h6.7c0.4-0.6,0.8-1.3,1.1-2h-8.9c-0.3-0.6-0.6-1.3-0.8-2h10.4
            c0.6-1.9,1-3.9,1-6c0-1.4-0.2-2.7-0.4-4H26.2c0.1-0.7,0.3-1.3,0.5-2h10.4c-0.2-0.7-0.5-1.4-0.8-2h-8.8c0.3-0.7,0.7-1.3,1.1-2h6.7
            c-0.4-0.7-0.9-1.4-1.5-2h-3.7c0.6-0.7,1.2-1.3,1.9-1.9c-3.3-3.1-7.8-4.9-12.7-4.9C19.3,13.1,19.3,13.1,19.2,13.1z"
            fill="#FF5050"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M0.5,32c0,10.4,8.4,18.9,18.9,18.9c4.9,0,9.3-1.9,12.7-4.9l0,0h0c0.7-0.6,1.3-1.3,1.9-2h-3.9
            c-0.5-0.6-1-1.3-1.4-1.9h6.7c0.4-0.6,0.8-1.3,1.1-2h-8.9c-0.3-0.6-0.6-1.3-0.8-2h10.4c0.6-1.9,1-3.9,1-6c0-1.4-0.2-2.7-0.4-4
            H26.2c0.1-0.7,0.3-1.3,0.5-2h10.4c-0.2-0.7-0.5-1.4-0.8-2h-8.8c0.3-0.7,0.7-1.3,1.1-2h6.7c-0.4-0.7-0.9-1.4-1.5-2h-3.7
            c0.6-0.7,1.2-1.3,1.9-1.9c-3.3-3.1-7.8-4.9-12.7-4.9c0,0-0.1,0-0.1,0"
            fill="#E52836"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M19.4,50.9c4.9,0,9.3-1.9,12.7-4.9l0,0h0c0.7-0.6,1.3-1.3,1.9-2h-3.9c-0.5-0.6-1-1.3-1.4-1.9h6.7
            c0.4-0.6,0.8-1.3,1.1-2h-8.9c-0.3-0.6-0.6-1.3-0.8-2h10.4c0.6-1.9,1-3.9,1-6c0-1.4-0.2-2.7-0.4-4H26.2c0.1-0.7,0.3-1.3,0.5-2
            h10.4c-0.2-0.7-0.5-1.4-0.8-2h-8.8c0.3-0.7,0.7-1.3,1.1-2h6.7c-0.4-0.7-0.9-1.4-1.5-2h-3.7
            c0.6-0.7,1.2-1.3,1.9-1.9c-3.3-3.1-7.8-4.9-12.7-4.9c0,0-0.1,0-0.1,0"
            fill="#CB2026"
          />
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              d="M26.1,36.8l0.3-1.7c-0.1,0-0.3,0.1-0.5,0.1c-0.7,0-0.8-0.4-0.7-0.6l0.6-3.5h1.1l0.3-1.9h-1l0.2-1.2h-2
              c0,0-1.2,6.6-1.2,7.4c0,1.2,0.7,1.7,1.6,1.7C25.4,37.1,25.9,36.9,26.1,36.8z"
              fill="#FFFFFF"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M26.8,33.6c0,2.8,1.9,3.5,3.5,3.5c1.5,0,2.1-0.3,2.1-0.3l0.4-1.9c0,0-1.1,0.5-2.1,0.5
              c-2.2,0-1.8-1.6-1.8-1.6h4.1c0,0,0.3-1.3,0.3-1.8c0-1.3-0.7-2.9-2.9-2.9C28.3,28.9,26.8,31.1,26.8,33.6z M30.3,30.7
              c1.1,0,0.9,1.3,0.9,1.4H29C29,32,29.2,30.7,30.3,30.7z"
              fill="#FFFFFF"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M43,36.8l0.4-2.2c0,0-1,0.5-1.7,0.5c-1.4,0-2-1.1-2-2.3c0-2.4,1.2-3.7,2.6-3.7c1,0,1.8,0.6,1.8,0.6
              l0.3-2.1c0,0-1.2-0.5-2.3-0.5c-2.3,0-3.6,2-3.6,5.8c0,2.5,0.2,4.2,2.6,4.2C41.9,37.1,43,36.8,43,36.8z"
              fill="#FFFFFF"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M15.1,28.9c-1.4,0-2.4,0.4-2.4,0.4l-0.3,1.7c0,0,0.9-0.4,2.2-0.4c0.7,0,1.3,0.1,1.3,0.7
              c0,0.4-0.1,0.5-0.1,0.5s-0.6,0-0.9,0c-1.7,0-3.6,0.7-3.6,3c0,1.8,1.2,2.2,1.9,2.2c1.4,0,2-0.9,2.1-0.9l-0.1,0.8h1.8l0.8-5.5
              C17.8,29,15.8,28.9,15.1,28.9z M15.5,33.4c0,0.3-0.2,1.9-1.4,1.9c-0.6,0-0.8-0.5-0.8-0.8c0-0.5,0.3-1.2,1.8-1.2
              C15.4,33.4,15.5,33.4,15.5,33.4z"
              fill="#FFFFFF"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M19.7,37c0.5,0,3,0.1,3-2.6c0-2.5-2.4-2-2.4-3c0-0.5,0.4-0.7,1.1-0.7c0.3,0,1.4,0.1,1.4,0.1l0.3-1.8
              c0,0-0.7-0.2-1.9-0.2c-1.5,0-3,0.6-3,2.6c0,2.3,2.5,2.1,2.5,3c0,0.6-0.7,0.7-1.2,0.7c-0.9,0-1.8-0.3-1.8-0.3l-0.3,1.8
              C17.5,36.8,18,37,19.7,37z"
              fill="#FFFFFF"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M59.6,27.3L59.2,30c0,0-0.8-1-1.9-1c-1.8,0-3.4,2.2-3.4,4.8c0,1.6,0.8,3.3,2.5,3.3
              c1.2,0,1.9-0.8,1.9-0.8l-0.1,0.7h2l1.5-9.6L59.6,27.3z M58.7,32.6c0,1.1-0.5,2.5-1.6,2.5c-0.7,0-1.1-0.6-1.1-1.6
              c0-1.6,0.7-2.6,1.6-2.6C58.3,30.9,58.7,31.4,58.7,32.6z"
              fill="#FFFFFF"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M4.2,36.9l1.2-7.2l0.2,7.2H7l2.6-7.2l-1.1,7.2h2.1l1.6-9.6H8.9l-2,5.9l-0.1-5.9H3.9l-1.6,9.6H4.2z"
              fill="#FFFFFF"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M33.1,36.9h2.1c0.6-3.3,0.7-6,2.1-5.5c0.2-1.3,0.5-1.8,0.7-2.3c0,0-0.1,0-0.4,0c-0.9,0-1.6,1.2-1.6,1.2l0.2-1.1"
              fill="#DCE5E5"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M44.9,31.1c0,0,0.9-0.4,2.2-0.4c0.7,0,1.3,0.1,1.3,0.7c0,0.4-0.1,0.5-0.1,0.5s-0.6,0-0.9,0
              c-1.7,0-3.6,0.7-3.6,3c0,1.8,1.2,2.2,1.9,2.2c1.4,0,2-0.9,2.1-0.9l-0.1,0.8h1.8l0.8-5.5c0-2.3-2-2.4-2.8-2.4
              M49,33.4c0,0.3-1.2,1.9-2.4,1.9c-0.6,0-0.8-0.5-0.8-0.8c0-0.5,0.3-1.2,1.8-1.2C48,33.4,49,33.4,49,33.4z"
              fill="#DCE5E5"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M49.9,36.9H52c0.6-3.3,0.7-6,2.1-5.5c0.2-1.3,0.5-1.8,0.7-2.3c0,0-0.1,0-0.4,0c-0.9,0-1.6,1.2-1.6,1.2l0.2-1.1"
              fill="#DCE5E5"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default MasterCardIcon;
