import React, { useState } from 'react';
import {
  Grid, Card, Typography, Box, Divider
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { fileName, label } from '../../../../../DocumentsAndInvoices/Documents/DocumentsForm/style';
import Upload from '../../../../../../components/Upload';
import FormGroup from '../../../../../../form/components/FormGroup';
import { fileToBase64 } from '../../../../../../utils/utils';
import { TextWithIcon } from '../../../../../Logistics/style';
import CloseIcon from '@mui/icons-material/Close';

const DocumentsForm = ({ fileNames, setFileNames }) => {
  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue
  } = useFormContext();

  const handleUploadFile = async (e, index) => {
    const file = e.target.files[0];

    if (file) {
      const fileAsBase64 = await fileToBase64(file);

      setFileNames((prevFileNames) => [
        ...prevFileNames.filter((item) => item.id !== index),
        { id: index, name: file.name },
      ]);

      setValue(index, {
        content: fileAsBase64.base64,
        name: fileAsBase64.name,
        extension: fileAsBase64.extension
      });
    }
  };

  const handleResetFile = (index) => {
    setFileNames((prevFileNames) => prevFileNames.filter((item) => item.id !== index));
    setValue(index, null);
  };

  return (
    <Card sx={{ p: '1rem' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
          >
            <FormGroup sx={label} label="Evaluation File" hasError={!!errors.evaluation_file}>
              <Controller
                name="evaluation_file"
                control={control}
                render={({ field }) => (
                  <Upload
                    {...field}
                    handleUpload={(e) => {
                      field.onChange(e);
                      handleUploadFile(e, 'evaluation_file');
                    }}
                    title="Upload Evaluation File"
                  />
                )}
              />
            </FormGroup>
            <Box sx={{ ...(TextWithIcon), alignItems: 'flex-end' }}>
              <Typography sx={fileName}>
                {
                  fileNames?.find((item) => item.id === 'evaluation_file')?.name
                  || 'No file uploaded'
                }
              </Typography>

              {fileNames?.find((item) => item.id === 'evaluation_file')?.name && (
                <CloseIcon
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                      color: 'mediumvioletred !important',
                    }
                  }}
                  onClick={() => handleResetFile('evaluation_file')}
                />
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
          >
            <FormGroup sx={label} label="EO File" hasError={!!errors.eo_file}>
              <Controller
                name="eo_file"
                control={control}
                render={({ field }) => (
                  <Upload
                    {...field}
                    handleUpload={(e) => {
                      field.onChange(e);
                      handleUploadFile(e, 'eo_file');
                    }}
                    title="Upload EO File"
                  />
                )}
              />
            </FormGroup>
            <Box sx={{ ...(TextWithIcon), alignItems: 'flex-end' }}>
              <Typography sx={fileName}>
                {
                  fileNames?.find((item) => item.id === 'eo_file')?.name
                  || 'No file uploaded'
                }
              </Typography>

              {fileNames?.find((item) => item.id === 'eo_file')?.name && (
                <CloseIcon
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                      color: 'mediumvioletred !important',
                    }
                  }}
                  onClick={() => handleResetFile('eo_file')}
                />
              )}
            </Box>
          </Box>

        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
          >
            <FormGroup sx={label} label="Addition File" hasError={!!errors.addition_file}>
              <Controller
                name="addition_file"
                control={control}
                render={({ field }) => (
                  <Upload
                    {...field}
                    handleUpload={(e) => {
                      field.onChange(e);
                      handleUploadFile(e, 'addition_file');
                    }}
                    title="Upload Addition File"
                  />
                )}
              />
            </FormGroup>
            <Box sx={{ ...(TextWithIcon), alignItems: 'flex-end' }}>
              <Typography sx={fileName}>
                {
                  fileNames?.find((item) => item.id === 'addition_file')?.name
                  || 'No file uploaded'
                }
              </Typography>

              {fileNames?.find((item) => item.id === 'addition_file')?.name && (
                <CloseIcon
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                      color: 'mediumvioletred !important',
                    }
                  }}
                  onClick={() => handleResetFile('addition_file')}
                />
              )}
            </Box>
          </Box>

        </Grid>
      </Grid>
    </Card>
  );
};

export default DocumentsForm;
