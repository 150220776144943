import Typography from '@mui/material/Typography';
import React from 'react';
import CollapsibleHeaderGroup from '../../../components/EditableTable/CollapsibleHeaderGroup';
import moment from 'moment';
import { Box } from '@mui/material';
import { HeaderName } from '../../../components/EditableTable/styles';
import Input from '../../../components/Input/Input';
import FiltrationDate from '../../../components/EditableTable/FiltrationDate';
import { AR_DIR_STATUSES } from './components/ADForm/constants';
import FiltrationSelect from '../../../components/EditableTable/FiltrationSelect';
import { TextWrap } from '../../Logistics/style';

export const columnGroupingModelAR = [
  {
    groupId: 'ad_group',
    headerName: 'Airworthiness Directives',
    children: [
      { field: 'number' },
      { field: 'issuer' },
      { field: 'revision' },
      { field: 'paragraph' },
      { field: 'additional_number' },
      { field: 'reference' },
      { field: 'ata_id' },
      { field: 'description' },
      { field: 'effective_date' },
    ],
    renderHeaderGroup: (params) => (
      <CollapsibleHeaderGroup
        groupId={params.groupId}
        headerName={params.headerName}
        fields={[
          'issuer',
          'revision',
          'paragraph',
          'additional_number',
          'reference',
          'ata_id',
          'description',
          'effective_date'
        ]}
      />
    ),
  },
  {
    groupId: 'affected_part_group_ar',
    headerName: 'AP',
    children: [
      { field: 'affected_part_number' },
      { field: 'affected_serial_number' },
      { field: 'affected_part_note' },
    ],
    renderHeaderGroup: (params) => (
      <CollapsibleHeaderGroup
        groupId={params.groupId}
        headerName={params.headerName}
        fields={['affected_serial_number', 'affected_part_note']}
        collapseByDefault
      />
    ),
  }
];

export const columns = ({
  handleFilter,
  filters,
}) => ([
  {
    minWidth: 100,
    field: 'id',
    headerName: '#',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography variant="subtitle1">{`#${row?.dir_id}`}</Typography>
    ),
  },
  {
    minWidth: 250,
    field: 'number',
    headerName: 'Doc. Number',
    sortable: true,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Doc. Number</Typography>
        <Input
          size="small"
          placeholder="..."
          defaultValue={filters.number || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'number');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography sx={{ ...(TextWrap), fontWeight: '600' }} variant="h6">{`${row?.number}`}</Typography>
    ),
  },
  {
    minWidth: 200,
    field: 'issuer',
    headerName: 'Issuer',
    sortable: true,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Issuer</Typography>
        <Input
          size="small"
          placeholder="..."
          defaultValue={filters.issuer || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'issuer');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1">{`${row?.issuer || '-'}`}</Typography>
    ),
  },
  {
    minWidth: 100,
    field: 'revision',
    headerName: 'R',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography variant="subtitle1">{`${row?.revision ?? '-'}`}</Typography>
    ),
  },
  {
    minWidth: 100,
    field: 'paragraph',
    headerName: 'P',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography variant="subtitle1">{`${row?.paragraph ?? '-'}`}</Typography>
    ),
  },
  {
    minWidth: 200,
    field: 'additional_number',
    headerName: 'Additional AD',
    sortable: true,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Additional AD</Typography>
        <Input
          size="small"
          placeholder="..."
          defaultValue={filters.additional_number || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'additional_number');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography sx={{ ...(TextWrap), fontWeight: '600' }} variant="h6">{`${row?.additional_number || '-'}`}</Typography>
    ),
  },
  {
    minWidth: 200,
    field: 'reference',
    headerName: 'REF DOC / SBs',
    sortable: true,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>REF DOC</Typography>
        <Input
          size="small"
          placeholder="..."
          defaultValue={filters.reference || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'reference');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1">{`${row?.reference ?? '-'}`}</Typography>
    ),
  },
  {
    minWidth: 200,
    field: 'ata_id',
    headerName: 'ATA',
    sortable: true,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>ATA</Typography>
        <Input
          size="small"
          placeholder="..."
          defaultValue={filters.ata_id || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'ata_id');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1">{`${row?.ata_id || '-'}`}</Typography>
    ),
  },
  {
    minWidth: 200,
    field: 'effective_date',
    headerName: 'Effective Date',
    sortable: true,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Effective Date</Typography>
        <FiltrationDate
          value={filters.effective_date || null}
          onChange={(e) => {
            handleFilter(e, 'effective_date', true);
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1">{`${row?.effective_date ? moment(row?.effective_date).format('YYYY-MM-DD') : '-'}`}</Typography>
    ),
  },
  {
    minWidth: 400,
    field: 'description',
    headerName: 'Description',
    sortable: true,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Description</Typography>
        <Input
          size="small"
          placeholder="..."
          defaultValue={filters.description || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'description');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography sx={TextWrap} variant="subtitle1">{`${row?.description}`}</Typography>
    ),
  },
  {
    minWidth: 450,
    field: 'remarks',
    headerName: 'Remarks',
    sortable: true,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Remarks</Typography>
        <Input
          size="small"
          placeholder="..."
          defaultValue={filters.remark || ''}
          onChange={(e) => {
            e.stopPropagation();
            handleFilter(e.target.value, 'remark');
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography sx={TextWrap} variant="subtitle1">{`${row?.remarks ?? '-'}`}</Typography>
    ),
  },
  {
    minWidth: 200,
    field: 'interval_days',
    headerName: 'interval',
    sortable: true,
    renderHeader: () => (
      <Typography style={HeaderName}>Interval</Typography>
    ),
    renderCell: ({ row }) => (
      <Typography sx={TextWrap} variant="subtitle1">{row?.interval_days}</Typography>
    ),
  },
  {
    minWidth: 200,
    field: 'last_done_date',
    headerName: 'Last Done',
    sortable: true,
    renderHeader: () => (
      <Typography style={HeaderName}>Last Done</Typography>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1">{`${row?.last_done_date ? moment(row?.last_done_date).format('YYYY-MM-DD') : '-'}`}</Typography>
    ),
  },
  {
    minWidth: 200,
    field: 'due_date',
    headerName: 'Next Due',
    sortable: true,
    renderHeader: () => (
      <Typography style={HeaderName}>Next Due</Typography>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1">{`${row?.due_date ? moment(row?.due_date).format('YYYY-MM-DD') : '-'}`}</Typography>
    ),
  },
  {
    minWidth: 200,
    field: 'remaining_days',
    headerName: 'Remaining',
    sortable: true,
    renderHeader: () => (
      <Typography style={HeaderName}>Remaining</Typography>
    ),
    renderCell: ({ row }) => (
      <Typography variant="subtitle1">{`${row?.remaining_days ?? '-'}`}</Typography>
    ),
  },
  {
    minWidth: 200,
    field: 'status',
    headerName: 'Status',
    sortable: true,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Status</Typography>
        <FiltrationSelect
          options={AR_DIR_STATUSES.concat([{ value: '', label: '-' }])}
          defaultValue={filters.status || ''}
          handleFilter={handleFilter}
          value="status"
        />
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize', fontWeight: '600' }}
      >
        {AR_DIR_STATUSES.find((item) => item.value === row?.status)?.label}
      </Typography>
    ),
  },
  {
    minWidth: 200,
    field: 'affected_part_number',
    headerName: 'Part Number',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography variant="subtitle1">{`${row?.affected_part_number ?? '-'}`}</Typography>
    ),
  },
  {
    minWidth: 200,
    field: 'affected_serial_number',
    headerName: 'Serial Number',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography variant="subtitle1">{`${row?.affected_serial_number ?? '-'}`}</Typography>
    ),
  },
  {
    minWidth: 200,
    field: 'affected_part_note',
    headerName: 'Part Note',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography variant="subtitle1">{`${row?.affected_part_note ?? '-'}`}</Typography>
    ),
  }
]);
