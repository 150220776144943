import React from 'react';
import {
  Card,
  CardContent,
  Tab
} from '@mui/material';
import { BoxFlexBetween } from '../../../assets/styles';
import { TabContext, TabList } from '@mui/lab';
import { columns, useAircraftReport } from './useAircraftReport';
import { GreenButton } from '../../Logistics/style';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import AircraftReportDialog from './AircraftReportDialog/AircraftReportDialog';
import { useGetAircraftReportsTableQuery } from '../../../store/slices/aircraftReportsSlice';

const AircraftReport = (props) => {
  const { user } = props;
  const {
    handleTabChange,
    tabState,
    openDialog,
    handleOpenDialog,
    handleCloseDialog,
    currentReportID
  } = useAircraftReport();

  return (
    <Card>
      <CardContent>
        <TabContext value={tabState}>
          <BoxFlexBetween>
            <TabList
              aria-label="tabs"
              onChange={handleTabChange}
            >
              <Tab value="active" label="Active" />
              <Tab value="draft" label="Draft" />
            </TabList>

            <Button
              title="Create new aircraft report"
              sx={{ ...GreenButton, height: '38px' }}
              startIcon={<FlagCircleIcon />}
              onClick={handleOpenDialog}
            />
          </BoxFlexBetween>

          <EditableTable
            useHook={useGetAircraftReportsTableQuery}
            sortByType="DESC"
            sortByActive="created_at"
            columns={columns}
            tableParams={{
              state: tabState,
            }}
            handleOpenReport={handleOpenDialog}
          />
        </TabContext>
      </CardContent>

      <AircraftReportDialog
        open={openDialog}
        onClose={handleCloseDialog}
        reportID={currentReportID}
        user={user}
      />
    </Card>
  );
};

export default AircraftReport;
