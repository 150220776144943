import {
  useSendCompensationMutation,
  useGetCompensationQuery,
  useUpdateCompensationMutation,
  useSearchReportsQuery,
} from '../../../../store/slices/personalReportsSlice';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { defaultValues, schema } from './schema';
import { toast } from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { fileToBase64 } from '../../../../utils/utils';

export const useCompensation = (onClose, id, crewId) => {
  const update = !!id;
  const [fileNames, setFileNames] = useState([]);
  const [storeCompensation] = useSendCompensationMutation();
  const [updateCompensation] = useUpdateCompensationMutation();
  const { data: compensationData, isLoading: compensationLoading } = useGetCompensationQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id
  });
  const { data: reports } = useSearchReportsQuery({ search: '', crew_id: crewId }, {
    refetchOnMountOrArgChange: true
  });

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    fields: rowFields,
    append: rowAppend,
    remove: rowRemove
  } = useFieldArray({
    name: 'rows',
    control: form.control,
  });

  useEffect(() => {
    if (compensationData && !compensationLoading && update) {
      const formattedRows = [
        {
          ...compensationData,
          file: compensationData?.file
            ? {
              content: compensationData?.file.content || '',
              name: compensationData?.file.name || '',
              extension: compensationData?.file.extension || '',
            }
            : null,
        }
      ];

      form.reset({
        rows: formattedRows,
      });

      setFileNames(
        formattedRows.map((row, index) => ({
          id: index,
          name: row?.file?.name || '',
        }))
      );
    }
  }, [compensationData, compensationLoading, form, update]);

  const handleCloseOnSubmit = (clear = true) => {
    if (clear) {
      setFileNames([]);
      form.reset(defaultValues);
    }
    onClose();
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    if (update) {
      const res = await updateCompensation({ id, data: data.rows[0] });
      if (res?.error) {
        toast.error(res?.error?.data?.message, {
          id: toastId,
          duration: 15000
        });

        return;
      }
    } else {
      const res = await storeCompensation({ ...data, crew_id: crewId });
      if (res?.error) {
        toast.error(res?.error?.data?.message, {
          id: toastId,
          duration: 15000
        });

        return;
      }
    }

    handleCloseOnSubmit();
    toast.success('Success!', {
      id: toastId,
    });
  };

  const handleUploadFile = async (e, index) => {
    const file = e.target.files[0];

    if (file) {
      const fileAsBase64 = await fileToBase64(file);

      setFileNames((prevFileNames) => [
        ...prevFileNames.filter((item) => item.id !== index),
        { id: index, name: file.name },
      ]);

      form.setValue(`rows.${index}.file`, {
        content: fileAsBase64.base64,
        name: fileAsBase64.name,
        extension: fileAsBase64.extension
      });
    }
  };

  const handleResetFile = (index) => {
    setFileNames((prevFileNames) => prevFileNames.filter((item) => item.id !== index));
    form.setValue(`rows.${index}.file`, null);
  };

  return {
    form,
    rowFields,
    rowAppend,
    rowRemove,
    onSubmit,
    update,
    handleUploadFile,
    handleResetFile,
    fileNames,
    handleCloseOnSubmit,
    reports
  };
};
