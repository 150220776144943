export const STATUS_SENT = 'sent';
export const STATUS_DECLINED = 'declined';
export const STATUS_DRAFT = 'draft';
export const STATUS_SUBMITTED = 'submitted';
export const STATUS_CLOSED = 'closed';

export const REPORT_STATUSES = [
  { value: STATUS_SENT, label: 'Sent' },
  { value: STATUS_DECLINED, label: 'Declined' },
  { value: STATUS_DRAFT, label: 'Draft' },
  { value: STATUS_SUBMITTED, label: 'Submitted' },
  { value: STATUS_CLOSED, label: 'Closed' }
];

export const TRX_TYPE_CARD_PAYMENT = 'card_payment';
export const TRX_TYPE_CASH = 'cash_payment';
export const TRX_TYPE_WITHDRAWAL = 'cash_withdrawal';

export const TRANSACTION_TYPES = [
  { value: TRX_TYPE_CARD_PAYMENT, label: 'Card Payment' },
  { value: TRX_TYPE_CASH, label: 'Cash Payment' },
  { value: TRX_TYPE_WITHDRAWAL, label: 'Cash Withdrawal' }
];
