import React, { useState } from 'react';
import { useAdFrom } from './useADFrom';
import {
  Dialog,
  DialogContent,
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Divider,
  TextField,
  Switch
} from '@mui/material';
import {
  CloseIconStyle,
  DetailsGridStyle,
  OrderDetailsStyle,
  RightMenuStyle
} from '../../../../Logistics/OrdersDashboard/styles';
import CloseIcon from '@mui/icons-material/Close';
import { headerBtn } from '../../../../Invoices/style';
import FormGroup from '../../../../../form/components/FormGroup';
import { Controller, FormProvider } from 'react-hook-form';
import Select from '../../../../../form/components/Select';
import DatePicker from '../../../../../form/components/DatePicker';
import { setDateValue, validateDatePickerValue } from '../../../../../utils/setDateValue';
import { asterisk } from '../../../../DocumentsAndInvoices/Invoices/style';
import { WhiteInput } from '../../../../Finance/InvoiceManagement/styles';
import { EVALUATION_DONE_OPTIONS, GENERAL_STATUSES_OPTIONS } from './constants';
import Autocomplete from '../../../../../form/components/Autocomplete';
import FieldError from '../../../../../form/components/FieldError';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import BoxIcon from 'mdi-material-ui/PackageVariantClosed';
import Tab from '@mui/material/Tab';
import Button from '../../../../../components/Button';
import { GreenButton, RedButton } from '../../../../Logistics/style';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteModal from '../../../../../components/DeleteModal';

const ADForm = ({
  open,
  onClose,
  aircraft,
  currentAD
}) => {
  const {
    update,
    form,
    onSubmit,
    hasWritePermissions,
    odsAtaOptions,
    aircraftOptions,
    tab,
    handleChangeApplicability,
    handleCloseForm,
    isLoading,
    onDelete,
    deleteModal,
    setDeleteModal
  } = useAdFrom(currentAD, aircraft, onClose);

  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue
  } = form;

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={handleCloseForm}
      sx={{
        '& .MuiDialog-paper': {
          border: watch('urgent') ? '1px solid red' : '1px solid whitesmoke',
        },
      }}
    >
      {isLoading ? (
        <DialogContent sx={{ height: '100rem', alignSelf: 'anchor-center', alignContent: 'center' }}>
          <CircularProgress />
        </DialogContent>
      ) : (
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} ref={form}>
            <Grid container spacing={2}>
              <Grid item xs={9.5} sx={{ backgroundColor: '#e9f1fe26' }}>
                <DialogContent>
                  <Card
                    sx={{ padding: '1rem' }}
                  >
                    <Box sx={{ headerBtn, display: 'flex', justifyContent: 'space-between' }}>
                      <Box>
                        <Typography variant="h5" noWrap>
                          {update ? watch('number') : 'Create Airworthiness Directive'}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          noWrap
                          sx={{
                            textTransform: 'capitalize', display: 'flex', mb: 2, fontWeight: 600
                          }}
                        >
                          Fields with
                          {' '}
                          <Typography sx={asterisk}>*</Typography>
                          {' '}
                          are required.
                        </Typography>
                      </Box>

                      <Box>
                        <Button
                          title={update ? 'Update' : 'Create'}
                          sx={GreenButton}
                          type="submit"
                          disabled={!hasWritePermissions}
                        />

                        {update && (
                          <Button
                            title="Delete"
                            sx={{ ...(RedButton), mx: 2 }}
                            disabled={!hasWritePermissions}
                            startIcon={<DeleteIcon />}
                            onClick={() => setDeleteModal(true)}
                          />
                        )}

                      </Box>
                    </Box>

                    <Divider />

                    <Grid container spacing={2}>
                      <Grid item xl={3} lg={4} md={4} xs={12} sm={12}>
                        <FormGroup label="Doc. Number" required hasError={!!errors.number}>
                          <Controller
                            name="number"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                fullWidth
                                error={!!errors.number}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xl={3} lg={4} md={4} xs={12} sm={12}>
                        <FormGroup label="Additional AD" hasError={!!errors.additional_number}>
                          <Controller
                            name="additional_number"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                fullWidth
                                error={!!errors.additional_number}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xl={3} lg={4} md={4} xs={12} sm={12}>
                        <FormGroup label="Issuer" required hasError={!!errors.issuer}>
                          <Controller
                            name="issuer"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                fullWidth
                                error={!!errors.issuer}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xl={3} lg={4} md={4} xs={12} sm={12}>
                        <FormGroup label="Revision" hasError={!!errors.revision}>
                          <Controller
                            name="revision"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                fullWidth
                                error={!!errors.revision}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xl={3} lg={4} md={4} xs={12} sm={12}>
                        <FormGroup label="Paragraph" hasError={!!errors.paragraph}>
                          <Controller
                            name="paragraph"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                fullWidth
                                error={!!errors.paragraph}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xl={3} lg={4} md={4} xs={12} sm={12}>
                        <FormGroup label="REF DOC / SBs" hasError={!!errors.reference}>
                          <Controller
                            name="reference"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                fullWidth
                                error={!!errors.reference}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={12}>
                        <FormGroup label="ATA" required hasError={!!errors.ata_id}>
                          <Controller
                            name="ata_id"
                            control={control}
                            render={({ field }) => (
                              <Select
                                options={odsAtaOptions}
                                {...field}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={12}>
                        <FormGroup label="Description" required hasError={!!errors.description}>
                          <Controller
                            name="description"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                multiline
                                rows={3}
                                fullWidth
                                error={!!errors.description}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
                        <FormGroup label="Method of compliance" hasError={!!errors.method_of_compliance}>
                          <Controller
                            name="method_of_compliance"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                multiline
                                rows={2}
                                fullWidth
                                error={!!errors.method_of_compliance}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
                        <FormGroup label="Compliance information" hasError={!!errors.compliance_information}>
                          <Controller
                            name="compliance_information"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                multiline
                                rows={2}
                                fullWidth
                                error={!!errors.compliance_information}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={12}>
                        <Card sx={{ backgroundColor: '#e9f0fe', p: '1rem' }}>
                          <Grid container spacing={2}>
                            <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
                              <FormGroup label="Affected Part Number" hasError={!!errors.affected_part_number}>
                                <Controller
                                  name="affected_part_number"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      sx={WhiteInput}
                                      {...field}
                                      size="small"
                                      fullWidth
                                      error={!!errors.affected_part_number}
                                    />
                                  )}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
                              <FormGroup label="Affected Serial Number" hasError={!!errors.affected_part_serial_number}>
                                <Controller
                                  name="affected_part_serial_number"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      sx={WhiteInput}
                                      {...field}
                                      size="small"
                                      fullWidth
                                      error={!!errors.affected_part_serial_number}
                                    />
                                  )}
                                />
                              </FormGroup>
                            </Grid>

                            <Grid item xs={12}>
                              <FormGroup label="Affected Part Note" hasError={!!errors.affected_part_note}>
                                <Controller
                                  name="affected_part_note"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      sx={WhiteInput}
                                      {...field}
                                      size="small"
                                      multiline
                                      rows={3}
                                      fullWidth
                                      error={!!errors.affected_part_note}
                                    />
                                  )}
                                />
                              </FormGroup>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>

                    </Grid>

                    <Divider />

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormGroup label="Applicability" hasError={!!errors.aircraft}>
                          <Controller
                            name="aircraft"
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                options={aircraftOptions || []}
                                multiple
                                size="small"
                                placeholder="Please select an Aircraft"
                                onChange={(e, value) => {
                                  handleChangeApplicability(e, value);
                                  field.onChange(value);
                                }}
                              />
                            )}
                          />
                          <FieldError error={errors.invoices} />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={12}>
                        <TabContext value={tab.currentTab}>
                          <Card sx={{ backgroundColor: '#e9f0fe' }}>
                            <Grid container spacing={0}>
                              <Grid item xs={12}>
                                <TabList
                                  variant="scrollable"
                                  scrollButtons="auto"
                                  onChange={tab.handleChangeTab}
                                >
                                  <Tab
                                    key="documents"
                                    value="documents"
                                    label={(
                                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <BoxIcon />
                                        Documents
                                      </Box>
                                  )}
                                  />

                                  {watch('aircraft')?.map((item) => (
                                    <Tab
                                      key={item.value}
                                      value={item.value}
                                      label={(
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                          {item.label}
                                        </Box>
                                    )}
                                    />
                                  ))}
                                </TabList>
                              </Grid>

                              <Grid item xs={12}>
                                <TabPanel value={tab.currentTab ?? 'documents'}>
                                  {tab.handleTabContent()}
                                </TabPanel>
                              </Grid>
                            </Grid>
                          </Card>
                        </TabContext>
                      </Grid>
                    </Grid>

                  </Card>
                </DialogContent>
              </Grid>

              <Grid item xs={2.5} sx={DetailsGridStyle}>
                <Grid container>
                  <Grid item xs={12} sx={RightMenuStyle}>
                    <CloseIcon
                      sx={CloseIconStyle}
                      onClick={onClose}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Card sx={OrderDetailsStyle}>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormGroup label="Status" required hasError={!!errors.general_status}>
                              <Controller
                                name="general_status"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    options={GENERAL_STATUSES_OPTIONS}
                                    placeholder="Status"
                                    {...field}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12}>
                            <FormGroup label="Effective Date" required hasError={!!errors.effective_date}>
                              <Controller
                                name="effective_date"
                                control={control}
                                render={({ field }) => (
                                  <DatePicker
                                    placeholderText="YYYY-MM-DD"
                                    dateFormat="yyyy-MM-dd"
                                    showMonthDropdown
                                    showYearDropdown
                                    {...field}
                                    onChange={(e) => {
                                      field.onChange(e);
                                      setDateValue(e, setValue, 'effective_date', 'YYYY-MM-DD');
                                    }}
                                    value={validateDatePickerValue(field.value)}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12}>
                            <FormGroup label="Issue Date" required hasError={!!errors.issue_date}>
                              <Controller
                                name="issue_date"
                                control={control}
                                render={({ field }) => (
                                  <DatePicker
                                    placeholderText="YYYY-MM-DD"
                                    dateFormat="yyyy-MM-dd"
                                    showMonthDropdown
                                    showYearDropdown
                                    {...field}
                                    onChange={(e) => {
                                      field.onChange(e);
                                      setDateValue(e, setValue, 'issue_date', 'YYYY-MM-DD');
                                    }}
                                    value={validateDatePickerValue(field.value)}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12}>
                            <FormGroup label="Attention" hasError={!!errors.urgent}>
                              <Controller
                                name="urgent"
                                control={control}
                                render={({ field }) => (
                                  <Switch
                                    color="error"
                                    checked={field.value}
                                    onChange={() => {
                                      setValue('urgent', !watch('urgent'));
                                    }}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12}>
                            <FormGroup label="Evaluation done" hasError={!!errors.evaluation_done}>
                              <Controller
                                name="evaluation_done"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    options={EVALUATION_DONE_OPTIONS}
                                    {...field}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12}>
                            <FormGroup label="EO done" hasError={!!errors.eo_done}>
                              <Controller
                                name="eo_done"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    options={EVALUATION_DONE_OPTIONS}
                                    {...field}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Grid>

                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card sx={OrderDetailsStyle}>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormGroup label="Notice" hasError={!!errors.notice}>
                              <Controller
                                name="notice"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    size="small"
                                    multiline
                                    rows={6}
                                    fullWidth
                                    error={!!errors.notice}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </form>
          <DeleteModal open={deleteModal} onClose={() => setDeleteModal(false)} onDelete={onDelete} />
        </FormProvider>
      )}
    </Dialog>
  );
};

export default ADForm;
