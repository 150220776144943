import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Card,
  Divider,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import FormGroup from '../../../../../../form/components/FormGroup';
import { StatusCardStyle } from '../styles';
import DatePicker from '../../../../../../form/components/DatePicker';
import { setDateValue, validateDatePickerValue } from '../../../../../../utils/setDateValue';
import { WhiteInput } from '../../../../../Finance/InvoiceManagement/styles';

const EditDeliveredStatus = ({canEditInput}) => {
  const {
    getValues,
    control,
    setValue
  } = useFormContext();

  return (
    <Grid item xs={12}>
      <Card sx={StatusCardStyle}>
        <Box>
          <Typography variant="h6">
            Edit
            {' '}
            <b>Delivered</b>
            {' '}
            Status:
          </Typography>
        </Box>

        <Typography variant="subtitle2">
          {`${getValues('delivered.delivered')} - ${getValues('delivered.delivered_at')}`}
        </Typography>

        <Divider />

        <Grid container spacing={4}>
          <Grid item xs={4}>
            <FormGroup label="Expected Day Delivery">
              <Controller
                name="delivered.expected_day_delivery"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="YYYY-MM-DD"
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    sx={{ ...(WhiteInput), width: '100%' }}
                    disabled={!canEditInput('delivered.expected_day_delivery')}
                    onChange={(e) => {
                      field.onChange(e);
                      setDateValue(e, setValue, 'delivered.expected_day_delivery', 'YYYY-MM-DD');
                    }}
                    value={validateDatePickerValue(field.value)}
                  />
                )}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default EditDeliveredStatus;
