import { yupResolver } from '@hookform/resolvers/yup';
import {
  Card,
  CardContent,
  Grid,
  Divider,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/Button';
import Checkbox from '../../form/components/Checkbox';
import FieldError from '../../form/components/FieldError';
import FormGroup from '../../form/components/FormGroup';
import Input from '../../form/components/Input';
import {
  useCreateBankMutation,
  useGetBankInfoMutation,
  useGetCurrencyListQuery,
  useUpdateBankMutation
} from '../../store/session';
import { useHandleQueryFormError } from '../../utils/hooks/useHandleQueryFormError';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { GreenButton, RedButton } from '../Logistics/style';
import CardsTable from './CardsTable';
import { defaultValues, schema } from './schema';
import Typography from '@mui/material/Typography';
import { loaderIcon } from '../CrewForm/Documentation/Card/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { getOptions } from '../../utils/getOptions';
import { WhiteInput } from '../Finance/InvoiceManagement/styles';

const CreateBank = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleError = useHandleQueryFormError();
  const [currentBank, setCurrentBank] = useState(null);

  const hasPermissionsBank = usePermissions('bank_edit');

  const [createBank] = useCreateBankMutation();
  const [updateBank] = useUpdateBankMutation();
  const { data: currencyList, isLoading: isCurrencyLoading } = useGetCurrencyListQuery();
  const [getBankInfo, { isLoading: getBankInfoLoading }] = useGetBankInfoMutation();

  const isLoading = getBankInfoLoading || isCurrencyLoading;

  const currencyOptions = getOptions(currencyList);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const {
    handleSubmit,
    control,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    if (!hasPermissionsBank) {
      toast.error('You don’t have access!');

      return;
    }

    const toastId = toast.loading('Loading...');

    if (!id) {
      const res = await createBank(data);

      if (res.error) {
        handleError(res.error, setError, getValues, toastId);

        return;
      }

      navigate(`/finance/banks/update/${res.data.id}`);

      toast.success('Bank was created!', {
        id: toastId,
      });
    } else {
      const res = await updateBank({ data, id });

      if (res.error) {
        if (res.error?.data?.message) {
          const { message } = res.error.data;
          if (message.includes('The account_numbers')) {
            toast.error('Account number fields are mandatory, please enter the required data!', {
              id: toastId,
            });
          } else {
            toast.error(message, {
              id: toastId,
            });
          }
        }

        return;
      }

      toast.success('Bank was updated!', {
        id: toastId,
      });
    }
  };

  const handleUpdateValues = (result) => {
    setCurrentBank(result);
    Object.entries(result).forEach(
      ([name, value]) => {
        setValue(name, value);
      }
    );
  };

  const handleGetBankInfo = async () => {
    const { data: result } = await getBankInfo(id);

    if (result) {
      handleUpdateValues(result);
    }
  };

  useEffect(() => {
    if (id) {
      handleGetBankInfo();
    }
  }, [id]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          item
          sx={{
            display: 'flex',
            gap: '9px',
          }}
          xs={12}
        >
          <Button type="submit" variant="contained" title={`${id ? 'Save' : 'Create'}`} size="large" sx={GreenButton} />

          <Button
            title="Back"
            size="large"
            sx={RedButton}
            onClick={() => {
              navigate('/finance/banks');
            }}
          />
        </Grid>

        <Card sx={{ mt: 3 }}>
          {isLoading ? (
            <Box
              sx={loaderIcon}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <CardContent>
                <Typography variant="h4">{`${id ? currentBank?.bank_name : 'Create new bank'}`}</Typography>
                <Divider />
                <Card sx={{ backgroundColor: '#e9f0fe', p: '1rem' }}>
                  <Grid xs={12} container spacing={4} rowSpacing={4} sx={{ flexGrow: 1, mt: 2 }}>
                    <Grid item xs={4}>
                      <FormGroup label="Edit Bank Name" required hasError={!!errors.bank_name}>
                        <Controller
                          name="bank_name"
                          control={control}
                          render={({ field }) => (
                            <Input
                              sx={WhiteInput}
                              placeholder="Name"
                              {...field}
                            />
                          )}
                        />

                        <FieldError error={errors.bank_name} />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={4}>
                      <FormGroup label="Show in Transactions">
                        <Controller
                          name="transactions"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                      <FormGroup label="Notes" hasError={!!errors.notes}>
                        <Controller
                          name="notes"
                          control={control}
                          render={({ field }) => (
                            <Input
                              sx={WhiteInput}
                              placeholder="Notes"
                              multiline
                              rows={4}
                              {...field}
                            />
                          )}
                        />

                        <FieldError error={errors.notes} />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Card>
              </CardContent>
              {id && (
                <CardContent sx={{ mt: 5, overflowX: 'scroll' }}>
                  <Typography variant="h4">Account numbers</Typography>
                  <Divider />
                  <CardsTable bank={currentBank} currencyOptions={currencyOptions} />
                </CardContent>
              )}
            </>
          )}

        </Card>
      </form>
    </FormProvider>
  );
};

export default CreateBank;
