import React from 'react';
import {
  Card, Chip, Grid, Typography
} from '@mui/material';
import { getCardBrandIcon } from '../../../../../../utils/creditCard';
import FormGroup from '../../../../../../form/components/FormGroup';
import { useFormContext } from 'react-hook-form';

const CreditCards = ({ options, type }) => {
  const { creditCardsOptions } = options;
  const { watch } = useFormContext();

  return (
    <FormGroup label={type === 'handed_over' ? 'Handed over Credit Cards' : 'Taken Over Credit Cards'}>
      <Card sx={{ padding: '1rem' }}>
        <Grid container spacing={2}>
          {!watch('aircraft_id') && (
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ color: 'info' }}>
                Select Aircraft is required to show credit cards.
              </Typography>
            </Grid>
          )}

          {(creditCardsOptions?.length === 0 && !!watch('aircraft_id')) && (
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ color: 'red' }}>
                Selected Aircraft has no credit cards. Please contact OPS to add credit cards to the aircraft.
              </Typography>
            </Grid>
          )}

          {creditCardsOptions?.map((card, index) => (
            <Grid item xs={6}>
              <Chip icon={getCardBrandIcon(card?.label)} key={index} label={card?.label} variant="filled" />
            </Grid>
          ))}
        </Grid>
      </Card>
    </FormGroup>
  );
};

export default CreditCards;
