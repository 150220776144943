import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Card,
  Divider,
  Grid,
  Typography,
  Box
} from '@mui/material';
import { StatusCardStyle } from '../styles';
import FormGroup from '../../../../../../form/components/FormGroup';
import Input from '../../../../../../form/components/Input';
import { WhiteInput } from '../../../../../Finance/InvoiceManagement/styles';

const EditSendStatus = ({ orderPartType, canEditInput }) => {
  const {
    getValues,
    control,
  } = useFormContext();

  return (
    <Grid item xs={12}>
      <Card sx={StatusCardStyle}>
        <Box>
          <Typography variant="h6">
            Edit
            {' '}
            <b>Send</b>
            {' '}
            Status:
          </Typography>
        </Box>

        <Typography variant="subtitle2">
          {`${getValues('send.creator')} - ${getValues('send.created_at')}`}
        </Typography>

        <Divider />

        {['consumable', 'part', 'Other'].includes(orderPartType) && (
          <Grid container spacing={4}>
            {['consumable', 'part'].includes(orderPartType) && (
              <Grid item xs={6}>
                <FormGroup label="Send Remarks">
                  <Controller
                    name="send.remarks"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        rows={2}
                        placeholder="Enter Remarks"
                        multiline
                        disabled={!canEditInput('send.remarks')}
                        sx={{ ...(WhiteInput), width: '100%' }}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
            )}
            {['Other'].includes(orderPartType) && (
              <Grid item xs={6}>
                <FormGroup label="Description">
                  <Controller
                    name="send.description"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        rows={2}
                        placeholder="Enter Description"
                        multiline
                        disabled={!canEditInput('send.description')}
                        sx={{ ...(WhiteInput), width: '100%' }}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
            )}
          </Grid>
        )}
        {['delivery', 'Exchange', 'Other'].includes(orderPartType) && (
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormGroup label="Address From">
                <Controller
                  name="send.address_from"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Enter Address"
                      disabled={!canEditInput('send.address_from')}
                      sx={{ ...(WhiteInput), width: '100%' }}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup label="Address To">
                <Controller
                  name="send.address_to"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Enter Address"
                      disabled={!canEditInput('send.address_to')}
                      sx={{ ...(WhiteInput), width: '100%' }}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
          </Grid>
        )}
      </Card>
    </Grid>
  );
};

export default EditSendStatus;
