import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Card,
  Divider,
  Grid,
  Typography,
  Box
} from '@mui/material';
import { StatusCardStyle } from '../styles';
import FormGroup from '../../../../../../form/components/FormGroup';
import Input from '../../../../../../form/components/Input';
import ServerAutocomplete from '../../../../../../form/components/ServerAutocomplete';
import { useSearchSupplierQuery } from '../../../../../../store/slices/logisticsSlice';
import DatePicker from '../../../../../../form/components/DatePicker';
import { setDateValue, validateDatePickerValue } from '../../../../../../utils/setDateValue';
import { WhiteInput } from '../../../../../Finance/InvoiceManagement/styles';

const EditOnTheWayStatus = ({ orderPartType, canEditInput }) => {
  const {
    getValues,
    setValue,
    control,
  } = useFormContext();

  return (
    <Grid item xs={12}>
      <Card sx={StatusCardStyle}>
        <Box>
          <Typography variant="h6">
            Edit
            {' '}
            <b>On The Way</b>
            {' '}
            Status:
          </Typography>
        </Box>

        <Typography variant="subtitle2">
          {`${getValues('ordered.ordered')} - ${getValues('ordered.ordered_at')}`}
        </Typography>

        <Divider />

        <Grid container spacing={4}>
          <Grid item xs={4}>
            <FormGroup label="TTM Code" required>
              <Controller
                name="ordered.ttm_code"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Enter Tracking Number"
                    disabled={!canEditInput('ordered.ttm_code')}
                    sx={{ ...(WhiteInput), width: '100%' }}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <FormGroup label="Delivery operator" required>
              <Controller
                control={control}
                name="ordered.delivery_operator"
                render={({ field }) => (
                  <ServerAutocomplete
                    fields={field}
                    withData
                    placeholder="Search operators by name or email"
                    searchQueryFn={useSearchSupplierQuery}
                    sx={{ ...(WhiteInput), width: '100%' }}
                    disabled={!canEditInput('ordered.delivery_operator_id')}
                    handleSelect={(value) => {
                      field.onChange(value);
                      setValue('ordered.delivery_operator_id', value?.value || null);
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          {(!['Overhaul_out', 'Repair_out', 'Repair_in', 'Overhaul_in'].includes(orderPartType) && !getValues('approved')?.price?.carrier) && (
            <Grid item xs={4}>
              <FormGroup label="Delivery Due Date">
                <Controller
                  control={control}
                  name="ordered.delivery_due_date"
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="YYYY-MM-DD"
                      dateFormat="yyyy-MM-dd"
                      showMonthDropdown
                      showYearDropdown
                      disabled={!canEditInput('ordered.delivery_due_date')}
                      sx={{ ...(WhiteInput), width: '100%' }}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setDateValue(e, setValue, 'ordered.delivery_due_date', 'YYYY-MM-DD');
                      }}
                      value={validateDatePickerValue(field.value)}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
          )}
          {getValues('approved')?.price?.transaction_type === 'exchange' && (
            <Grid item xs={4}>
              <FormGroup label="Exchange Due Date">
                <Controller
                  name="ordered.exchange_due_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="YYYY-MM-DD"
                      dateFormat="yyyy-MM-dd"
                      showMonthDropdown
                      showYearDropdown
                      disabled={!canEditInput('ordered.exchange_due_date')}
                      sx={{ ...(WhiteInput), width: '100%' }}
                      onChange={(e) => {
                        field.onChange(e);
                        setDateValue(e, setValue, 'ordered.exchange_due_date', 'YYYY-MM-DD');
                      }}
                      value={validateDatePickerValue(field.value)}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

export default EditOnTheWayStatus;
