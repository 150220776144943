import { SvgIcon } from '@mui/material';
import React from 'react';

const LowestPriorityIcon = () => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path
      d="M3,4h10c0.6,0,1,0.4,1,1s-0.4,1-1,1H3C2.4,6,2,5.6,2,5S2.4,4,3,4z"
      fill="#8d8d8d"
    />
  </SvgIcon>
);

export default LowestPriorityIcon;
