import React from 'react';
import {
  Chip,
  Grid,
  Divider,
  Typography
} from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Upload from '../../../../../../../components/Upload';
import { fileToBase64 } from '../../../../../../../utils/utils';
import { useGetTransactionFileMutation } from '../../../../../../../store/slices/aircraftReportsSlice';
import toast from 'react-hot-toast';

const Files = ({ options, trxIndex }) => {
  const {
    control,
  } = useFormContext();

  const {
    fields: fileFields,
    append: fileAppend,
    remove: fileRemove
  } = useFieldArray({
    name: `transactions.${trxIndex}.files`,
    control,
  });

  const handleDeleteFile = (index) => () => {
    fileRemove(index);
  };

  const [download, { isLoading: fileLoading }] = useGetTransactionFileMutation();
  const handleDownloadFile = (id) => () => {
    if (fileLoading || !id) return;
    const toastId = toast.loading('Loading...');

    download(id).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleAddFile = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileName = file.name;
      const fileAsBase64 = await fileToBase64(file);

      fileAppend({
        content: fileAsBase64.base64,
        extension: fileAsBase64.extension,
        name: fileName,
      });
    }
  };

  return (
    <Grid container>
      {!options.readOnly ? (
        <Grid item xs={12}>
          <Upload
            title="Upload file"
            disabled={options.readOnly}
            handleUpload={(e) => handleAddFile(e)}
          />
          <Divider />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography variant="h6">Files</Typography>
          <Divider />
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid container spacing={2}>
          {fileFields.length === 0 && (
            <Grid item>
              <Typography variant="subtitle1" sx={{ color: 'red' }}>No uploaded files</Typography>
            </Grid>
          )}

          {fileFields.length > 0 && fileFields.map((file, index) => (
            <Grid item key={file?.id || index}>
              <Chip
                color="success"
                label={file?.name}
                onClick={handleDownloadFile(file?.file_id)}
                onDelete={options.readOnly ? undefined : handleDeleteFile(index)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Files;
