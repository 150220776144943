import { boolean, object, string } from 'yup';

export const defaultValues = {
  bank_name: '',
  notes: '',
  transactions: false,
  account_numbers: [
    {
      bank_id: null,
      account_number: '',
      currency_id: null,
      account_number_notes: '',
      cards: [
        {
          card_number: '',
          cardholder: '',
          expire_date: '',
          cvc: '',
          aircraft_id: null,
        }
      ]
    }
  ]
};

export const schema = object().shape({
  bank_name: string()
    .required('${label} cannot be blank.')
    .label('Name'),
  notes: string().nullable(),
  transactions: boolean().nullable(),
});
