import {
  object, string, number, ref
} from 'yup';

export const defaultValues = {
  ac_handed_over_at: null,
  ac_taken_at: null,
  actions: [],
  aircraft_id: '',
  aircraft_registration: '',
  aircraft_type_name: '',
  block_off: null,
  block_on: null,
  crew_cca: '',
  crew_engineer: '',
  crew_fo: '',
  crew_insp: '',
  crew_lm: '',
  crew_pic: '',
  fuel_density: '',
  current_airv: '',
  current_block: '',
  current_ldg: '',
  defects: [],
  flight_number: '',
  from_id: '',
  fuel_arrival_kg: '',
  fuel_departure_kg: '',
  fuel_uplift: '',
  fuel_uplift_kg: '',
  fuel_planned_kg: '',
  previous_fuel_arrival_kg: '',
  iceing_hot: '',
  iceing_percent: '',
  iceing_time: '',
  iceing_type: '',
  id: '',
  index: '',
  load_cargo: '',
  load_drg: '',
  load_pax: '',
  ods_status: '',
  oil_lh_eng_arrival: '',
  oil_lh_eng_departure: '',
  oil_lh_eng_uplift: '',
  oil_lh_pgb_arrival: '',
  oil_lh_pgb_departure: '',
  oil_lh_pgb_uplift: '',
  oil_rh_eng_arrival: '',
  oil_rh_eng_departure: '',
  oil_rh_eng_uplift: '',
  oil_rh_pgb_arrival: '',
  oil_rh_pgb_departure: '',
  oil_rh_pgb_uplift: '',
  pax_adults: '',
  pax_children: '',
  pax_infants: '',
  previous_air: '',
  previous_block: '',
  departureDate: null,
  previous_ldg: '',
  regardings_flight_level: '',
  regardings_hyd_qty: '',
  regardings_ias: '',
  regardings_lh_eng_oil_temp: '',
  regardings_lh_eng_rpm: '',
  regardings_lh_eng_temp: '',
  regardings_lh_fuel_flow: '',
  regardings_lh_prop_oil_temp: '',
  regardings_lh_prop_rpm: '',
  regardings_lh_start_time: '',
  regardings_lh_trq: '',
  regardings_oat: '',
  regardings_rh_eng_oil_temp: '',
  regardings_rh_eng_rpm: '',
  regardings_rh_eng_temp: '',
  regardings_rh_fuel_flow: '',
  regardings_rh_prop_oil_temp: '',
  regardings_rh_prop_rpm: '',
  regardings_rh_start_time: '',
  regardings_rh_trq: '',
  remark: '',
  security_check_at: null,
  take_off: null,
  take_on: '',
  to_id: '',
  toc: '',
  weight_lw: '',
  weight_tow: '',
  approach_type: '',
};

export const schema = object().shape({
  toc: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('TOC'),
  block_off: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('BLOCK OFF'),
  take_off: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('TAKE OFF'),
  take_on: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('LAND'),
  block_on: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('BLOCK ON'),
  load_cargo: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('CARGO'),
  load_pax: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('PAX'),
  load_drg: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('DRG'),
  fuel_departure_kg: number()
    .transform((_, originalValue) => parseFloat(originalValue) || 0)
    .nullable(),
  fuel_arrival_kg: number()
    .transform((_, originalValue) => parseFloat(originalValue) || 0)
    .required('${label} cannot be blank.')
    .nullable()
    .positive('${label} must be greater than 0.')
  //   .max(ref('fuel_departure_kg'), '${label} cannot be greater than DEPARTURE.')
    .label('ARRIVAL'),
  fuel_planned_kg: number()
    .transform((_, originalValue) => parseFloat(originalValue) || 0)
    .positive('${label} must be greater than 0.')
    .required('${label} cannot be blank.')
    .label('PLANNED'),
  weight_tow: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('TOW'),
  fuel_density: string()
    .when(['fuel_uplift'], (fuel_uplift, fuelSchema) => {
      if (fuel_uplift) return fuelSchema.required('${label} cannot be blank.');

      return fuelSchema;
    })
    .label('DENSITY')
    .nullable(),
  weight_lw: string()
    .nullable()
    .required('${label} cannot be blank.')
    .label('LW'),
  approach_type: string()
    .nullable()
    .label('APPROACH TYPE'),
});
