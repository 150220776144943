import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { HeaderName } from '../../../../components/EditableTable/styles';
import FiltrationInput from '../../../../components/EditableTable/FiltrationInput';
import moment from 'moment';
import React from 'react';

export const columns = ({
  handleFilter,
  filters,
  handleOpenReport
}) => ([
  {
    flex: 0.2,
    field: 'id',
    minWidth: 200,
    headerName: 'Record ID',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Record ID</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.id || ''}
          value="id"
          placeholder="Search by ID"
        />
      </Box>
    ),
    renderCell: ({ row: { id } }) => (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          textDecorationLine: 'underline',
          cursor: 'pointer'
        }}
        variant="subtitle1"
        onClick={(e) => handleOpenReport(e, id)}
      >
        {`Report #${id}`}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'status',
    minWidth: 200,
    headerName: 'Status',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Status</Typography>
      </Box>
    ),
    renderCell: ({ row: { status } }) => (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          textDecorationLine: 'underline',
          cursor: 'pointer'
        }}
        variant="subtitle1"
      >
        {`${status}`}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'aircraft_registration',
    minWidth: 200,
    headerName: 'Aircraft',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Aircraft</Typography>
      </Box>
    ),
    renderCell: ({ row: { aircraft_registration } }) => (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
        }}
        variant="subtitle1"
      >
        {`${aircraft_registration}`}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'crew_name',
    minWidth: 200,
    headerName: 'Crew',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Crew</Typography>
      </Box>
    ),
    renderCell: ({ row: { crew_name } }) => (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
        }}
        variant="subtitle1"
      >
        {`${crew_name}`}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'created_at',
    minWidth: 200,
    headerName: 'Created at',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Created at</Typography>
      </Box>
    ),
    renderCell: ({ row: { created_at } }) => (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
        }}
        variant="subtitle1"
      >
        {moment(created_at).format('YYYY-MM-DD HH:mm')}
      </Typography>
    ),
  },
]);
