import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '../../../../../components/Modal';
import CloseIcon from '@mui/icons-material/Close';
import {
  footerSx, headerSx, cancelButton, stuckSx, headerTitleSx
} from './styles';

export const DocumentConfirmModal = ({ onClose, onAccept, open }) => (
  <Modal onClose={onClose} open={open}>
    <Box>
      <Box sx={headerSx}>
        <Box sx={headerTitleSx}>Confirm Document</Box>
        <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
      </Box>
      <Box sx={stuckSx}>
        <Box><b>Read each statement below carefully!</b></Box>
        <Box>By tapping on the "CONFIRM" button below I hereby declare that I will read the downloaded documents and report back to <b>Fleet Air International</b> if I don't understand them.</Box>
        <Box>I hereby also declare that I will download documents that were not downloaded successfully and will read them and report back if I don't understand them.</Box>
      </Box>
      <Box sx={footerSx}>
        <Button onClick={onClose} sx={cancelButton}>Cancel</Button>
        <Button onClick={onAccept} variant="contained">Confirm</Button>
      </Box>
    </Box>
  </Modal>
);
