import { useSelector } from 'react-redux';

export const usePermissions = (permission, admin = true) => {
  if (!permission) return true;

  const { user } = useSelector((state) => state.session);

  if (user?.role === 'admin' && admin) {
    return true;
  }

  if (typeof permission === 'string') {
    return !!user?.group[permission] || false;
  }

  const keysOfPermissions = Object.keys(user?.group || {});

  const arrayOfAccesses = keysOfPermissions.filter(i => user?.group[i]);

  return arrayOfAccesses.some(access => permission.includes(access));
};
