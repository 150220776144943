import React from 'react';
import {
  Dialog, Grid, DialogContent, Divider, CircularProgress
} from '@mui/material';
import { useStatusType } from './useStatusType';
import FormGroup from '../../../../../form/components/FormGroup/FormGroup';
import { Controller } from 'react-hook-form';
import Select from '../../../../../form/components/Select';
import FieldError from '../../../../../form/components/FieldError';
import { STATUS_TYPES } from '../../constants';
import Input from '../../../../../form/components/Input';
import Button from '../../../../../components/Button';

const StatusTypeModal = (props) => {
  const {
    open,
    onClose,
    trxId
  } = props;

  const {
    form: {
      handleSubmit,
      control,
      watch,
      formState: { errors }
    },
    onSubmit,
    isLoading
  } = useStatusType(trxId, onClose);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogContent>
        {isLoading ? (
          <CircularProgress sx={{
            marginTop: '2rem',
          }}
          />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid contrainer spacing={6}>
              <Grid item xs={4}>
                <FormGroup required label="Status Type" hasError={!!errors.status_type}>
                  <Controller
                    name="status_type"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="Select Status Type"
                        options={STATUS_TYPES}
                      />
                    )}
                  />
                  <FieldError error={errors.status_type} />
                </FormGroup>
              </Grid>

              {watch('status_type') === 'incorrect' && (
                <Grid item xs={8}>
                  <FormGroup label="Comments" hasError={!!errors.comments_external}>
                    <Controller
                      name="comments_external"
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder="Comments"
                          multiline
                          rows={6}
                          {...field}
                        />
                      )}
                    />
                    <FieldError error={errors.comments_external} />
                  </FormGroup>
                </Grid>
              )}

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={6}>
                <Button
                  title="Update"
                  type="submit"
                  color="primary"
                />
              </Grid>

            </Grid>
          </form>
        )}

      </DialogContent>
    </Dialog>
  );
};

export default StatusTypeModal;
