import React from 'react';
import {
  Grid, Card, TextField, InputAdornment
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker from '../../../../../../form/components/DatePicker';
import { setDateValue, validateDatePickerValue } from '../../../../../../utils/setDateValue';
import FormGroup from '../../../../../../form/components/FormGroup';
import { WhiteInput } from '../../../../../Finance/InvoiceManagement/styles';
import { Rename } from 'mdi-material-ui';
import { numberFormatValidate } from '../../../../../../utils/number';
import Select from '../../../../../../form/components/Select';
import { AIRCRAFT_DATA_STATUSES } from '../constants';

const AircraftForm = ({ aircraft, dataFields }) => {
  const {
    formState: { errors },
    control,
    setValue
  } = useFormContext();

  const {
    fields: dataFieldsArray,
    append: appendDataFields,
    remove: removeDataFields
  } = dataFields;

  return (
    <Card sx={{ p: '1rem' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {dataFieldsArray?.map((field, index) => {
            if (field.aircraft_id === aircraft) {
              return (
                <Grid container spacing={2} key={index}>
                  <Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
                    <FormGroup label="Last Done Date" hasError={!!errors.data?.[index]?.last_done_date}>
                      <Controller
                        name={`data.${index}.last_done_date`}
                        control={control}
                        value={validateDatePickerValue(field.value)}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            sx={{
                              ...(WhiteInput),
                              '& > .react-datepicker__tab-loop': {
                                zIndex: 2000
                              }
                            }}
                            value={validateDatePickerValue(field.value)}
                            placeholderText="YYYY-MM-DD"
                            dateFormat="yyyy-MM-dd"
                            showMonthDropdown
                            showYearDropdown
                            onChange={(e) => {
                              field.onChange(e);
                              setDateValue(e, setValue, `data.${index}.last_done_date`, 'YYYY-MM-DD');
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
                    <FormGroup label="Last Done FH" hasError={!!errors?.data?.[index]?.last_done_fh}>
                      <Controller
                        name={`data.${[index]}.last_done_fh`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            type="number"
                            sx={{ width: '100%', ...(WhiteInput) }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              const { value } = e.target;

                              if (numberFormatValidate(value)) {
                                field.onChange(value);
                              }
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
                    <FormGroup label="Last Done FC" hasError={!!errors?.data?.[index]?.last_done_fc}>
                      <Controller
                        name={`data.${[index]}.last_done_fc`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            type="number"
                            sx={{ width: '100%', ...(WhiteInput) }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              const { value } = e.target;

                              if (numberFormatValidate(value)) {
                                field.onChange(value);
                              }
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
                    <FormGroup label="Interval Days" hasError={!!errors?.data?.[index]?.interval_days}>
                      <Controller
                        name={`data.${[index]}.interval_days`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            type="number"
                            sx={{ width: '100%', ...(WhiteInput) }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              const { value } = e.target;

                              if (numberFormatValidate(value)) {
                                field.onChange(value);
                              }
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
                    <FormGroup label="Interval FH" hasError={!!errors?.data?.[index]?.interval_fh}>
                      <Controller
                        name={`data.${[index]}.interval_fh`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            type="number"
                            sx={{ width: '100%', ...(WhiteInput) }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              const { value } = e.target;

                              if (numberFormatValidate(value)) {
                                field.onChange(value);
                              }
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
                    <FormGroup label="Interval FC" hasError={!!errors?.data?.[index]?.interval_fc}>
                      <Controller
                        name={`data.${[index]}.interval_fc`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            type="number"
                            sx={{ width: '100%', ...(WhiteInput) }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              const { value } = e.target;

                              if (numberFormatValidate(value)) {
                                field.onChange(value);
                              }
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
                    <FormGroup label="Due Date" hasError={!!errors.data?.[index]?.due_date}>
                      <Controller
                        name={`data.${index}.due_date`}
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            sx={{
                              ...(WhiteInput),
                              '& > .react-datepicker__tab-loop': {
                                zIndex: 2000
                              }
                            }}
                            value={validateDatePickerValue(field.value)}
                            placeholderText="YYYY-MM-DD"
                            dateFormat="yyyy-MM-dd"
                            showMonthDropdown
                            showYearDropdown
                            onChange={(e) => {
                              field.onChange(e);
                              setDateValue(e, setValue, `data.${index}.due_date`, 'YYYY-MM-DD');
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
                    <FormGroup label="Due FH" hasError={!!errors?.data?.[index]?.due_fh}>
                      <Controller
                        name={`data.${[index]}.due_fh`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            type="number"
                            sx={{ width: '100%', ...(WhiteInput) }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              const { value } = e.target;

                              if (numberFormatValidate(value)) {
                                field.onChange(value);
                              }
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
                    <FormGroup label="Due FC" hasError={!!errors?.data?.[index]?.due_fc}>
                      <Controller
                        name={`data.${[index]}.due_fc`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            type="number"
                            sx={{ width: '100%', ...(WhiteInput) }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              const { value } = e.target;

                              if (numberFormatValidate(value)) {
                                field.onChange(value);
                              }
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xl={8} lg={8} md={8} xs={12} sm={12}>
                    <FormGroup label="Remarks" hasError={!!errors?.data?.[index]?.remarks}>
                      <Controller
                        name={`data.${[index]}.remarks`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            multiline
                            rows={6}
                            fullWidth
                            error={!!errors?.data?.[index]?.remarks}
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
                    <FormGroup label="Status" required hasError={!!errors?.data?.[index]?.status}>
                      <Controller
                        name={`data.${[index]}.status`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            options={AIRCRAFT_DATA_STATUSES}
                            {...field}
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>


                </Grid>
              );
            }

            return null;
          })}
        </Grid>
      </Grid>
    </Card>

  );
};

export default AircraftForm;
