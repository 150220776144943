import React from 'react';
import EditableTable from '../../../../components/EditableTable';
import { useGetOdsQuery } from '../../../../store/session';
import { columns } from './columns';
import { tableContainer } from '../../OdsTable/styles';
import { Box } from '@mui/material';

const ApproachTypeTable = ({ filterState }) => (
  <Box sx={{ ...tableContainer, mt: 3 }}>
    <EditableTable
      getRowId={(row) => row?.id}
      getRowClassName={(params) => `row-${params?.row.type === 'M' ? 'm' : 'f'} row-${params.row.ods_status === 'CNLD' && 'cnld'}`}
      sortByType="DESC"
      sortByActive="departure"
      useHook={useGetOdsQuery}
      tableParams={filterState}
      columns={columns}

    />
  </Box>
);

export default ApproachTypeTable;
