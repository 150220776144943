import React, { useState } from 'react';
import {
  Card,
  Typography,
  Grid,
  Box,
  Divider,
  LinearProgress,
  Chip
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { TextWithIcon } from '../../../Logistics/style';
import DatePicker from '../../../../form/components/DatePicker';
import { validateDatePickerValue } from '../../../../utils/setDateValue';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { WhiteInput } from '../../InvoiceManagement/styles';
import ListIcon from '@mui/icons-material/List';
import moment from 'moment/moment';

const Filters = ({ filters, setFilters, isLoading }) => {
  const [selectedChip, setSelectedChip] = useState('current_year');

  const handleFilter = (period) => {
    setSelectedChip(period);
    // eslint-disable-next-line default-case
    switch (period) {
      case 'last_week':
        setFilters((prev) => ({
          ...prev,
          date_from: moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
          date_to: moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'),
        }));
        break;
      case 'last_month':
        setFilters((prev) => ({
          ...prev,
          date_from: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
          date_to: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
        }));
        break;
      case 'last_year':
        setFilters((prev) => ({
          ...prev,
          date_from: moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD'),
          date_to: moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD'),
        }));
        break;
      case 'current_week':
        setFilters((prev) => ({
          ...prev,
          date_from: moment().startOf('week').format('YYYY-MM-DD'),
          date_to: moment().endOf('week').format('YYYY-MM-DD'),
        }));
        break;
      case 'current_month':
        setFilters((prev) => ({
          ...prev,
          date_from: moment().startOf('month').format('YYYY-MM-DD'),
          date_to: moment().endOf('month').format('YYYY-MM-DD'),
        }));
        break;
      case 'current_year':
        setFilters((prev) => ({
          ...prev,
          date_from: moment().startOf('year').format('YYYY-MM-DD'),
          date_to: moment().endOf('year').format('YYYY-MM-DD'),
        }));
        break;
    }
  };

  return (
    <Card sx={{ padding: '1rem' }}>
      <Typography sx={TextWithIcon} variant="h5" nowrap>
        <FilterAltIcon />
        Filters
      </Typography>

      <Box sx={{ minHeight: '6px' }}>
        {isLoading && (
          <LinearProgress />
        )}
      </Box>

      <Divider />

      <Card sx={{ padding: '1rem', backgroundColor: '#dfdfe0' }}>
        <Grid container spacing={6}>
          <Grid item xs={12} lg={3}>
            <Box sx={TextWithIcon}>
              <CalendarMonthIcon />
              <Typography variant="h6" noWrap>Date From</Typography>
            </Box>

            <Divider />

            <DatePicker
              maxDate={filters?.date_to ? moment(filters.date_to).toDate() : null}
              sx={WhiteInput}
              placeholderText="From..."
              dateFormat="yyyy-MM-dd"
              showMonthDropdown
              showYearDropdown
              onChange={(e) => {
                setFilters((prev) => ({ ...prev, date_from: e ? moment(e).format('YYYY-MM-DD') : '' }));
                setSelectedChip(null);
              }}
              value={validateDatePickerValue(filters?.date_from || '')}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <Box sx={TextWithIcon}>
              <CalendarMonthIcon />
              <Typography variant="h6" noWrap>Date To</Typography>
            </Box>

            <Divider />

            <DatePicker
              minDate={filters?.date_from ? moment(filters.date_from).toDate() : null}
              sx={WhiteInput}
              placeholderText="To..."
              dateFormat="yyyy-MM-dd"
              showMonthDropdown
              showYearDropdown
              onChange={(e) => {
                setFilters((prev) => ({ ...prev, date_to: e ? moment(e).format('YYYY-MM-DD') : '' }));
                setSelectedChip(null);
              }}
              value={validateDatePickerValue(filters?.date_to || '')}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <Box sx={TextWithIcon}>
              <ListIcon />
              <Typography variant="h6" noWrap>Options</Typography>
            </Box>

            <Divider />

            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card sx={{ padding: '1rem' }}>
                  <Grid
                    container
                    spacing={6}
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    <Grid item xs={12}>
                      <Grid container spacing={6}>
                        <Grid item xs={4} lg={4}>
                          <Chip
                            label="Last Week"
                            variant={selectedChip === 'last_week' ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => handleFilter('last_week')}
                          />
                        </Grid>
                        <Grid item xs={4} lg={4}>
                          <Chip
                            label="Last Month"
                            variant={selectedChip === 'last_month' ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => handleFilter('last_month')}
                          />
                        </Grid>
                        <Grid item xs={4} lg={4}>
                          <Chip
                            label="Last Year"
                            variant={selectedChip === 'last_year' ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => handleFilter('last_year')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={6}>
                        <Grid item xs={4} lg={4}>
                          <Chip
                            label="Current Week"
                            variant={selectedChip === 'current_week' ? 'contained' : 'outlined'}
                            color="success"
                            onClick={() => handleFilter('current_week')}
                          />
                        </Grid>
                        <Grid item xs={4} lg={4}>
                          <Chip
                            label="Current Month"
                            variant={selectedChip === 'current_month' ? 'contained' : 'outlined'}
                            color="success"
                            onClick={() => handleFilter('current_month')}
                          />
                        </Grid>
                        <Grid item xs={4} lg={4}>
                          <Chip
                            label="Current Year"
                            variant={selectedChip === 'current_year' ? 'contained' : 'outlined'}
                            color="success"
                            onClick={() => handleFilter('current_year')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Card>
    </Card>
  );
};

export default Filters;
