import React, { useState } from 'react';
import {
  Box,
  Card as MuiCard,
  Dialog,
  DialogContent,
  Divider,
  Typography,
  Grid, InputAdornment
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { CloseIconStyle } from '../../../Logistics/OrdersDashboard/styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import Card from 'react-credit-cards';
import FormGroup from '../../../../form/components/FormGroup/FormGroup';
import Input from '../../../../form/components/Input/Input';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {
  formatCreditCardNumber, formatCVC, formatExpirationDate
} from '../../../../utils/input';
import Button from '../../../../components/Button/Button';
import { GreenButton } from '../../../Logistics/style';
import SaveIcon from '@mui/icons-material/Save';
import { useGetAircraftListQuery } from '../../../../store/session';
import { getOptionsWithData } from '../../../../utils/getOptions';
import Select from '../../../../form/components/Select';

const AddCreditCard = (props) => {
  const {
    open,
    onClose,
    update
  } = props;
  const index = open?.index;
  const data = open?.data;
  const { watch } = useFormContext();

  const defaultValues = {
    card_credit_number: '',
    expiry: '',
    cvv: '',
    holder: '',
    aircraft_id: null
  };
  const [cardState, setCardState] = useState(defaultValues);
  const { data: AircraftList } = useGetAircraftListQuery();
  const aircraftOptions = getOptionsWithData(AircraftList);

  const handleAddCard = () => {
    const accountNumbers = watch(`account_numbers.${index}`);

    update(
      index,
      {
        ...accountNumbers,
        cards: [...(data.cards || []), {
          card_number: cardState.card_credit_number,
          expire_date: cardState.expiry,
          cvc: cardState.cvv,
          cardholder: cardState.holder,
          aircraft_id: cardState.aircraft_id
        }]
      }
    );

    setCardState(defaultValues);
    onClose();
  };

  const handleInputFocus = (e) => {
    setCardState({ ...cardState, focused: e.target.name });
  };

  return (
    <Dialog
      fullWidth
      open={!!open}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogContent>
        <MuiCard sx={{ p: '1rem' }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
          >
            <Box>
              <Typography variant="h5" noWrap>
                Add New Credit Card
              </Typography>
            </Box>
            <CloseIcon
              sx={CloseIconStyle}
              onClick={onClose}
            />
          </Box>

          <Divider />

          <Grid container spacing={6}>
            <Grid item xs={12} lg={6} sx={{ alignContent: 'center' }}>
              <Box>
                <Card
                  cvc={cardState.cvv}
                  expiry={cardState.expiry}
                  focused={cardState.focused}
                  name={cardState.holder}
                  number={cardState.card_credit_number}
                />
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormGroup label="Card Number">
                    <Input
                      fullWidth
                      autoComplete="off"
                      placeholder="0000 0000 0000 0000"
                      value={cardState.card_credit_number}
                      onChange={(e) => {
                        setCardState({ ...cardState, card_credit_number: formatCreditCardNumber(e.target.value) });
                      }}
                      onFocus={handleInputFocus}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CreditCardIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <FormGroup label="Expiry Date">
                    <Input
                      fullWidth
                      placeholder="MM/YY"
                      value={cardState.expiry}
                      onChange={(e) => setCardState({ ...cardState, expiry: formatExpirationDate(e.target.value) })}
                      onFocus={handleInputFocus}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <FormGroup label="CVV Code">
                    <Input
                      fullWidth
                      placeholder="123"
                      value={cardState.cvv}
                      onChange={(e) => setCardState({ ...cardState, cvv: formatCVC(e.target.value) })}
                      onFocus={handleInputFocus}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup label="Card Holder">
                    <Input
                      fullWidth
                      value={cardState.holder}
                      inputProps={{ maxLength: 55 }}
                      onChange={(e) => setCardState({ ...cardState, holder: e.target.value })}
                      onFocus={handleInputFocus}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup label="Aircraft">
                    <Select
                      onChange={(e) => setCardState({ ...cardState, aircraft_id: e.target.value })}
                      options={[
                        { value: null, label: 'N/A' },
                        ...aircraftOptions
                      ]}
                      placeholder="Aircraft"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Divider />
              <Button
                sx={{ ...(GreenButton), mt: '1rem' }}
                title="Save Card"
                onClick={handleAddCard}
                startIcon={<SaveIcon />}
              />
            </Grid>
          </Grid>
        </MuiCard>
      </DialogContent>

    </Dialog>
  );
};

export default AddCreditCard;
