import { createSlice } from '@reduxjs/toolkit';
import { useProvideTags } from '../../utils/hooks/useProvideTags';
import { sessionApi } from '../session';

// State and actions
export const financeSlice = createSlice({
  name: 'finance',
  initialState: {},
});

// Finance Endpoints
sessionApi.injectEndpoints({
  endpoints: (builder) => ({
    getTransactionTable: builder.query({
      query: (data) => `/finance/transaction/table${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['TRANSACTIONS']),
    }),
    getTransactionDetails: builder.query({
      query: (trx_id) => `/finance/transaction/details/${trx_id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    uploadTransactions: builder.mutation({
      query: (data) => ({
        url: '/finance/transaction/import',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['UPLOAD_TRANSACTIONS']),
    }),
    toggleTypeTransactions: builder.mutation({
      query: ({ id }) => ({
        url: `/finance/transaction/toggle/type/${id}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error)
    }),
    toggleStatusTransactions: builder.mutation({
      query: ({ id }) => ({
        url: `/finance/transaction/toggle/status/${id}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error)
    }),
    getTransactionUploadsTable: builder.query({
      query: (data) => `/finance/transaction/uploads${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['UPLOAD_TRANSACTIONS']),
    }),
    getTransactionUploadFile: builder.mutation({
      query: (upload_id) => ({
        url: `/finance/transaction/upload/file/${upload_id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getTransactionUploadErrorsTable: builder.query({
      query: (upload_id) => `/finance/transaction/upload/details/${upload_id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteTransactionsUpload: builder.mutation({
      query: (id) => ({
        url: `/finance/transaction/upload/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateTransaction: builder.mutation({
      query: ({ id, data }) => ({
        url: `/finance/transaction/update/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: () => (['TRANSACTIONS', 'UPDATE_DATA']),
    }),
    updateFinanceInvoice: builder.mutation({
      query: ({ id, data }) => ({
        url: `/finance/generate/invoice_update/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getIncomeTable: builder.query({
      query: (data) => `/finance/income${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getIncomeTotal: builder.query({
      query: (data) => `/finance/income_total${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateIncomeItem: builder.mutation({
      query: ({ id, data }) => ({
        url: `/finance/income/${id}?${new URLSearchParams(data).toString()}`,
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    showIncomeItem: builder.query({
      query: ({ id }) => `/finance/income/${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getIncomeFile: builder.mutation({
      query: (id) => ({
        url: `/finance/income/files/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    generateNotification: builder.mutation({
      query: ({ data }) => ({
        url: '/finance/income/notification',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getInvoicesList: builder.query({
      query: ({ id }) => `/finance/income_list?flight_id=${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getInvoicesTotal: builder.query({
      query: () => '/finance/generate/invoice_total',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFinanceInvoiceTable: builder.query({
      query: (data) => `/finance/generate/invoice${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteFinanceInvoice: builder.mutation({
      query: (id) => ({
        url: `/finance/generate/invoice/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateFinanceInvoiceStatus: builder.mutation({
      query: (id) => ({
        url: `/finance/generate/invoice/${id}`,
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFinanceInvoiceFile: builder.mutation({
      query: (id) => ({
        url: `/finance/generate/invoice/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    generateFinanceInvoice: builder.mutation({
      query: ({ data }) => ({
        url: '/finance/generate/invoice',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updatePricesGenerateFinanceInvoice: builder.mutation({
      query: ({ data, id, currency_id }) => ({
        url: `/finance/generate/total_price_update/${id}?invoice_data=${encodeURIComponent(data)}&currency_id=${currency_id}`,
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getMonthlyTransactionsReport: builder.query({
      query: ({ bank_id, account_number_id, year }) => `/finance/transaction/monthly_report_data?bank_id=${bank_id}&account_number_id=${account_number_id}&year=${year}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    uploadMonthlyPdf: builder.mutation({
      query: ({ data }) => ({
        url: '/finance/transaction/monthly_report_data/file',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    downloadMonthlyPdf: builder.mutation({
      query: ({ upload_id, type }) => ({
        url: `/finance/transaction/monthly_report_data/file?upload_id=${upload_id}&file_type=${type}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteMonthlyPdf: builder.mutation({
      query: ({ upload_id, type }) => ({
        url: `/finance/transaction/monthly_report_data/file?upload_id=${upload_id}&file_type=${type}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createTakarekTransactions: builder.mutation({
      query: (data) => ({
        url: '/finance/transaction/create',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getStatisticsTotal: builder.query({
      query: (params = '') => `/finance/statistics/total${params}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getLogisticStatisticsTotal: builder.query({
      query: (data) => `/finance/statistics/logistic/total${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getExternalLogisticStatisticsTable: builder.query({
      query: (data) => `/finance/statistics/logistic/external/table${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getInternalLogisticStatisticsTable: builder.query({
      query: (data) => `/finance/statistics/logistic/internal/table${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getInternalOrderInvoices: builder.query({
      query: (id) => `/finance/statistics/logistic/internal/invoices/${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getExternalOrderInvoices: builder.query({
      query: (id) => `/finance/statistics/logistic/external/invoices/${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    searchInvoiceTransaction: builder.query({
      query: ({ search }) => `/finance/generate/transaction/list?search=${search}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getInvoiceManagementTables: builder.query({
      query: (data = '') => {
        const type = data.includes('archive') ? 'archive' : 'decline';

        return `/finance/invoice_management/${type}${data}`;
      },
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getLogisticSettings: builder.query({
      query: () => '/finance/statistics/logistic/settings',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateLogisticSettings: builder.mutation({
      query: (data) => ({
        url: '/finance/statistics/logistic/settings/update',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getTransactionUpdateData: builder.query({
      query: (id) => `/finance/transaction/update-data/${id}`,
      providesTags: (result, error) => useProvideTags(result, error, ['UPDATE_DATA'])
    }),
  }),
});

export const {
  // Income
  useGetIncomeTableQuery,
  useGetIncomeTotalQuery,
  useUpdateIncomeItemMutation,
  useShowIncomeItemQuery,
  useGetIncomeFileMutation,
  useGenerateNotificationMutation,
  useGetInvoicesListQuery,

  // Invoice
  useGetFinanceInvoiceTableQuery,
  useGetInvoicesTotalQuery,
  useDeleteFinanceInvoiceMutation,
  useUpdateFinanceInvoiceStatusMutation,
  useGetFinanceInvoiceFileMutation,
  useGenerateFinanceInvoiceMutation,
  useUpdatePricesGenerateFinanceInvoiceMutation,

  // Transaction
  useGetTransactionTableQuery,
  useGetTransactionDetailsQuery,
  useUploadTransactionsMutation,
  useGetTransactionUploadsTableQuery,
  useGetTransactionUploadFileMutation,
  useGetTransactionUploadErrorsTableQuery,
  useDeleteTransactionsUploadMutation,
  useUpdateTransactionMutation,
  useToggleTypeTransactionsMutation,
  useToggleStatusTransactionsMutation,
  useUpdateFinanceInvoiceMutation,
  useGetMonthlyTransactionsReportQuery,
  useUploadMonthlyPdfMutation,
  useDownloadMonthlyPdfMutation,
  useDeleteMonthlyPdfMutation,
  useCreateTakarekTransactionsMutation,
  useSearchInvoiceTransactionQuery,
  useGetTransactionUpdateDataQuery,

  // Statistics
  useGetStatisticsTotalQuery,
  useGetLogisticStatisticsTotalQuery,
  useGetExternalLogisticStatisticsTableQuery,
  useGetInternalLogisticStatisticsTableQuery,
  useGetInternalOrderInvoicesQuery,
  useGetExternalOrderInvoicesQuery,
  useGetLogisticSettingsQuery,
  useUpdateLogisticSettingsMutation,

  // Invoice Management
  useGetInvoiceManagementTablesQuery
} = sessionApi;

export default financeSlice.reducer;
