import FileSaver from 'file-saver';
import { useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDownloadInvoiceMutation, useDownloadUserInvoiceMutation, useGetProofOfPaymentMutation } from '../../../store/session';
import { useGetBoardDataQuery, useGetBoardPersonalDataQuery } from '../../../store/slices/invoiceManagementSlice';
import { stringifyLocationSearch } from '../../../utils/locationSearch';
import { INVOICE_TYPES, PAYED_STATUSES } from './constants';
import { INVOICE_PRIORITY } from '../../../constans/logistics';

export const useInvoiceManagement = () => {
  const [search, setSearch] = useState('');
  const [refetchLimit, setRefetchLimit] = useState(false);
  const [invoiceFileDrawerState, setInvoiceFileDrawerState] = useState({
    isOpen: false,
    config: {
      invoiceFile: null,
      invoicePoP: null,
      proformFile: null,
      proformPoP: null
    }
  });
  const [filterState, setFilterState] = useState({
    search: null,
    payment_status: null,
    payment_type: null,
    invoice_type: null
  });

  const { data: boardData, loading: isBoardDataLoading, refetch: refetchBoard } = useGetBoardDataQuery(stringifyLocationSearch({ ...filterState, search }), {
    refetchOnMountOrArgChange: true
  });
  const { data: boardPersonalData, loading: isBoardPersonalDataLoading, refetch: refetchBoardPersonal } = useGetBoardPersonalDataQuery(stringifyLocationSearch({ ...filterState, search }), {
    refetchOnMountOrArgChange: true
  });
  const [downloadInvoice] = useDownloadInvoiceMutation();
  const [getProofOfPayment] = useGetProofOfPaymentMutation();
  const [openInvoiceFile] = useDownloadUserInvoiceMutation();

  const isLoading = isBoardDataLoading;

  const handleTasksByStatus = (statusOptions) => useMemo(() => {
    if (isLoading || !boardData) {
      return {
        groupedInvoices: {},
        count: 0
      };
    }

    const statusArray = Array.isArray(statusOptions) ? statusOptions : [statusOptions];
    let filteredInvoices = boardData.filter(item => statusArray.includes(item.payment_status));

    if (statusArray.some(status => PAYED_STATUSES.includes(status))) {
      filteredInvoices = filteredInvoices.sort((a, b) => {

        if (!a.transaction_id && b.transaction_id) return -1;
        if (a.transaction_id && !b.transaction_id) return 1;

        return new Date(b.created_at) - new Date(a.created_at);
      });
    }

    const groupedInvoices = filteredInvoices.reduce((acc, invoice) => {
      let type = 'Logistics';
      if (invoice.payment_type === 'request') {
        type = 'Regular';
      } else if (invoice.payment_type === 'flight') {
        type = 'Flight';
      } else if (invoice.priority) {
        const priority = INVOICE_PRIORITY.find(i => i.value === invoice.priority);
        type = `Logistics ${priority?.label}`;
      }

      if (!acc[type]) {
        acc[type] = { invoices: [], count: 0 };
      }

      acc[type].invoices.push(invoice);
      acc[type].count += 1;

      return acc;
    }, {});

    return {
      groupedInvoices,
      count: filteredInvoices.length
    };
  }, [boardData, isBoardDataLoading]);

  const handlePersonalTasksByStatus = (statusOptions) => useMemo(() => {
    if (isBoardPersonalDataLoading || !boardPersonalData) {
      return {
        invoices: [],
        count: 0
      };
    }

    const statusArray = Array.isArray(statusOptions) ? statusOptions : [statusOptions];
    const filteredInvoices = boardPersonalData.filter(item => statusArray.includes(item.payment_status));

    return {
      invoices: filteredInvoices,
      count: filteredInvoices.length
    };
  }, [boardPersonalData, isBoardPersonalDataLoading]);

  const getInvoiceTitle = (invoice, with_id = true) => {
    if (!invoice) {
      return '';
    }

    const parentId = invoice.parent_id;
    let { id } = invoice;
    let type = 'Invoice';

    if (parentId) {
      type = `Inv. #${id} + Pr.`;
      id = parentId;
    }

    if (invoice.is_proforma) {
      type = 'Proforma';
    }

    return `${type} ${with_id ? `#${id}` : ''}`;
  };

  const formatInvoice = (invoice) => {
    if (!invoice) {
      return {
        id: '',
        status: '',
        number: '',
        type: {
          icon: '',
          label: '',
          value: '',
        },
        amount: '',
        purpose: '',
        due_date: '',
        is_expired: false,
        is_expired_warning: false,
        sender: '',
        is_proforma: 0,
        invoice_number: '',
        invoice_name: '',
        orders: [],
        can_be_updated: false,
      };
    }

    const type = INVOICE_TYPES.find((invoiceType) => invoiceType.value === invoice.payment_type) || {
      icon: '',
      label: '',
      value: ''
    };

    let { amount } = invoice;
    if (invoice?.parent_invoice?.invoice_sum) {
      amount += invoice.parent_invoice.invoice_sum;
    }

    return {
      id: invoice.id,
      status: invoice.payment_status,
      number: getInvoiceTitle(invoice),
      type,
      amount: `${amount} ${invoice.currency}`,
      purpose: invoice.purpose,
      due_date: invoice.due_date ?? '',
      is_expired: invoice.is_expired ?? false,
      is_expired_warning: invoice.is_expired_warning ?? false,
      sender: invoice.sender_name,
      is_proforma: invoice.is_proforma,
      invoice_number: invoice.invoice_number,
      invoice_name: invoice.invoice_name,
      orders: invoice.orders,
      can_be_updated: (invoice.payment_status === 0 || invoice.payment_status === 6) && invoice.payment_type !== 'request',
    };
  };

  const formatPersonalInvoice = (invoice) => {
    if (!invoice) {
      return {
        id: '',
        status: '',
        number: '',
        amount: '',
        sender: '',
        transaction_id: '',
      };
    }

    return {
      id: invoice.id,
      status: invoice.payment_status,
      number: `Report #${invoice.id}`,
      amount: `${invoice.amount} ${invoice.currency}`,
      sender: invoice.sender_name,
      transaction_id: invoice.transaction_id,
    };
  };

  const handlePreviewPoP = async (id) => {
    const toastId = toast.loading('Loading...');

    await getProofOfPayment(id).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleDownloadInvoice = async ({ id, file_name }) => {
    const toastId = toast.loading('Downloading...');
    const res = await downloadInvoice(id);

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    const fileName = file_name?.replace( /\\/g, '/' ).replace( /.*\//, '' );
    FileSaver.saveAs(res.data, fileName);
    toast.success('Success!', {
      id: toastId,
    });
  };

  const handleOpenInvoice = async ( id ) => {
    const toastId = toast.loading('Downloading...');

    const res = await openInvoiceFile(id);

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    if (res.data instanceof Blob) {
      // Create a URL for the Blob
      const fileURL = URL.createObjectURL(res.data);
      window.open(fileURL, '_blank');
    } else {
      // If res.data is already a URL
      window.open(res.data, '_blank');
    }

    toast.success('Success!', {
      id: toastId,
    });
  };

  const handleDownloadPoP = async ({ id, file_name }) => {
    const toastId = toast.loading('Downloading...');
    const res = await getProofOfPayment(id);

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    const fileName = file_name?.replace( /\\/g, '/' ).replace( /.*\//, '' );
    FileSaver.saveAs(res.data, fileName);
    toast.success('Success!', {
      id: toastId,
    });
  };

  const handleOpenInvoiceFileDrawer = (isOpen = true, config = {}) => {
    setInvoiceFileDrawerState(prev => ({
      ...prev,
      isOpen,
      config: {
        ...prev.config,
        ...config
      }
    }));
  };

  const handleRefetchLimit = () => {
    setRefetchLimit(!refetchLimit);
  };

  return {
    handleTasksByStatus,
    handlePersonalTasksByStatus,
    formatInvoice,
    formatPersonalInvoice,
    handleDownloadInvoice,
    handleDownloadPoP,
    getInvoiceTitle,
    filterState,
    setFilterState,
    handleRefetchLimit,
    refetchLimit,
    setSearch,
    refetchBoard,
    refetchBoardPersonal,
    handlePreviewPoP,
    invoiceFileDrawerState,
    handleOpenInvoice,
    handleOpenInvoiceFileDrawer
  };
};
