import React from 'react';
import {
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Divider,
  Box,
  Typography,
} from '@mui/material';
import { useEditModel } from './useEditModel';
import { CloseIcon } from '../../../../../components/Icons';
import { FormProvider } from 'react-hook-form';
import EditHistory from './History';
import EditPrices from './Prices';
import EditSendStatus from './SendStatus';
import EditPendingStatus from './PendingStatus';
import EditApproveStatus from './ApproveStatus';
import EditOnTheWayStatus from './OnTheWayStatus';
import EditDeliveredStatus from './DeliveredStatus';
import EditDeclineStatus from './DeclineStatus';
import Button from '../../../../../components/Button';
import {StatusCardStyle} from "./styles";

const EditModal = (props) => {
  const { open, onClose, tabValue } = props;
  const {
    orderData,
    isLoading,
    form,
    onSubmit,
    currencyOptions,
    canEditInput
  } = useEditModel(tabValue);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
    >
      {isLoading ? (
        <DialogContent sx={{ alignSelf: 'center' }}>
          <CircularProgress />
        </DialogContent>
      ) : (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Card sx={{ padding: '1rem' }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                      <Typography variant="h5">
                        {`Edit order #${orderData?.id}`}
                      </Typography>

                      <Button
                        size="small"
                        type="submit"
                        title="Update"
                      />
                    </Box>

                    <CloseIcon sx={{ cursor: 'pointer ' }} onClick={onClose} />
                  </Box>

                  <Divider />
                </Grid>

                {form.getValues('history') && (
                  <EditHistory canEditInput={canEditInput} />
                )}

                {form.getValues('pending')?.requester && (
                  <EditPrices currencyOptions={currencyOptions} orderPartType={orderData?.part_type} canEditInput={canEditInput} />
                )}

                {(form.getValues('send') && !['Overhaul_in', 'Repair_in', 'Overhaul_out', 'Repair_out'].includes(orderData?.part_type)) && (
                  <EditSendStatus orderPartType={orderData?.part_type} canEditInput={canEditInput} />
                )}

                {form.getValues('pending')?.requester && (
                  <EditPendingStatus canEditInput={canEditInput} />
                )}

                {form.getValues('approved')?.approver && (
                  <EditApproveStatus canEditInput={canEditInput} />
                )}

                {form.getValues('ordered')?.ordered && (
                  <EditOnTheWayStatus currencyOptions={currencyOptions} orderPartType={orderData?.part_type} canEditInput={canEditInput} />
                )}

                {form.getValues('delivered')?.delivered && (
                  <EditDeliveredStatus canEditInput={canEditInput} />
                )}

                {form.getValues('decline')?.decliner && (
                  <EditDeclineStatus canEditInput={canEditInput} />
                )}

                {(
                  !form.getValues('history')
                  && ['Overhaul_in', 'Repair_in', 'Overhaul_out', 'Repair_out'].includes(orderData?.part_type)
                  && !form.getValues('pending')?.requester
                  && !form.getValues('approved')?.approver
                  && !form.getValues('ordered')?.ordered
                  && !form.getValues('delivered')?.delivered
                  && !form.getValues('decline')?.decliner
                ) && (
                  <Grid item xs={12}>
                    <Card sx={StatusCardStyle}>
                      <Box>
                        <Typography variant="h6">
                          <b>No data to edit</b>
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Card>
          </form>
        </FormProvider>
      )}
    </Dialog>
  );
};

export default EditModal;
