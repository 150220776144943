import {array, boolean, mixed, object, string} from 'yup';

export const defaultValues = {
  number: '',
  general_status: '',
  additional_number: '',
  issuer: '',
  revision: '',
  paragraph: '',
  reference: '',
  ata_id: null,
  urgent: false,
  evaluation_done: 'n/a',
  eo_done: 'n/a',
  description: '',
  notice: '',
  effective_date: '',
  issue_date: '',
  method_of_compliance: '',
  compliance_information: '',
  affected_part_number: '',
  affected_part_serial_number: '',
  affected_part_note: '',
  aircraft: [],
  evaluation_file: {
    content: '',
    name: '',
  },
  eo_file: {
    content: '',
    name: '',
  },
  addition_file: {
    content: '',
    name: '',
  },
  data: [
    {
      aircraft_id: null,
      last_done_date: null,
      last_done_fh: 0,
      last_done_dc: 0,
      interval_days: 0,
      interval_fh: 0,
      interval_fc: 0,
      due_date: null,
      due_fh: 0,
      due_fc: 0,
      status: '',
      remarks: '',
      work_order_task_id: null
    }
  ],
};

export const schema = object().shape({
  number: string()
    .nullable()
    .required()
    .label('Doc. Number'),
  general_status: string()
    .nullable()
    .required()
    .label('Status'),
  issuer: string()
    .nullable()
    .required()
    .label('Issuer'),
  revision: string()
    .nullable()
    .label('Revision'),
  paragraph: string()
    .nullable()
    .label('Paragraph'),
  reference: string()
    .nullable()
    .label('REF DOC / SBs'),
  ata_id: string()
    .nullable()
    .required()
    .label('ATA'),
  urgent: boolean()
    .nullable()
    .required()
    .label('Attention'),
  evaluation_done: string()
    .nullable()
    .label('Evaluation Done'),
  eo_done: string()
    .nullable()
    .label('EO Done'),
  description: string()
    .nullable()
    .required()
    .label('Description'),
  notice: string()
    .nullable()
    .label('Notice'),
  effective_date: string()
    .nullable()
    .required()
    .label('Effective Date'),
  issue_date: string()
    .required()
    .label('Issue Date'),
  method_of_compliance: string()
    .nullable()
    .label('Method of Compliance'),
  compliance_information: string()
    .nullable()
    .label('Compliance Information'),
  affected_part_number: string()
    .nullable()
    .label('Affected Part Number'),
  affected_part_serial_number: string()
    .nullable()
    .label('Affected Part Serial Number'),
  affected_part_note: string()
    .nullable()
    .label('Affected Part Note'),
  eo_file: mixed(),
  evaluation_file: mixed(),
  addition_file: mixed(),
  data: array().of(object({}).shape({
    aircraft_id: string()
      .nullable()
      .label('Aircraft'),
    last_done_date: string()
      .nullable()
      .label('Last Done Date'),
    last_done_fh: string()
      .nullable()
      .label('Last Done FH'),
    last_done_dc: string()
      .nullable()
      .label('Last Done DC'),
    interval_days: string()
      .nullable()
      .label('Interval Days'),
    interval_fh: string()
      .nullable()
      .label('Interval FH'),
    interval_fc: string()
      .nullable()
      .label('Interval FC'),
    due_date: string()
      .nullable()
      .label('Due Date'),
    due_fh: string()
      .nullable()
      .label('Due FH'),
    due_fc: string()
      .nullable()
      .label('Due FC'),
    status: string()
      .nullable()
      .label('Status'),
    remarks: string()
      .nullable()
      .label('Remarks'),
    work_order_task_id: string()
      .nullable()
      .label('Work Order Task'),
  }))
});
