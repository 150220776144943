import React from 'react';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CloseIconStyle } from '../../../Logistics/OrdersDashboard/styles';
import { BoxFlexBetween, StyledCard } from '../../../../assets/styles';
import Button from '../../../../components/Button';
import { GreenButton, YellowButton } from '../../../Logistics/style';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import { useFormContext } from 'react-hook-form';
import { STATUS_DECLINED, STATUS_DRAFT, STATUS_SENT } from '../constants';

const DialogHeader = ({ options, isCreate, onClose }) => {
  const { setValue, getValues, watch } = useFormContext();

  return (
    <Box>
      <BoxFlexBetween>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <Box>
            <Typography variant="h5" noWrap>
              {isCreate ? 'Create' : 'Edit'}
              {' '}
              Aircraft Report
            </Typography>
            <Typography variant="subtitle2" noWrap>
              All fields marked with
              {' '}
              <b style={{ color: 'red' }}>*</b>
              {' '}
              are required
            </Typography>
          </Box>

          {(isCreate || options.reportStatus === STATUS_DRAFT || options.reportStatus === STATUS_DECLINED || options.reportStatus === STATUS_SENT) && (
            <Box sx={{ paddingX: '1rem' }}>
              {options.reportStatus === STATUS_DRAFT && (
                <Button
                  startIcon={<SaveIcon />}
                  sx={{ ...(YellowButton), marginX: '1rem' }}
                  type="submit"
                  onClick={() => setValue('status', STATUS_DRAFT)}
                  title={isCreate ? 'Save as Draft' : 'Update Draft'}
                />
              )}

              <Button
                startIcon={<SendIcon />}
                sx={{ ...(GreenButton), marginX: '1rem' }}
                type="submit"
                onClick={() => setValue('status', STATUS_SENT)}
                title={options.reportStatus === STATUS_DECLINED || options.reportStatus === STATUS_SENT ? 'Update' : 'Sent'}
              />
            </Box>
          )}
        </Box>

        <CloseIcon
          sx={CloseIconStyle}
          onClick={onClose}
        />
      </BoxFlexBetween>
      <Typography variant="subtitle2" noWrap>
        <b style={{ color: 'red' }}>Attention! If card details do not match, please contact OPS.</b>
      </Typography>
      {options.declinePurpose && (
        <StyledCard sx={{ backgroundColor: '#f8c3c3' }}>
          <Typography variant="subtitle2">
            Decline purpose:
          </Typography>
          <Typography>
            {options.declinePurpose}
          </Typography>
        </StyledCard>
      )}
    </Box>

  );
};

export default DialogHeader;
