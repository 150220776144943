import React from 'react';
import {
  Card, Chip, Grid, Typography
} from '@mui/material';
import { StyledCard } from '../../../../../../assets/styles';
import FormGroup from '../../../../../../form/components/FormGroup';
import { Controller, useFormContext } from 'react-hook-form';
import Select from '../../../../../../form/components/Select';
import DatePicker from '../../../../../../form/components/DatePicker';
import { WhiteInput } from '../../../../../Finance/InvoiceManagement/styles';
import moment from 'moment/moment';
import CheckBox from '../../../../../../form/components/Checkbox';
import { setDateValue, validateDatePickerValue } from '../../../../../../utils/setDateValue';
import CreditCards from '../CreditCards/CreditCards';

const FormHeader = ({ options, user }) => {
  const { aircraftOptions } = options;
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <StyledCard sx={{ backgroundColor: '#f5f5f5' }}>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={4}>
          <FormGroup label="Aircraft" required hasError={!!errors.aircraft_id}>
            <Controller
              name="aircraft_id"
              control={control}
              render={({ field }) => (
                <Select
                  sx={WhiteInput}
                  {...field}
                  placeholder="Select Aircraft"
                  options={aircraftOptions}
                  disabled={options.readOnly}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={4}>
          <FormGroup label="Date Start" required hasError={!!errors.date_start}>
            <Controller
              name="date_start"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  sx={WhiteInput}
                  placeholderText="YYYY-MM-DD"
                  dateFormat="yyyy-MM-dd"
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) => {
                    setDateValue(e, setValue, 'date_start', 'YYYY-MM-DD');
                  }}
                  value={validateDatePickerValue(field.value)}
                  disabled={options.readOnly}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={4}>
          <FormGroup label="Date End" hasError={!!errors.date_start}>
            <Controller
              name="date_end"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  sx={WhiteInput}
                  minDate={watch('date_start') ? moment(watch('date_start')).toDate() : null}
                  placeholderText="YYYY-MM-DD"
                  dateFormat="yyyy-MM-dd"
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) => {
                    setDateValue(e, setValue, 'date_end', 'YYYY-MM-DD');
                  }}
                  value={validateDatePickerValue(field.value)}
                  disabled={options.readOnly}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={4}>
          <CreditCards options={options} type="taken_over" />
        </Grid>

        <Grid item xs={12} lg={4}>
          <FormGroup label="Confirm Taken Over" hasError={!!errors.taken_over_confirm}>
            <Controller
              name="taken_over_confirm"
              control={control}
              render={({ field }) => (
                <CheckBox
                  {...field}
                  disabled={options.readOnly}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} lg={4}>
          <FormGroup label="Responsible person:">
            <Typography variant="body1">
              {user?.name}
            </Typography>
          </FormGroup>
        </Grid>

      </Grid>
    </StyledCard>
  );
};

export default FormHeader;
