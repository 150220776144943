import React from 'react';
import {
  Box,
  Card as MuiCard,
  Dialog,
  DialogContent,
  Divider,
  Typography,
  Grid, InputAdornment
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { CloseIconStyle } from '../../../Logistics/OrdersDashboard/styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import Card from 'react-credit-cards';
import FormGroup from '../../../../form/components/FormGroup/FormGroup';
import Input from '../../../../form/components/Input/Input';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {
  formatCreditCardNumber, formatCVC, formatExpirationDate
} from '../../../../utils/input';
import Button from '../../../../components/Button/Button';
import { RedButton } from '../../../Logistics/style';
import DeleteIcon from '@mui/icons-material/Delete';
import { useGetAircraftListQuery } from '../../../../store/session';
import { getOptions, getOptionsWithData } from '../../../../utils/getOptions';
import Select from '../../../../form/components/Select';

const EditCreditCard = (props) => {
  const {
    open,
    onClose,
    accountIndex,
    cardIndex,
    update
  } = props;
  const { watch, control } = useFormContext();
  const { data: AircraftList } = useGetAircraftListQuery();
  const aircraftOptions = getOptionsWithData(AircraftList);

  const handleDeleteCard = (indexV, i) => {
    const accountNumbers = watch(`account_numbers.${indexV}`);

    update(indexV, {
      ...accountNumbers,
      cards: accountNumbers.cards.filter((item, itemIndex) => itemIndex !== i)
    });
  };

  return (
    <Dialog
      fullWidth
      open={!!open}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogContent>
        <MuiCard sx={{ p: '1rem' }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
          >
            <Box>
              <Typography variant="h5" noWrap>
                Edit Credit Card
              </Typography>
            </Box>
            <CloseIcon
              sx={CloseIconStyle}
              onClick={onClose}
            />
          </Box>

          <Divider />

          <Grid container spasing={2}>
            <Grid item xs={12} lg={6} sx={{ alignContent: 'center' }}>
              <Box>
                <Card
                  cvc={watch(`account_numbers.${[accountIndex]}.cards.${[cardIndex]}.cvc`) || ''}
                  expiry={watch(`account_numbers.${[accountIndex]}.cards.${[cardIndex]}.expire_date`) || ''}
                  name={watch(`account_numbers.${[accountIndex]}.cards.${[cardIndex]}.cardholder`) || ''}
                  number={watch(`account_numbers.${[accountIndex]}.cards.${[cardIndex]}.card_number`) || ''}
                />
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormGroup label="Card Number">
                    <Controller
                      name={`account_numbers.${[accountIndex]}.cards.${[cardIndex]}.card_number`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          fullWidth
                          autoComplete="off"
                          placeholder="0000 0000 0000 0000"
                          {...field}
                          onChange={(e) => {
                            field.onChange(formatCreditCardNumber(e.target.value));
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <CreditCardIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <FormGroup label="Expiry Date">
                    <Controller
                      name={`account_numbers.${[accountIndex]}.cards.${[cardIndex]}.expire_date`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          fullWidth
                          placeholder="MM/YY"
                          {...field}
                          onChange={(e) => field.onChange(formatExpirationDate(e.target.value))}
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <FormGroup label="CVV Code">
                    <Controller
                      name={`account_numbers.${[accountIndex]}.cards.${[cardIndex]}.cvc`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          fullWidth
                          placeholder="123"
                          {...field}
                          onChange={(e) => field.onChange(formatCVC(e.target.value))}
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup label="Card Holder">
                    <Controller
                      name={`account_numbers.${[accountIndex]}.cards.${[cardIndex]}.cardholder`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          fullWidth
                          inputProps={{ maxLength: 55 }}
                          {...field}
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup label="Aircraft">
                    <Controller
                      name={`account_numbers.${[accountIndex]}.cards.${[cardIndex]}.aircraft_id`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={[
                            { value: null, label: 'N/A' },
                            ...aircraftOptions
                          ]}
                          placeholder="Aircraft"
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Divider />
              <Button
                sx={{ ...(RedButton), mt: '1rem' }}
                title="Delete Card"
                startIcon={<DeleteIcon />}
                onClick={() => handleDeleteCard(accountIndex, cardIndex)}
              />
            </Grid>
          </Grid>
        </MuiCard>
      </DialogContent>
    </Dialog>
  );
};

export default EditCreditCard;
