import React from 'react';
import { Box } from '@mui/material';
import { datePickerContainer } from './styles';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import Input from '../../Input/Input';
import moment from 'moment';

const FiltrationDate = ({
  value, onChange, inputFormat = 'YYYY-MM-DD', format = 'YYYY-MM-DD', ...props
}) => {
  const parsedValue = moment(value, inputFormat).isValid() ? moment(value, inputFormat) : null;

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
      }}
      sx={datePickerContainer}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          inputFormat={inputFormat}
          format={format}
          size="small"
          value={parsedValue}
          onChange={onChange}
          {...props}
          slotProps={{ textField: { variant: 'outlined', size: 'small', placeholder: '...' } }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default FiltrationDate;
