import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema, defaultValues } from './schema';
import { useGetAircraftListQuery, useGetCurrencyListQuery } from '../../../../store/session';
import { getOptionsWithData, getOptions } from '../../../../utils/getOptions';
import {
  useGetCreditCardsQuery,
  useStoreAircraftReportMutation,
  useGetAircraftReportQuery,
  useUpdateAircraftReportMutation
} from '../../../../store/slices/aircraftReportsSlice';
import toast from 'react-hot-toast';
import { useHandleQueryFormError } from '../../../../utils/hooks/useHandleQueryFormError';
import { useEffect, useState } from 'react';
import { STATUS_DECLINED, STATUS_DRAFT } from '../constants';
import { openFileInNewTab } from '../../../../utils/utils';

export const useAircraftReportDialog = (reportID, onClose) => {
  const isCreate = !reportID;
  const handleError = useHandleQueryFormError();
  const [fileNames, setFileNames] = useState([]);
  const [reportStatus, setReportStatus] = useState(STATUS_DRAFT);
  const [declinePurpose, setDeclinePurpose] = useState(null);

  const { data: aircraft, isLoading: AircraftLoading } = useGetAircraftListQuery();
  const aircraftOptions = getOptionsWithData(aircraft);

  const { data: currencies, isLoading: CurrenciesLoading } = useGetCurrencyListQuery();
  const currencyOptions = getOptions(currencies);

  const { data: reportData, isLoading: ReportLoading } = useGetAircraftReportQuery(reportID, {
    refetchOnMountOrArgChange: true,
    skip: !reportID
  });

  const isLoading = AircraftLoading || CurrenciesLoading || ReportLoading;
  const [storeAircraftReport, { isLoading: isSubmitting }] = useStoreAircraftReportMutation();
  const [updateAircraftReport, { isLoading: isUpdating }] = useUpdateAircraftReportMutation();

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  useEffect(() => {
    if (reportData) {
      form.reset(reportData);

      setReportStatus(reportData.status);
      if (reportData.status === STATUS_DECLINED) {
        setDeclinePurpose(reportData.decline_purpose);
      }

      setFileNames(
        reportData?.transactions?.map((row, index) => ({
          id: index,
          name: row?.file?.name || '',
        }))
      );
    }
  }, [reportData, ReportLoading]);

  const { data: creditCards } = useGetCreditCardsQuery(form.watch('aircraft_id'), {
    refetchOnMountOrArgChange: true,
    skip: !form.watch('aircraft_id')
  });
  const creditCardsOptions = getOptions(creditCards);

  const onSubmit = async (data) => {
    if (isSubmitting || isUpdating) return;
    const toastId = toast.loading('Loading...');

    let res;
    if (reportID) {
      res = await updateAircraftReport({ id: reportID, data });
    } else {
      res = await storeAircraftReport(data);
    }

    if (res.error) {
      handleError(res.error, form.setError, form.getValues, toastId);

      return;
    }

    toast.success('Success!', {
      id: toastId
    });

    if (!reportID) form.reset(defaultValues);
    onClose();
  };

  const handleOpenFile = (content, mimeType) => {
    openFileInNewTab(content, mimeType);
  };

  return {
    isCreate,
    form,
    onSubmit,
    isLoading,
    options: {
      aircraftOptions,
      creditCardsOptions,
      currencyOptions,
      fileNames,
      setFileNames,
      reportStatus,
      declinePurpose,
      setReportStatus,
      hideHeader: true,
      readOnly: false,
    },
    actions: {
      handleOpenFile
    }
  };
};
