export const OPENED = 'opened';
export const SUBMITTED = 'submitted';
export const RETURNED = 'returned';
export const ACCEPTED = 'accepted';
export const PAID = 'paid';
export const DECLINED = 'declined';
export const DRAFT = 'draft';
export const PENALTY = 'penalty';
export const BONUS = 'bonus';
export const COMPENSATION_STATUSES = [
  {
    value: PENALTY,
    label: 'Penalty'
  },
  {
    value: BONUS,
    label: 'Bonus'
  },
];
export const STATUSES = [
  {
    value: OPENED,
    label: 'Opened'
  },
  {
    value: SUBMITTED,
    label: 'Submited'
  },
  {
    value: RETURNED,
    label: 'Returned'
  },
  {
    value: ACCEPTED,
    label: 'Accepted',
  },
  {
    value: PAID,
    label: 'Paid'
  },
  {
    value: DECLINED,
    label: 'Declined'
  },
  {
    value: DRAFT,
    label: 'Draft'
  }
];

export const TRANSFER_DETAILS = [
  {
    value: 'duty',
    label: 'Duty'
  },
  {
    value: 'nutrition',
    label: 'Nutrition'
  },
  {
    value: 'travel',
    label: 'Travel'
  },
  {
    value: 'other',
    label: 'Other'
  }
];

export const getFormatStatus = (report_status, defaultLabel = 'Empty') => STATUSES.find(i => i.value === report_status)?.label || defaultLabel;
export const getFormatCompensationsStatus = (report_status, defaultLabel = 'Empty') => (report_status ? (report_status === PAID ? 'Completed' : 'In Progress') : defaultLabel);
