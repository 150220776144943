import React, { useEffect } from 'react';
import {
  gridColumnVisibilityModelSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';

const ColumnGroupRoot = styled('div')({
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
});

const ColumnGroupTitle = styled('span')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const CollapsibleHeaderGroup = ({
  groupId, headerName, fields, collapseByDefault = false
}) => {
  const apiRef = useGridApiContext();
  const columnVisibilityModel = gridColumnVisibilityModelSelector(apiRef);

  // Initialize column visibility based on `collapseByDefault`
  useEffect(() => {
    const newModel = { ...columnVisibilityModel };
    let shouldUpdate = false;

    fields.forEach((field) => {
      if (collapseByDefault && newModel[field] !== false) {
        newModel[field] = false; // Collapse by default
        shouldUpdate = true;
      } else if (!collapseByDefault && newModel[field] !== true) {
        newModel[field] = true; // Expand by default
        shouldUpdate = true;
      }
    });

    if (shouldUpdate) {
      apiRef.current.setColumnVisibilityModel(newModel);
    }
  }, [collapseByDefault]);

  if (!groupId) {
    return null;
  }

  // Determine if the group is collapsed
  const isGroupCollapsed = fields.every(
    (field) => columnVisibilityModel[field] === false,
  );

  // Toggle visibility of the group
  const handleToggleGroup = () => {
    const newModel = { ...columnVisibilityModel };
    const shouldCollapse = !isGroupCollapsed;

    fields.forEach((field) => {
      newModel[field] = !shouldCollapse;
    });

    apiRef.current.setColumnVisibilityModel(newModel);
  };

  return (
    <ColumnGroupRoot>
      <ColumnGroupTitle>
        <Typography variant="h5">{headerName ?? groupId}</Typography>
      </ColumnGroupTitle>
      <IconButton
        sx={{ ml: 0.5 }}
        onClick={handleToggleGroup}
      >
        {isGroupCollapsed ? (
          <KeyboardArrowRightIcon fontSize="small" />
        ) : (
          <KeyboardArrowDownIcon fontSize="small" />
        )}
      </IconButton>
    </ColumnGroupRoot>
  );
};

export default CollapsibleHeaderGroup;
