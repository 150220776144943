import { object } from 'yup';

export const defaultValues = {
  aircraft_id: null,
  date_start: '',
  date_end: '',
  credit_card_id: null,
  taken_over_confirm: false,
  cash_balances: [
    {
      amount: '',
      currency_id: null,
      cash_received_date: '',
    }
  ],
  transactions: [
    {
      date: '',
      type: '',
      amount: '',
      currency_id: null,
      notes: '',
      transactions: [],
      files: []
    }
  ],
  handed_over_date: '',
  handed_over_card_id: null,
  handed_over_confirm: false,
  handed_over_cash_balances: [
    {
      amount: '',
      currency_id: null,
      cash_received_date: '',
    }
  ]
};

export const schema = object().shape({

});
