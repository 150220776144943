import {
  Box,
  Card, CardContent,
  CircularProgress,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import React, { useRef, useState, useEffect } from 'react';
import Button from '../../../../../../../components/Button';
import { useSearchSupplierQuery } from '../../../../../../../store/slices/logisticsSlice';
import { headerBtn } from '../../../../../../Invoices/style';
import FormGroup from '../../../../../../../form/components/FormGroup';
import { Controller, useForm } from 'react-hook-form';
import Select from '../../../../../../../form/components/Select';
import {
  INVOICE_PRIORITY
} from '../../../../../../../constans/logistics';
import FieldError from '../../../../../../../form/components/FieldError';
import {
  block as currentBlock,
  file,
  fileName,
  label
} from '../../../../../../Logistics/OrdersDashboard/components/forms/approved/invoices/style';
import Autocomplete from '../../../../../../../form/components/Autocomplete';
import Input from '../../../../../../../form/components/Input';
import ServerAutocomplete from '../../../../../../../form/components/ServerAutocomplete';
import DatePicker from '../../../../../../../form/components/DatePicker';
import { setDateValue, validateDatePickerValue } from '../../../../../../../utils/setDateValue';
import Upload from '../../../../../../../components/Upload';
import { useGetInvoiceDetailsMutation, useUpdateInvoiceRequestMutation } from '../../../../../../../store/slices/invoiceManagementSlice';
import { toast } from 'react-hot-toast';
import { defaultValues, schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { getOptions } from '../../../../../../../utils/getOptions';
import { useGetCurrencyQuery } from '../../../../../../../store/session';
import { Filters, GreenButton } from '../../../../../../Logistics/style';

const UpdateInvoice = ({ onClose, updateInvoiceId }) => {
  const [getInvoice, { data: invoice, isLoading: invoiceLoading }] = useGetInvoiceDetailsMutation();
  const [nameFile, setNameFile] = useState();
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [supplier, setSupplier] = useState(null);
  const refForm = useRef(null);
  const block = { ...currentBlock, mb: '15px' };
  const { data: currency } = useGetCurrencyQuery(undefined, {
    refetchOnMountOrArgChange: true
  });
  const currencyOptions = getOptions(currency);
  const [updateInvoice] = useUpdateInvoiceRequestMutation();

  const {
    handleSubmit,
    setValue,
    trigger,
    reset,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  useEffect(() => {
    if (updateInvoiceId) {
      getInvoice({ id: updateInvoiceId });
    }
  }, [updateInvoiceId]);

  useEffect(() => {
    if (invoice && !invoiceLoading) {
      setPriorityOptions(invoice.priority === 10 ? INVOICE_PRIORITY : INVOICE_PRIORITY.filter((i) => i.value !== 10));
      setValue('priority', invoice.priority);
      setValue('description', invoice.description);
      setValue('invoice_number', invoice.invoice_number);
      setValue('invoice_sum', invoice.invoice_sum);
      setValue('due_payment_date', invoice.due_payment_date);
      setNameFile(invoice.invoice_path.split('/').pop());
      const findCurrency = currencyOptions?.find(item => parseInt(item.value) === invoice.currency_id);
      if (findCurrency) {
        setValue('currency', findCurrency);
        setValue('currency_id', findCurrency.value);
        trigger('currency', findCurrency);
      }
      const supplierData = invoice.invoice_supplier_data;
      if (supplierData) {
        setSupplier({
          data: {
            actual_address: supplierData.actual_address,
            // currency: "EUR",
            email: supplierData.email,
            id: supplierData.id,
            type: supplierData.type,
            vendor_balance: supplierData.vendor_balance,
          },
          label: supplierData.name,
          value: supplierData.id,
        });
        setValue('invoice_name', supplierData.name);
      }
    }
  }, [invoice, invoiceLoading]);

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const formData = new FormData();

    formData.append('priority', data.priority);
    formData.append('description', data.description);
    formData.append('invoice_number', data.invoice_number);
    if (data.file) formData.append('file', data.file);
    formData.append('invoice_sum', data.invoice_sum);
    formData.append('currency_id', data.currency_id);
    formData.append('due_payment_date', data.due_payment_date);
    if (supplier) {
      formData.append('supplier_id', supplier?.data?.id);
      formData.append('invoice_name', data.invoice_name);
    }

    const res = await updateInvoice({ id: updateInvoiceId, data: formData });

    if (res.error) {
      toast.error(res.error.data?.message, {
        id: toastId,
      });

      return;
    }

    toast.success('The invoice has been update!', {
      id: toastId,
    });

    reset();
    document.getElementById('file-upload').value = '';
    setNameFile(null);
    onClose();
  };

  const uploadFile = (e) => {
    setNameFile(e.target.files[0].name);

    setValue('file', e.target.files[0]);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={!!updateInvoiceId}
      onClose={onClose}
    >
      {invoiceLoading ? (
        <DialogContent sx={{ alignSelf: 'center' }}>
          <CircularProgress />
        </DialogContent>
      ) : (
        <Grid container spacing={2}>
          <Grid item sx={{ backgroundColor: '#e9f1fe26' }}>
            <DialogContent>
              <Card
                sx={{ padding: '1rem' }}
              >
                <Box sx={{ headerBtn, display: 'flex', justifyContent: 'space-between' }}>
                  <Box>
                    <Typography variant="h5" noWrap>
                      Update Invoice Request
                    </Typography>
                  </Box>
                </Box>
                <Divider />
              </Card>

              <Grid item xs={12}>
                <Card sx={{ mt: 0, border: 'none' }}>
                  <CardContent sx={{ mt: 0 }}>
                    <form onSubmit={handleSubmit(onSubmit)} ref={refForm}>
                      <Grid
                        container
                        spacing={2}
                        sx={{ padding: '0 12px' }}
                      >
                        <Grid item xs={12} sx={{ ...block, position: 'relative' }}>
                          <FormGroup sx={label} label="Description" required hasError={!!errors.description}>
                            <Controller
                              name="description"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  rows={8}
                                  multiline
                                  variant="filled"
                                  sx={{ width: '100%' }}
                                />
                              )}
                            />
                            <FieldError error={errors.description} />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={6} sx={{ ...block, position: 'relative' }}>
                          <FormGroup sx={label} label="Invoice Number" required hasError={!!errors.invoice_number}>
                            <Controller
                              name="invoice_number"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  sx={{ width: '100%' }}
                                />
                              )}
                            />
                            <FieldError error={errors.invoice_number} />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={6} sx={{ ...block, position: 'relative' }}>
                          <FormGroup sx={label} label="Supplier" required hasError={!!errors.invoice_name}>
                            <Controller
                              name="invoice_name"
                              control={control}
                              render={({ field }) => (
                                <ServerAutocomplete
                                  value={supplier}
                                  withData
                                  placeholder="Search suppliers by name or email"
                                  searchQueryFn={useSearchSupplierQuery}
                                  handleSelect={(value) => {
                                    field.onChange(value?.label);
                                    setSupplier(value);
                                  }}
                                />
                              )}
                            />
                            <FieldError error={errors.invoice_name} />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={6} sx={{ ...block, position: 'relative' }}>
                          <FormGroup sx={label} label="Deadline Payment" required hasError={!!errors.due_payment_date}>
                            <Controller
                              name="due_payment_date"
                              control={control}
                              render={({ field }) => (
                                <DatePicker
                                  placeholderText="YYYY-MM-DD"
                                  dateFormat="yyyy-MM-dd"
                                  showMonthDropdown
                                  showYearDropdown
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    setDateValue(e, setValue, 'due_payment_date', 'YYYY-MM-DD');
                                  }}
                                  value={validateDatePickerValue(field.value)}
                                />
                              )}
                            />
                            <FieldError error={errors.due_payment_date} />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={6} sx={{ ...block, position: 'relative' }}>
                          <FormGroup sx={label} label="SUM" required hasError={!!errors.invoice_sum}>
                            <Controller
                              name="invoice_sum"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  type="number"
                                  sx={{ width: '100%' }}
                                />
                              )}
                            />
                            <FieldError error={errors.invoice_sum} />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={6} sx={{ ...block, position: 'relative' }}>
                          <FormGroup sx={label} required label="Currency" hasError={!!errors.currency}>
                            <Controller
                              name="currency"
                              control={control}
                              render={({ field }) => (
                                <Autocomplete
                                  {...field}
                                  value={field.value ? currencyOptions?.find((item) => item.value === field?.value?.value) : null}
                                  options={currencyOptions}
                                  size="small"
                                  placeholder="Please select a currency"
                                  onChange={(e, value ) => {
                                    field.onChange(value);
                                    setValue('currency', value || null);
                                    setValue('currency_id', value?.value || null);
                                    trigger('currency', value);
                                  }}
                                />
                              )}
                            />
                            <FieldError error={errors.currency} />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={6}>
                          <FormGroup label="Priority" required hasError={!!errors.priority}>
                            <Controller
                              name="priority"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  options={priorityOptions}
                                  placeholder="Priority"
                                  {...field}
                                />
                              )}
                            />
                            <FieldError error={errors.priority} />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12} sx={{ ...file, position: 'relative' }}>
                          <FormGroup sx={label} required label="File" hasError={!!errors.file}>
                            <Controller
                              name="file"
                              control={control}
                              render={({ field }) => (
                                <Upload
                                  {...field}
                                  accept=".doc,.docx,image/*,.pdf"
                                  handleUpload={(e) => {
                                    field.onChange(e);
                                    uploadFile(e);
                                  }}
                                  title="Update file"
                                />
                              )}
                            />
                            <FieldError error={errors.file} />
                          </FormGroup>
                          <Typography sx={fileName}>{nameFile}</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ padding: '1rem 0rem 0rem 0rem' }}>
                          <Divider />
                          <Box sx={{ ...(Filters), padding: '0px', justifyContent: 'space-between' }}>
                            <Button title="Cancel" onClick={onClose} />
                            <Button sx={GreenButton} title="Update" type="submit" />
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            </DialogContent>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

export default UpdateInvoice;
