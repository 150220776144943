import toast from 'react-hot-toast';
import {
  useDeleteADFileMutation,
  useGetADFilesQuery,
  useOpenADFileMutation,
  useUploadADFileMutation
} from '../../../../../../../store/slices/airworthinessDirectiveSlice';
import { usePermissions } from '../../../../../../../utils/hooks/usePermissions';

export const useADFiles = ({ currentAD, typeId }) => {
  const canEdit = usePermissions(['ad_write']);
  const [openFile, { isLoading: fileLoading }] = useOpenADFileMutation();
  const [deleteFile, { isLoading: deleteLoading }] = useDeleteADFileMutation();
  const [uploadFiles, { isLoading: uploadLoading }] = useUploadADFileMutation();
  const { data: files, isLoading: filesLoading, refetch } = useGetADFilesQuery(
    { id: currentAD, file_type_id: typeId },
    { refetchOnMountOrArgChange: true }
  );

  const loading = uploadLoading || filesLoading || fileLoading || deleteLoading;

  const upload = async (e) => {
    if (!e.target.files[0]) return;
    const toastId = toast.loading('Uploading...');

    const formData = new FormData();
    const uploadedFiles = e.target.files;
    Object.keys(uploadedFiles)?.forEach((key) => {
      formData.append('upload_files[]', uploadedFiles[key]);
    });
    formData.append('file_type_id', typeId);

    const res = await uploadFiles({ id: currentAD, data: formData });
    e.target.value = null;

    if (res.error) {
      toast.error(res.error.data?.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    await refetch();
    toast.success('Successfully upload files!', {
      id: toastId
    });
  };

  const handleOpen = async (fileId) => {
    const toastId = toast.loading('Opening...');

    await openFile({ id: fileId }).then((res) => {
      if (!res?.errros) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);

        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleDelete = async (fileId) => {
    const toastId = toast.loading('Deleting...');

    await deleteFile({ id: fileId }).then((res) => {
      if (!res?.errros) {
        toast.success('Success!', {
          id: toastId,
        });
        refetch();
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  return {
    upload,
    loading,
    files,
    canEdit,
    handleOpen,
    fileLoading,
    handleDelete,
    deleteLoading
  };
};
