import React from 'react';
import { SvgIcon } from '@mui/material';

const VisaIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 512 512">
    <g id="shape_1_3_">
      <g id="shape_1">
        <g>
          <path
            d="M211.328,184.445l-23.465,144.208h37.542l23.468-144.208 H211.328z M156.276,184.445l-35.794,99.185l-4.234-21.358l0.003,0.007l-0.933-4.787c-4.332-9.336-14.365-27.08-33.31-42.223 c-5.601-4.476-11.247-8.296-16.705-11.559l32.531,124.943h39.116l59.733-144.208H156.276z M302.797,224.48 c0-16.304,36.563-14.209,52.629-5.356l5.357-30.972c0,0-16.534-6.288-33.768-6.288c-18.632,0-62.875,8.148-62.875,47.739 c0,37.26,51.928,37.723,51.928,57.285c0,19.562-46.574,16.066-61.944,3.726l-5.586,32.373c0,0,16.763,8.148,42.382,8.148 c25.616,0,64.272-13.271,64.272-49.37C355.192,244.272,302.797,240.78,302.797,224.48z M455.997,184.445h-30.185 c-13.938,0-17.332,10.747-17.332,10.747l-55.988,133.461h39.131l7.828-21.419h47.728l4.403,21.419h34.472L455.997,184.445z M410.27,277.641l19.728-53.966l11.098,53.966H410.27z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#005BAC"
          />
        </g>
      </g>
    </g>
    <g id="shape_1_2_">
      <g id="shape_1_1_">
        <g>
          <path
            d="M104.132,198.022c0,0-1.554-13.015-18.144-13.015H25.715 l-0.706,2.446c0,0,28.972,5.906,56.767,28.033c26.562,21.148,35.227,47.51,35.227,47.51L104.132,198.022z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#F6AC1D"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default VisaIcon;
