import { Grid } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import FormGroup from '../../../../../../../form/components/FormGroup';
import Input from '../../../../../../../form/components/Input';
import ServerAutocomplete from '../../../../../../../form/components/ServerAutocomplete';
import { useGetStoragePartsAutocompleteQuery } from '../../../../../../../store/session';

const AutocompletePartOffForm = (props) => {
  const {
    errors,
    control,
    handleSelectAircraftPart,
    currentPartIDOff,
    currentPartNameOff,
    currentPartSNOff,
    currentParts,
    getValues
  } = props;

  return (
    <>
      <Grid item xs={6}>
        <FormGroup label="Aircraft Body - Part ID" required={(getValues('part_type') !== 'consumable')} hasError={errors?.part_aircraft_body_id}>
          <Controller
            name="part_aircraft_body_id"
            control={control}
            render={({ field }) => (
              <ServerAutocomplete
                field={field}
                value={currentPartIDOff}
                disabled={!!currentParts?.part_off_text}
                withData
                placeholder="Search Aircraft Body Parts"
                searchQueryFn={useGetStoragePartsAutocompleteQuery}
                queryParams={{ is_aircraft_body: true }}
                handleSelect={(item) => handleSelectAircraftPart(item, 'part_id')}
                getOptionLabel={(option) => (typeof option === 'string' ? option : `${option?.value} - ${option?.label} - ${option?.data?.serial_number}`) || ''}
              />
            )}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={6}>
        <FormGroup label="Aircraft Body - Part name" required={(getValues('part_type') !== 'consumable')} hasError={errors?.part_aircraft_body_id}>
          <Controller
            name="part_aircraft_body_id"
            control={control}
            render={({ field }) => (
              <ServerAutocomplete
                field={field}
                value={currentPartNameOff}
                disabled={!!currentParts?.part_off_text}
                withData
                placeholder="Search Aircraft Body Parts"
                searchQueryFn={useGetStoragePartsAutocompleteQuery}
                queryParams={{ is_aircraft_body: true, search_row: 'part_name' }}
                handleSelect={(item) => handleSelectAircraftPart(item, 'part_name')}
                getOptionLabel={(option) => (typeof option === 'string' ? option : `${option?.value} - ${option?.label} - ${option?.data?.serial_number}`) || ''}
              />
            )}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={6}>
        <FormGroup label="Aircraft Body - Serial Number" required={(getValues('part_type') !== 'consumable')} hasError={errors?.part_aircraft_body_id}>
          <Controller
            name="part_aircraft_body_id"
            control={control}
            render={({ field }) => (
              <ServerAutocomplete
                field={field}
                value={currentPartSNOff}
                disabled={!!currentParts?.part_off_text}
                withData
                placeholder="Search Aircraft Body Parts"
                searchQueryFn={useGetStoragePartsAutocompleteQuery}
                queryParams={{ is_aircraft_body: true, search_row: 'serial_number' }}
                handleSelect={(item) => handleSelectAircraftPart(item, 'serial_number')}
                getOptionLabel={(option) => (typeof option === 'string' ? option : `${option?.value} - ${option?.label} - ${option?.data?.serial_number}`) || ''}
              />
            )}
          />
        </FormGroup>
      </Grid>

      {(currentPartIDOff || currentPartNameOff || currentPartSNOff || currentParts) && (
      <Grid container spacing={6} sx={{ padding: '0px  24px' }}>
        <Grid item xs={4}>
          <FormGroup label="Part Name" required={(getValues('part_type') !== 'consumable')} hasError={!!errors?.part_off_name}>
            <Controller
              control={control}
              name="part_off_name"
              render={({ field }) => (
                <Input
                  {...field}
                  disabled={!!currentPartIDOff || !!currentPartNameOff || !!currentPartSNOff || !!currentParts?.part_off_text}
                  placeholder="Enter Part Name"
                  sx={{ width: '100%' }}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={4}>
          <FormGroup label="Part Number" required={(getValues('part_type') !== 'consumable')} hasError={!!errors?.part_off_number}>
            <Controller
              control={control}
              name="part_off_number"
              render={({ field }) => (
                <Input
                  {...field}
                  disabled={!!currentPartIDOff || !!currentPartNameOff || !!currentPartSNOff || !!currentParts?.part_off_text}
                  placeholder="Enter Part Number"
                  sx={{ width: '100%' }}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={4}>
          <FormGroup label="Serial Number" required={(getValues('part_type') !== 'consumable')} hasError={!!errors?.serial_off_number}>
            <Controller
              control={control}
              name="serial_off_number"
              render={({ field }) => (
                <Input
                  {...field}
                  disabled={!!currentPartIDOff || !!currentPartNameOff || !!currentPartSNOff || !!currentParts?.part_off_text}
                  placeholder="Enter Serial Number"
                  sx={{ width: '100%' }}
                />
              )}
            />
          </FormGroup>
        </Grid>
      </Grid>
      )}
    </>
  );
};

export default AutocompletePartOffForm;
