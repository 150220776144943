import {
  object,
  string,
} from 'yup';

export const defaultValues = {
  send: {
    remarks: '',
  },
};

export const schema = object().shape({
  send: object().shape({
    remarks: string().nullable(),
  })
});
