import {
  array, object, string
} from 'yup';

export const defaultValues = {
  update_draft: false,
  report_status: '',
  date_start: null,
  date_end: null,
  rows: [
    {
      date: null,
      transfer_details: '',
      amount: 0,
      rate: 0,
      rate_currency_id: null,
      file: null
    }
  ],

};

export const schema = object().shape({
  date_start: string().required('Date Start is required'),
  date_end: string().required('Date End is required'),
  rows: array().of(object({}).shape({
    date: string().required('Date is required'),
    transfer_details: string().required('Transfer Details is required'),
    amount: string()
      .required('Amount is required')
      .test('not-zero', 'Amount cannot be zero', (value) => parseFloat(value) > 0),
    rate: string()
      .required('Quantity is required')
      .test('is-integer', 'Quantity must be an integer', (value) => /^\d+$/.test(value))
      .test('not-zero', 'Quantity cannot be zero', (value) => parseFloat(value) > 0),
    rate_currency_id: string().required('Currency is required'),
    // file: mixed().when('transfer_details', {
    //   is: 'other',
    //   then: mixed()
    //     .required('File is required when Transfer Details is "Other"'),
    //   otherwise: mixed(),
    // }),
  }))
});
