import { useParams } from 'react-router-dom';
import {
  useGetOrderEditInfoQuery,
  useUpdateEditOrderInfoMutation
} from '../../../../../store/slices/logisticsSlice';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { defaultValues, schema } from './schema';
import { useEffect } from 'react';
import { getOptions } from '../../../../../utils/getOptions';
import { useGetCurrencyListQuery } from '../../../../../store/session';
import { toast } from 'react-hot-toast';
import { usePermissions } from '../../../../../utils/hooks/usePermissions';

export const useEditModel = (tabValue) => {
  const canAllEditPermission = usePermissions('edit_order_info_access');
  const canEmptyEditPermission = usePermissions('edit_supplement_order_info_access');
  const params = useParams();
  const [updateOrderInfo] = useUpdateEditOrderInfoMutation();
  const { data: orderData, isLoading: orderDataInfoEditLoading } = useGetOrderEditInfoQuery({ id: params?.id, is_internal: tabValue === 'delivery' }, {
    refetchOnMountOrArgChange: true,
    skip: !params?.id
  });

  const { data: currencyList, isLoading: isCurrencyLoading } = useGetCurrencyListQuery();
  const currencyOptions = getOptions(currencyList);

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (orderData) {
      form.reset(orderData);
    }
  }, [orderData, orderDataInfoEditLoading]);

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    if (data?.pending?.prices?.new) {
      data.new_price = data.pending.prices.new;
    }
    if (tabValue === 'delivery') {
      data.is_internal = true;
    }
    const res = await updateOrderInfo({ id: params?.id, data });

    if (res?.error) {
      toast.error(res?.error.data.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }

    toast.success('Successfully!', {
      id: toastId,
    });
  };

  const isLoading = orderDataInfoEditLoading || isCurrencyLoading;

  const getNestedValue = (obj, path) => path.split('.').reduce((acc, key) => {
    if (acc === undefined || acc === null) return undefined;
    const arrayMatch = key.match(/(\w+)\[(\d+)\]/);
    if (arrayMatch) {
      const [, arrayKey, index] = arrayMatch;

      return acc[arrayKey] ? acc[arrayKey][parseInt(index, 10)] : undefined;
    }

    return acc[key];
  }, obj);

  const isEmpty = (value) => (
    value === undefined
      || value === null
      || (typeof value === 'string' && value.trim() === '')
      || (Array.isArray(value) && value.length === 0)
      || (typeof value === 'object' && Object.keys(value).length === 0)
  );
  const canEditInput = (inputName) => {
    if (canAllEditPermission) {
      return true;
    }

    return isEmpty(getNestedValue(orderData, inputName)) && canEmptyEditPermission;
  };

  return {
    orderData,
    isLoading,
    form,
    currencyOptions,
    onSubmit,
    canEditInput
  };
};
