import React from 'react';
import {
  Box, Card, Dialog, DialogContent, Divider, Typography, Grid, TextField, InputAdornment
} from '@mui/material';
import { useCompensation } from './useCompensation';
import { btnS, headerBtn } from '../../../Invoices/style';
import CloseIcon from '@mui/icons-material/Close';
import { CloseIconStyle } from '../../../Logistics/OrdersDashboard/styles';
import FormGroup from '../../../../form/components/FormGroup/FormGroup';
import { Controller } from 'react-hook-form';
import { WhiteInput } from '../../InvoiceManagement/styles';
import Button from '../../../../components/Button/Button';
import { GreenButton, TextWithIcon } from '../../../Logistics/style';
import SendIcon from '@mui/icons-material/Send';
import Select from '../../../../form/components/Select';
import { Plus, Rename } from 'mdi-material-ui';
import { fileName, label } from '../../../DocumentsAndInvoices/Documents/DocumentsForm/style';
import Upload from '../../../../components/Upload';
import FieldError from '../../../../form/components/FieldError';
import { RowStyle } from '../../../MyProfile/PersonalReportsTab/styles';
import { defaultValues } from './schema';
import { StyledDelete } from '../../../../components/EditableTable/styles';
import { numberFormatValidate } from '../../../../utils/number';
import { COMPENSATION_STATUSES } from '../../../MyProfile/PersonalReportsTab/constants';
import Autocomplete from '../../../../form/components/Autocomplete';

const CompensationForm = ({
  open, onClose, id, crewId
}) => {
  const {
    update,
    onSubmit,
    handleUploadFile,
    handleResetFile,
    handleCloseOnSubmit,
    fileNames,
    reports,
    form: {
      watch,
      handleSubmit,
      control,
      setValue,
      getValues,
      formState: { errors }
    },
    rowFields,
    rowAppend,
    rowRemove
  } = useCompensation(onClose, id, crewId);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={handleCloseOnSubmit}
      sx={{
        '& .MuiDialog-paper': {
          height: '90vh',
          maxHeight: '90vh',
        },
      }}
    >
      <DialogContent sx={{ overflowY: 'scroll' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card sx={{ padding: 4 }}>
            <Box sx={{ ...headerBtn, display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={TextWithIcon}>
                <Box>
                  <Typography variant="h5" noWrap>
                    {update ? 'Edit' : 'Create'}
                    {' '}
                    {update ? 'Compensation' : 'Compensations'}
                  </Typography>

                  <Typography variant="subtitle2" noWrap>
                    All fields marked with * are required
                  </Typography>
                </Box>

                <Button
                  sx={{ ...(GreenButton), marginX: '20px' }}
                  title={update ? 'Update' : 'Send'}
                  type="submit"
                  startIcon={<SendIcon />}
                />
              </Box>

              <CloseIcon
                sx={CloseIconStyle}
                onClick={() => handleCloseOnSubmit(update)}
              />
            </Box>

            <Divider />

            {!update && (
              <Grid item xs={6} md={4}>
                <FormGroup sx={label} label="Report (ID)" hasError={!!errors.report_id}>
                  <Controller
                    name="report_id"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        value={field.value ? reports?.filter((item) => item.value === field?.value)[0] : null}
                        options={reports}
                        size="small"
                        placeholder="Search Report"
                        onChange={(e, value) => {
                          field.onChange(e);
                          setValue('report_id', value?.value || '');
                        }}
                      />
                    )}
                  />
                  <FieldError error={errors.report_id} />
                </FormGroup>
              </Grid>
            )}

            <Box sx={{ mt: 4 }}>
              {rowFields.map((row, index) => (
                <Card sx={{ ...RowStyle }}>
                  {!update && (
                    <>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="h5">{`Row #${index + 1}`}</Typography>
                        {index !== 0 && <StyledDelete onClick={() => rowRemove(index)} />}
                      </Box>
                      <Divider />
                    </>
                  )}
                  <Grid container spacing={6} key={row.id}>
                    <Grid item xs={6} md={2}>
                      <FormGroup label="Amount" required hasError={!!errors?.rows?.[index]?.amount}>
                        <Controller
                          name={`rows.${[index]}.amount`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              type="number"
                              sx={{ width: '100%', ...(WhiteInput) }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                const { value } = e.target;

                                if (numberFormatValidate(value)) {
                                  field.onChange(value);
                                }
                              }}
                            />
                          )}
                        />
                        <FieldError error={errors.rows?.[index]?.amount} />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <FormGroup label="Type" required hasError={!!errors.rows?.[index]?.type}>
                        <Controller
                          control={control}
                          name={`rows.${[index]}.type`}
                          render={({ field }) => (
                            <Select
                              sx={WhiteInput}
                              options={COMPENSATION_STATUSES}
                              placeholder="Type"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <FormGroup label="Comments" required hasError={!!errors?.rows?.[index]?.comments}>
                        <Controller
                          name={`rows.${[index]}.comments`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              sx={{ width: '100%', ...(WhiteInput) }}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                              }}
                            />
                          )}
                        />
                        <FieldError error={errors.rows?.[index]?.comments} />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                      }}
                      >
                        <FormGroup sx={label} label="File" hasError={!!errors.rows?.[index]?.file}>
                          <Controller
                            name={`rows.${[index]}.file`}
                            control={control}
                            render={({ field }) => (
                              <Upload
                                {...field}
                                handleUpload={(e) => {
                                  field.onChange(e);
                                  handleUploadFile(e, index);
                                }}
                                title="Upload file"
                              />
                            )}
                          />
                          <FieldError error={errors.rows?.[index]?.file} />
                        </FormGroup>

                        <Box sx={{ ...(TextWithIcon), alignItems: 'flex-end' }}>
                          <Typography sx={fileName}>
                            {
                              fileNames?.find((item) => item.id === index)?.name
                              || 'No file uploaded'
                            }
                          </Typography>

                          {fileNames?.find((item) => item.id === index)?.name && (
                            <CloseIcon
                              sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  color: 'mediumvioletred !important',
                                }
                              }}
                              onClick={() => handleResetFile(index)}
                            />
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              ))}
            </Box>

            {!update && (
              <Box>
                <Button
                  title="Add Row"
                  startIcon={<Plus />}
                  sx={{ ...btnS, ...GreenButton, maxWidth: '140px' }}
                  size="small"
                  onClick={() => rowAppend(defaultValues.rows[0])}
                />
              </Box>
            )}

          </Card>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CompensationForm;
