import creditCardType from 'credit-card-type';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {
  MasterCardIcon,
  VisaIcon,
  AmexIcon
} from '../components/Icons';

export const getCardBrandIcon = (cardNumber) => {
  if (!cardNumber) {
    // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
    return <CreditCardIcon />;
  }

  const cardInfo = creditCardType(cardNumber);
  if (cardInfo && cardInfo.length > 0) {
    // Using the first match returned by the library
    const cardType = cardInfo[0].type;
    switch (cardType) {
      case 'visa':
        // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
        return <VisaIcon />;
      case 'mastercard':
        // eslint-disable-next-line react/react-in-jsx-scope
        return <MasterCardIcon />;
      case 'american-express':
        // eslint-disable-next-line react/react-in-jsx-scope
        return <AmexIcon />;
      // // Add additional cases for other card types if needed
      default:
        // eslint-disable-next-line react/react-in-jsx-scope
        return <CreditCardIcon />;
    }
  }

  // eslint-disable-next-line react/react-in-jsx-scope
  return <CreditCardIcon />;
};
