import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { HeaderName } from '../../../components/EditableTable/styles';
import FiltrationInput from '../../../components/EditableTable/FiltrationInput';
import React from 'react';
import Chip from '@mui/material/Chip';
import LinkButton from '../../../components/LinkButton';
import {
  getFormatCompensationsStatus,
  getFormatStatus,
  OPENED,
  PAID
} from '../../MyProfile/PersonalReportsTab/constants';

export const columns = ({
  handleFilter,
  filters,
  isCompensations
}) => ([
  {
    flex: 0.2,
    field: 'crew_shortname',
    minWidth: 200,
    headerName: 'Crew Shortname',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Crew Shortname</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.crew_shortname || ''}
          value="crew_shortname"
          placeholder="Search by Shortname"
        />
      </Box>
    ),
    renderCell: ({ row: { crew_shortname, id } }) => (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          textDecorationLine: 'underline',
          cursor: 'pointer'
        }}
        variant="subtitle1"
      >
        <LinkButton path={isCompensations ? `/finance/compensations/pilot/${id}` : `/finance/personal-reports/pilot/${id}`}>
          {crew_shortname}
        </LinkButton>
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'crew_name',
    minWidth: 200,
    headerName: 'Crew Name',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column'
        }}
      >
        <Typography style={HeaderName}>Crew Name</Typography>
        <FiltrationInput
          handleFilter={handleFilter}
          defaultValue={filters.crew_name || ''}
          value="crew_name"
          placeholder="Search by Name"
        />
      </Box>
    ),
    renderCell: ({ row: { crew_name, id } }) => (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          textDecorationLine: 'underline',
          cursor: 'pointer'
        }}
        variant="subtitle1"
      >
        <LinkButton path={isCompensations ? `/finance/compensations/pilot/${id}` : `/finance/personal-reports/pilot/${id}`}>
          {crew_name}
        </LinkButton>
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'report_status',
    minWidth: 200,
    headerName: 'Report Status',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',

        }}
      >
        <Typography style={HeaderName}>Report Status</Typography>
      </Box>
    ),
    renderCell: ({ row: { report_status } }) => (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
        }}
        variant="subtitle1"
      >
        {isCompensations ? (
          <Chip
            label={getFormatCompensationsStatus(report_status, 'No ongoing report')}
            variant={report_status ? 'filled' : 'outlined'}
            color={report_status ? (report_status === PAID ? 'success' : 'warning') : 'default'}
            size={report_status ? '' : 'small'}
          />
        ) : (
          <Chip
            label={getFormatStatus(report_status, 'No ongoing report')}
            variant={report_status ? 'filled' : 'outlined'}
            color={report_status ? (report_status === OPENED ? 'error' : (report_status === PAID ? 'success' : 'primary')) : 'default'}
            size={report_status ? '' : 'small'}
          />
        )}
      </Typography>
    ),
  },
]);
