import DeleteIcon from '@mui/icons-material/Delete';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box, Card,
  Chip, CircularProgress,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import React from 'react';
import Button from '../../../../../../../components/Button/Button';
import Upload from '../../../../../../../components/Upload';
import { headerBtn } from '../../../../../../Invoices/style';
import { RedButton, StatusHeader, TextWithIcon } from '../../../../../../Logistics/style';
import { useADFiles } from './useADFiles';

const Files = ({ currentAD, typeId, title = 'Files' }) => {
  const {
    upload,
    loading,
    files,
    canEdit,
    handleOpen,
    fileLoading,
    deleteLoading,
    handleDelete
  } = useADFiles({ currentAD, typeId });

  return (
    <Card sx={{
      gap: '15px',
      backgroundColor: '#e9f0fe',
      padding: '10px',
      borderRadius: '8px'
    }}
    >
      <Box sx={{ ...headerBtn }}>
        <Box>
          <Typography variant="h5" noWrap sx={TextWithIcon}>
            <DocumentScannerIcon />
            {title}
          </Typography>
        </Box>
      </Box>

      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {files?.length ? (
            <Grid container spacing={2}>
              {files.map((file, index) => (
                <Grid item xs={12} key={file.id} sx={{ backgroundColor: 'white', margin: '10px', borderRadius: '8px' }}>
                  <Box sx={StatusHeader}>
                    {`#${index + 1}`}

                    <Chip label={file?.name} />

                    <Box sx={{ margin: '0.2rem' }}>
                      <Button
                        key={file.id}
                        startIcon={!fileLoading ? <OpenInNewIcon /> : <CircularProgress size={15} color="inherit" />}
                        sx={{ marginLeft: '0.5rem' }}
                        title="Preview"
                        size="small"
                        disabled={fileLoading}
                        onClick={() => handleOpen(file.id)}
                      />
                      <Button
                        startIcon={<DeleteIcon />}
                        sx={{ marginLeft: '0.5rem', ...(RedButton) }}
                        disabled={!canEdit || deleteLoading}
                        title="Delete"
                        size="small"
                        onClick={() => handleDelete(file.id)}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '0.5rem' }}>
                    <Typography variant="subtitle2" noWrap></Typography>
                    <Typography variant="subtitle2" noWrap>
                      {`Uploaded at: ${file?.created_at}`}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="subtitle1" bold noWrap sx={{ textAlignLast: 'center' }}>
              No files uploaded yet.
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sx={{ textAlignLast: 'center' }}>
          <Upload
            sx={{ marginTop: '1rem' }}
            handleUpload={upload}
            title="Upload"
            accept=".doc,.docx,image/*,.pdf"
            multiple
            disabled={loading || !canEdit}
          />
        </Grid>
      </Grid>

    </Card>
  );
};

export default Files;
