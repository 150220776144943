import React from 'react';
import { useFinanceAircraftReport } from './useFinanceAircraftReport';
import {
  Box
} from '@mui/material';
import PageHeader from './PageHeader';
import AircraftTable from './AircraftTable';
import ReportsTable from './ReportsTable';
import ReportForm from './ReportForm';
import { loaderIcon } from '../../CrewForm/Documentation/Card/styles';
import CircularProgress from '@mui/material/CircularProgress';

const FinanceAircraftReport = () => {
  const {
    isLoading,
    form,
    options,
    actions
  } = useFinanceAircraftReport();

  return (
    <Box>
      <PageHeader options={options} />

      {options.aircraft === null && (
        <AircraftTable actions={actions} />
      )}

      {(options.aircraft !== null && options.report === null) && (
        <ReportsTable actions={actions} options={options} />
      )}

      {options.report !== null && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {isLoading ? (
            <Box sx={loaderIcon}>
              {' '}
              <CircularProgress />
              {' '}
            </Box>
          ) : (
            <ReportForm actions={actions} options={options} form={form} />
          )}
        </>
      )}

    </Box>
  );
};

export default FinanceAircraftReport;
