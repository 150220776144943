export const encodeObjectToURIParams = (obj) => {
  let str = '';
  // eslint-disable-next-line guard-for-in
  for (const key in obj) {
    if (str !== '') {
      str += '&';
    }
    str += `${key}=${encodeURIComponent(obj[key])}`;
  }

  return str;
};

export const basename = (path) => path.split('/').reverse()[0];
