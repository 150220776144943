import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Card, Divider, Grid, TextField, Typography
} from '@mui/material';
import { StatusCardStyle } from '../styles';
import FormGroup from '../../../../../../form/components/FormGroup';
import Input from '../../../../../../form/components/Input';
import { WhiteInput } from '../../../../../Finance/InvoiceManagement/styles';
import Autocomplete from '../../../../../../form/components/Autocomplete';
import { useSearchSupplierQuery } from '../../../../../../store/slices/logisticsSlice';
import ServerAutocomplete from '../../../../../../form/components/ServerAutocomplete';
import Select from '../../../../../../form/components/Select';
import { PRICES_STATUS, TRANSACTION_TYPE } from '../../../../OrdersDashboard/components/forms/new/schema';
import DatePicker from '../../../../../../form/components/DatePicker';
import { setDateValue, validateDatePickerValue } from '../../../../../../utils/setDateValue';
import Checkbox from '../../../../../../form/components/Checkbox';

const EditPrices = ({ currencyOptions, orderPartType, canEditInput }) => {
  const {
    getValues,
    control,
    setValue,
    watch
  } = useFormContext();

  const renderPriceItem = (index) => (
    <Grid container spacing={4}>
      {!['Overhaul_out', 'Repair_out'].includes(orderPartType) && (
        <Grid item xs={4}>
          <FormGroup
            label={(watch('pending')?.prices?.[index]?.transaction_type === 'exchange')
              ? 'Core Value' : 'Amount'}
            required
          >
            <Controller
              name={`pending.prices[${index}].amount`}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder={!['Overhaul_out', 'Repair_out'].includes(orderPartType) ? ((watch('pending')?.prices?.[index]?.transaction_type === 'exchange') ? 'Core Value' : 'Amount') : 'Estimated Price'}
                  disabled={!canEditInput(`pending.prices[${index}].amount`)}
                  sx={{ ...(WhiteInput), width: '100%' }}
                />
              )}
            />
          </FormGroup>
        </Grid>
      )}
      {!['Overhaul_out', 'Repair_out'].includes(orderPartType) && (
        <Grid item xs={4}>
          <FormGroup label="Currency" required>
            <Controller
              control={control}
              name={`pending.prices[${index}].currency_id`}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  sx={WhiteInput}
                  value={currencyOptions?.filter((currency) => Number(currency?.value) === Number(field?.value))?.[0]}
                  options={canEditInput(`pending.prices[${index}].currency_id`) ? currencyOptions : []}
                  size="small"
                  placeholder="Select"
                  disabled={!canEditInput(`pending.prices[${index}].currency_id`)}
                  onChange={(e, value ) => {
                    field.onChange(value);
                    setValue(`pending.prices[${index}].currency_id`, value?.value || null);
                  }}
                />
              )}
            />
          </FormGroup>
        </Grid>
      )}
      {!['Overhaul_out', 'Repair_out'].includes(orderPartType) && (
        <Grid item xs={4}>
          <FormGroup label={['delivery', 'Other'].includes(orderPartType) ? 'Delivery Operators' : 'Vendor'} required>
            <Controller
              control={control}
              name={`pending.prices.${[index]}.supplier`}
              render={({ field }) => (
                <ServerAutocomplete
                  fields={field}
                  sx={WhiteInput}
                  withData
                  placeholder="Search suppliers by name or email"
                  searchQueryFn={useSearchSupplierQuery}
                  disabled={!canEditInput(`pending.prices[${index}].supplier_id`)}
                  handleSelect={(value) => {
                    field.onChange(value);
                    setValue(`pending.prices.${[index]}.supplier_id`, value?.value || null);
                  }}
                />
              )}
            />
          </FormGroup>
        </Grid>
      )}
      {!['Exchange', 'Overhaul_in', 'Repair_in', 'Overhaul_out', 'Repair_out', 'delivery', 'Other'].includes(orderPartType) && (
        <Grid item xs={4}>
          <FormGroup label="Transaction Type" required>
            <Controller
              control={control}
              name={`pending.prices.${[index]}.transaction_type`}
              render={({ field }) => (
                <Select
                  sx={WhiteInput}
                  options={TRANSACTION_TYPE}
                  placeholder="Transaction Type"
                  disabled={!canEditInput(`pending.prices[${index}].transaction_type`)}
                  {...field}
                />
              )}
            />
          </FormGroup>
        </Grid>
      )}
      {!['Exchange', 'Overhaul_in', 'Repair_in', 'Overhaul_out', 'Repair_out', 'delivery', 'Other'].includes(orderPartType) && (
        <Grid item xs={4}>
          <FormGroup label="Status" required>
            <Controller
              control={control}
              name={`pending.prices.${[index]}.status`}
              render={({ field }) => (
                <Select
                  options={PRICES_STATUS}
                  placeholder="Status"
                  disabled={!canEditInput(`pending.prices[${index}].status`)}
                  sx={{ ...(WhiteInput), width: '100%' }}
                  {...field}
                />
              )}
            />
          </FormGroup>
        </Grid>
      )}
      {['Overhaul_in', 'Repair_in', 'Overhaul_out', 'Repair_out', 'Return'].includes(orderPartType) && (
        <>
          <Grid item xs={4}>
            <FormGroup label="From Address" required>
              <Controller
                name={`pending.prices.${[index]}.address_from`}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    multiline
                    disabled={!canEditInput(`pending.prices[${index}].address_from`)}
                    sx={{ ...(WhiteInput), width: '100%' }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <FormGroup label="To Address" required>
              <Controller
                name={`pending.prices.${[index]}.address_to`}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    multiline
                    disabled={!canEditInput(`pending.prices[${index}].address_to`)}
                    sx={{ ...(WhiteInput), width: '100%' }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
        </>
      )}
      <Grid item xs={4}>
        <FormGroup
          label={['Repair_in', 'Overhaul_in'].includes(orderPartType) ? 'Estimated Date Return Repair' : 'Estimated Delivery Date'}
          required={['Repair_in', 'Overhaul_in', 'Repair_out', 'Overhaul_out'].includes(orderPartType)}
        >
          <Controller
            control={control}
            name={`pending.prices.${[index]}.estimated_date`}
            render={({ field }) => (
              <DatePicker
                placeholderText="YYYY-MM-DD"
                dateFormat="yyyy-MM-dd"
                showMonthDropdown
                showYearDropdown
                {...field}
                sx={WhiteInput}
                disabled={!canEditInput(`pending.prices[${index}].estimated_date`)}
                onChange={(e) => {
                  field.onChange(e);
                  setDateValue(e, setValue, `pending.prices.${[index]}.estimated_date`, 'YYYY-MM-DD');
                }}
                value={validateDatePickerValue(field.value)}
              />
            )}
          />
        </FormGroup>
      </Grid>
      {['Overhaul_in', 'Repair_in', 'part', 'consumable', 'instrument', 'other'].includes(orderPartType) && (
        <>
          <Grid item xs={4}>
            <FormGroup label="Estimated Delivery Price">
              <Controller
                control={control}
                name={`pending.prices.${[index]}.estimated_delivery_price`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="number"
                    placeholder="Estimated Delivery Price"
                    disabled={!canEditInput(`pending.prices[${index}].estimated_delivery_price`)}
                    sx={{ ...(WhiteInput), width: '100%' }}
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={4}>
            <FormGroup label="Estimated Delivery Currency">
              <Controller
                control={control}
                name={`pending.prices.${[index]}.estimated_delivery_currency_id`}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    sx={WhiteInput}
                    value={currencyOptions?.filter((currency) => Number(currency?.value) === Number(field?.value))?.[0]}
                    options={canEditInput(`pending.prices[${index}].estimated_delivery_currency_id`) ? currencyOptions : []}
                    size="small"
                    placeholder="Please select a Estimated Delivery Currency"
                    disabled={!canEditInput(`pending.prices[${index}].estimated_delivery_currency_id`)}
                    onChange={(e, value ) => {
                      field.onChange(value);
                      setValue(`pending.prices[${index}].estimated_delivery_currency_id`, value?.value || null);
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
        </>
      )}
      {watch('pending')?.prices?.[index]?.transaction_type === 'exchange' && (
        <Grid item xs={4}>
          <FormGroup label="Exchange Fee" required>
            <Controller
              name={`pending.prices.${[index]}.exchange_fee`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  type="number"
                  placeholder="Exchange Fee"
                  disabled={!canEditInput(`pending.prices[${index}].exchange_fee`)}
                  sx={{ ...(WhiteInput), width: '100%' }}
                />
              )}
            />
          </FormGroup>
        </Grid>
      )}
      <Grid item xs={4}>
        <FormGroup label="Suggested Price" required>
          <Controller
            name={`pending.prices.${[index]}.suggested`}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
              />
            )}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );

  return (
    <Grid item xs={12}>
      <Card sx={StatusCardStyle}>
        <Typography variant="h6">
          Edit
          {' '}
          <b>Prices</b>
        </Typography>

        <Divider />

        <Grid container spacing={4}>
          {getValues('pending.prices')?.map((price, index) => (
            <Grid item xs={12} key={price?.id}>
              <Card sx={{ padding: '1rem', backgroundColor: price.approved_by ? '#c6f2c8' : 'none' }}>
                <Typography variant="subtitle1">
                  {price.approved_by ? 'Aprrove Price #' : 'Price #'}
                  {index + 1}
                </Typography>

                <Divider />

                {renderPriceItem(index)}
              </Card>
            </Grid>
          ))}
          {!getValues('pending.prices')?.length && (
            <Grid item xs={12}>
              <Card sx={{ padding: '1rem', backgroundColor: '#f2e0c6' }}>
                <Typography variant="subtitle1">
                  Create new price
                </Typography>

                <Divider />

                {renderPriceItem('new')}
              </Card>
            </Grid>
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

export default EditPrices;
