import React from 'react';
import {
  Box,
  Card, Divider,
  Grid,
  Typography
} from '@mui/material';
import { BoxFlexBetween, StyledCard } from '../../../../../../assets/styles';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Button from '../../../../../../components/Button';
import { GreenButton, RedButton } from '../../../../../Logistics/style';
import { defaultValues } from '../../schema';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DatePicker from '../../../../../../form/components/DatePicker';
import { WhiteInput } from '../../../../../Finance/InvoiceManagement/styles';
import FormGroup from '../../../../../../form/components/FormGroup';
import Select from '../../../../../../form/components/Select';
import {
  STATUS_CLOSED,
  STATUS_SUBMITTED,
  TRANSACTION_TYPES,
  TRX_TYPE_CARD_PAYMENT, TRX_TYPE_CASH,
  TRX_TYPE_WITHDRAWAL
} from '../../../constants';
import Input from '../../../../../../form/components/Input';
import { setDateValue, validateDatePickerValue } from '../../../../../../utils/setDateValue';
import Autocomplete from '../../../../../../form/components/Autocomplete';
import { numberFormatValidate } from '../../../../../../utils/number';
import Files from './components/Files';

const Transactions = ({ options, actions }) => {
  const { currencyOptions } = options;
  const {
    control,
    setValue,
    formState: { errors },
    getValues,
    watch,
  } = useFormContext();

  const {
    fields: trxFields,
    append: trxAppend,
    remove: trxRemove
  } = useFieldArray({
    name: 'transactions',
    control,
  });

  const handleShowLinkTransaction = (index) => [STATUS_SUBMITTED, STATUS_CLOSED].includes(options.reportStatus)
      && watch(`transactions.${index}.type`) !== TRX_TYPE_CASH;

  const filterTransactions = (cardId, filterDate) => {
    const transactions = options.transactionOptions;

    return transactions?.filter(transaction => {
      const matchesCard = cardId
        ? transaction.data?.account_number?.cards?.some(card => card.id === cardId)
        : true;

      const matchesDate = filterDate
        ? (() => {
          const createdAt = new Date(transaction.data?.created_at);
          const targetDate = new Date(filterDate);

          const minDate = new Date(targetDate);
          minDate.setMonth(minDate.getMonth() - 3);

          const maxDate = new Date(targetDate);
          maxDate.setMonth(maxDate.getMonth() + 3);

          return createdAt >= minDate && createdAt <= maxDate;
        })()
        : true;

      return matchesCard && matchesDate;
    });
  };

  return (
    <StyledCard sx={{ backgroundColor: '#f5f5f5' }}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <BoxFlexBetween>
            <Box>
              <Typography variant="h6" noWrap>
                Transactions
              </Typography>
            </Box>

            {!options.readOnly && (
              <Button
                startIcon={<KeyboardArrowDownIcon />}
                title="Add transaction"
                sx={GreenButton}
                onClick={() => trxAppend(defaultValues.transactions[0])}
              />
            )}

          </BoxFlexBetween>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={6}>
            {trxFields.map((row, index) => (
              <Grid item xs={12} key={row.id}>
                <Card sx={{ padding: '1rem', backgroundColor: 'rgba(62,107,160,0.28)' }}>
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <BoxFlexBetween>
                        <Typography variant="h6" noWrap>
                          Transaction #
                          {index + 1}
                        </Typography>

                        {(index !== 0 && !options.readOnly) && (
                          <Button
                            sx={RedButton}
                            title="Delete"
                            onClick={() => trxRemove(index)}
                          />
                        )}
                      </BoxFlexBetween>

                      <Divider />
                    </Grid>

                    {/* Date */}
                    <Grid item xs={12} lg={3}>
                      <FormGroup label="Transaction Date" required hasError={!!errors.transactions?.[index]?.date}>
                        <Controller
                          name={`transactions[${index}].date`}
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              sx={WhiteInput}
                              placeholderText="YYYY-MM-DD"
                              dateFormat="yyyy-MM-dd"
                              showMonthDropdown
                              showYearDropdown
                              onChange={(e) => {
                                setDateValue(e, setValue, `transactions[${index}].date`, 'YYYY-MM-DD');
                              }}
                              value={validateDatePickerValue(field.value)}
                              disabled={options.readOnly}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>

                    {/* Date */}
                    <Grid item xs={12} lg={3}>
                      <FormGroup label="Transaction Type" required hasError={!!errors.transactions?.[index]?.type}>
                        <Controller
                          name={`transactions[${index}].type`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              sx={WhiteInput}
                              {...field}
                              placeholder="Select Type"
                              options={TRANSACTION_TYPES}
                              disabled={options.readOnly}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>

                    {/* Amount */}
                    <Grid item xs={12} lg={3}>
                      <FormGroup label="Amount" required hasError={!!errors.transactions?.[index]?.amount}>
                        <Controller
                          name={`transactions[${index}].amount`}
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              sx={WhiteInput}
                              type="number"
                              placeholder="Amount"
                              style={{ width: '100%' }}
                              disabled={options.readOnly}
                              onChange={(e) => {
                                const { value } = e.target;

                                if (numberFormatValidate(value, 11)) {
                                  field.onChange(value);
                                }
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>

                    {/* Currency */}
                    <Grid item xs={12} lg={3}>
                      <FormGroup label="Currency" required hasError={!!errors.transactions?.[index]?.currency_id}>
                        <Controller
                          name={`transactions[${index}].currency_id`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              sx={WhiteInput}
                              {...field}
                              placeholder="Select Amount Currency"
                              options={currencyOptions}
                              disabled={currencyOptions.length === 0 || options.readOnly}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>

                    {[TRX_TYPE_CARD_PAYMENT, TRX_TYPE_WITHDRAWAL].includes(watch(`transactions[${index}].type`)) && (
                      <Grid item xs={12} lg={3}>
                        <FormGroup label="Transaction Credit Card" required hasError={!!errors.transaction_credit_card_id}>
                          <Controller
                            name={`transactions[${index}].transaction_credit_card_id`}
                            control={control}
                            render={({ field }) => (
                              <Select
                                sx={WhiteInput}
                                {...field}
                                placeholder={!watch('aircraft_id') ? 'Select Aircraft First' : 'Select Credit Card'}
                                options={options.creditCardsOptions}
                                disabled={!watch('aircraft_id') || options.creditCardsOptions.length === 0}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>
                    )}

                    {/* Notes */}
                    <Grid item xs={12}>
                      <FormGroup label="Notes" hasError={!!errors.transactions?.[index]?.notes}>
                        <Controller
                          name={`transactions[${index}].notes`}
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              sx={WhiteInput}
                              placeholder="..."
                              multiline
                              rows={2}
                              style={{ width: '100%' }}
                              disabled={options.readOnly}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>

                    {/* File */}
                    <Grid item xs={6}>
                      <Files options={options} actions={actions} trxIndex={index} />
                    </Grid>

                    {handleShowLinkTransaction(index) && (
                      <Grid itemx xs={6}>
                        <FormGroup label="Link Transaction" hasError={!!errors.transactions?.[index]?.transactions}>
                          <Controller
                            name={`transactions.${index}.transactions`}
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                sx={WhiteInput}
                                options={filterTransactions(
                                  getValues(`transactions[${index}].transaction_credit_card_id`),
                                  getValues(`transactions[${index}].date`)
                                )}
                                multiple
                                placeholder="Link Transaction"
                                onChange={(_, value) => {
                                  field.onChange(value);
                                  setValue(`transactions.${index}.transactions`, value || []);
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>
                    )}

                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

      </Grid>
    </StyledCard>
  );
};

export default Transactions;
