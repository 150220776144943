import React, { useState } from 'react';
import {
  Box, Card, CardContent, Tab, Breadcrumbs
} from '@mui/material';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import Typography from '@mui/material/Typography';
import {
  BoxHeader, BoxHeaderFirst, CardHeaderSx, MainHeader
} from '../InvoiceManagement/styles';
import FlagIcon from '@mui/icons-material/Flag';
import { columns } from './columns';
import EditableTable from '../../../components/EditableTable';
import {
  useGetFinancePilotReportsTableQuery
} from '../../../store/slices/personalReportsSlice';
import { TabContext, TabList } from '@mui/lab';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LinkButton from '../../../components/LinkButton';
import GroupIcon from '@mui/icons-material/Group';
import { TransparentButton } from '../../Logistics/style';
import Button from '../../../components/Button';

const PersonalReports = () => {
  const [tabsState, setTabsState] = useState('1');
  const [pageState, setPageState] = useState('reports');
  const hasPermissions = usePermissions(['personal_reports_read', 'personal_reports_write']);
  const hasCompensationRead = usePermissions('compensation_read_access');

  const handleTabsChange = (event, newValue) => {
    setTabsState(newValue);
  };

  return (
    <Box>
      {/* Page Header */}
      <Card sx={{ ...CardHeaderSx, mb: 5 }}>
        <Box sx={BoxHeader}>
          <Box sx={{
            ...BoxHeaderFirst, display: 'flex', width: '100%', justifyContent: 'space-between'
          }}
          >
            <Typography noWrap variant="h4" sx={MainHeader}>
              <FlagIcon />
              {pageState === 'reports' ? 'Personal Reports' : 'Compensations'}
            </Typography>
            {hasCompensationRead && (
              <Box>
                <Button
                  title="Reports"
                  variant={pageState !== 'reports' ? 'outlined' : 'contained'}
                  sx={pageState !== 'reports' ? TransparentButton : {}}
                  onClick={() => setPageState('reports')}
                />
                {' '}
                <Button
                  title="Compensations"
                  variant={pageState !== 'compensations' ? 'outlined' : 'contained'}
                  sx={pageState !== 'compensations' ? TransparentButton : {}}
                  onClick={() => setPageState('compensations')}
                />
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <LinkButton path="/finance/personal-reports" style={{ display: 'flex', alignItems: 'center' }}>
              <GroupIcon sx={{ mr: 0.5, color: 'rgb(28 70 117)' }} fontSize="inherit" />
              <Typography sx={{ fontWeight: '600' }}>Crews</Typography>
            </LinkButton>
          </Breadcrumbs>
        </Box>
      </Card>

      {/* Page Content */}
      <Box>
        {hasPermissions && (
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <TabContext value={tabsState}>
                <TabList
                  onChange={handleTabsChange}
                  aria-label="tabs"
                  sx={{ bgcolor: 'whitesmoke', minHeight: '96px' }}
                  variant="fullWidth"
                >
                  <Tab value="1" label="Captains" icon={<AccountBoxIcon />} />
                  <Tab value="2" label="FO" icon={<AccountCircleIcon />} />
                </TabList>

                <EditableTable
                  useHook={useGetFinancePilotReportsTableQuery}
                  columns={columns}
                  isCompensations={pageState === 'compensations'}
                  sortByType="DESC"
                  sortByActive="report_status"
                  getRowClassName={({ id }) => `row-m ${id}`}
                  getRowHeight={() => 'auto'}
                  getRowId={(row) => row.id}
                  tableParams={{
                    crew_post: tabsState,
                    is_compensations: pageState === 'compensations' ? 1 : 0,
                  }}
                />
              </TabContext>
            </CardContent>
          </Card>
        )}
      </Box>
    </Box>
  );
};

export default PersonalReports;
